import React, { Component } from "react";
import PropTypes from "prop-types";

// import RBush from "rbush";

// import { createRoisFromAnno } from "../utils/ROI";

import { withPersistentStorage } from "./PersistentStorageContext";
import { withProject } from "./ProjectContext";

import { getParentIndexLayer } from "../utils/StructuresUtils";

const ProjectHistoryContext = React.createContext();

export const withProjectHistory = (Component) => {
  const WrappedComponent = (props) => (
    <ProjectHistoryContext.Consumer>
      {(context) => <Component {...props} projectHistory={context} />}
    </ProjectHistoryContext.Consumer>
  );

  WrappedComponent.displayName = `withProjectHistory(${
    Component.displayName || Component.name || "Component"
  })`;

  return WrappedComponent;
};

// this is the main component, it has to be added at the root of the app.
// all components that use withProject(...) will have access to it via this.props.Project...
class ProjectHistoryProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      canUndo: false,
      canRedo: false,
      sizeLimitReached: false,
    };
    window.projectHistory = this;
  }

  /**
   * Initializes the Project provider and passes a viewer component
   *
   * @param {object} viewer - Viewer Component
   */
  init = (viewer) => {
    this.viewer = viewer;
    this.past = [];
    this.future = [];
  };

  add = (item) => {
    if (item.length > 0) {
      item = item.map((action) => {
        action.roi = action.roi.copy();
        return action;
      });
      this.past.push(item);
      this.future = [];
      this.updateState();
    }
  };

  //intern function, adds and removes polys for one item (history step)
  processItem = (item, stepType) => {
    if (item.length === 0) return;

    const { fileId, structures } = this.viewer.props.projectContext;
    let roiLayers = this.viewer.props.projectContext.roiLayers[fileId];

    for (let action of item) {
      let parentLayerIdx = -1;
      try {
        parentLayerIdx = getParentIndexLayer(
          structures.find((str) => str.id === action.id),
          structures
        );
      } catch (ex) {
        console.log(stepType, "error:", ex);
        window.showErrorSnackbar(
          "Something went wrong, " + stepType + " ignored!"
        );
        return;
      }
      const parentRoiLayer = roiLayers[parentLayerIdx];

      if (
        (stepType === "redo" && action.add) ||
        (stepType === "undo" && !action.add)
      ) {
        parentRoiLayer.tree.insert(action.roi.treeItem);
      } else {
        parentRoiLayer.tree.remove(action.roi.treeItem, (a, b) => {
          return a.roi.uuid === b.roi.uuid;
        });
      }
      parentRoiLayer.layer.regionRois = parentRoiLayer.tree
        .all()
        .map((treeItem) => treeItem.roi);
    }
  };

  undo = () => {
    if (this.past.length > 0) {
      let item = this.past.pop();
      this.processItem(item, "undo");
      this.future.push(item);
    }
    this.updateState();
  };

  redo = () => {
    if (this.future.length > 0) {
      let item = this.future.pop();
      this.processItem(item, "redo");
      this.past.push(item);
    }
    this.updateState();
  };

  clear = () => {
    this.past = [];
    this.future = [];
    this.shownSaveHintNum = 0;
    this.updateState();
  };

  updateState = () => {
    this.setState({
      canUndo: this.past.length > 0,
      canRedo: this.future.length > 0,
    });
  };

  getHistoryLength = () => {
    return this.past.length;
  };

  mergePastItems(n) {
    if (n > 1) {
      let newItem = [];
      for (let i = 0; i < n; i++) {
        let item = this.past.pop();
        newItem.push(...item);
      }
      this.past.push(newItem);
    }
  }

  render() {
    return (
      <ProjectHistoryContext.Provider
        value={{
          init: this.init,
          add: this.add,
          undo: this.undo,
          redo: this.redo,
          getHistoryLength: this.getHistoryLength,
          mergePastItems: this.mergePastItems,
          ...this.state,
        }}
      >
        {this.props.children}
      </ProjectHistoryContext.Provider>
    );
  }
}

ProjectHistoryProvider.propTypes = {
  historyDepth: PropTypes.number.isRequired,
  projectContext: PropTypes.object,
  children: PropTypes.element.isRequired,
};

export default withPersistentStorage(withProject(ProjectHistoryProvider));
