import React, { Component } from "react";

import PropTypes from "prop-types";
import classNames from "classnames";

import withStyles from "@mui/styles/withStyles";

import {
  IconButton,
  Tooltip,
  Divider,
  Fade,
  Popper,
  Paper,
  //  SvgIcon,
} from "@mui/material";

import {
  faImages,
  faCube,
  faFillDrip,
  faPlus,
  faPen,
  faDrawPolygon,
  faClone,
  faMousePointer,
  faCamera,
  faDownload,
  faPlayCircle,
  faExchangeAlt,
  faSquareFull,
  faThLarge,
  faTh,
  faBrain,
  faMagic,
  faBraille,
  faFileDownload,
} from "@fortawesome/free-solid-svg-icons";
import AllOutIcon from "@mui/icons-material/AllOut";
import { faCircle } from "@fortawesome/free-regular-svg-icons";
import UndoIcon from "@mui/icons-material/Undo";
import RedoIcon from "@mui/icons-material/Redo";
import RateReviewIcon from "@mui/icons-material/RateReview";
//import IsoIcon from "@mui/icons-material/Iso";
import PinDropIcon from "@mui/icons-material/PinDrop";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Save,
  ZoomOutMap,
  CheckBoxOutlineBlank,
  ZoomIn,
  ZoomOut,
  ViewQuilt,
  FilterAlt,
} from "@mui/icons-material";
import SelectAllIcon from "@mui/icons-material/SelectAll";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import FlipIcon from "@mui/icons-material/Flip";
import ScatterPlotIcon from "@mui/icons-material/ScatterPlot";
import InlineSVG from "svg-inline-react";
import ToggleButton from "./ToggleButton";
import CustomSvgIcon from "../../globalComponents/CustomSvgIcon.jsx";

import { withTiles } from "../contexts/TilesContext";
import Backend from "../../common/utils/Backend";
import VerticalResizeBorder from "./VerticalResizeBorder";
import { withAllViewerContexts } from "../contexts/AllViewerContexts";
import { withResultTab } from "../contexts/ResultTabContext";

// enum containing all available tools
export const Tools = {
  NONE: "none",
  // shape anotation tools
  RECT_ROI: "rect",
  // pixel anotation tools
  PEN_ROI: "pen",
  MAGICWAND_ROI: "magic wand",
  REGIONGROWING_ROI: "region growing",
  REGIONGRABCUT_ROI: "region grabcut",
  REGION_ROI: "region",
  RECTANGLE_ROI: "rectangle",
  COMMENT_ROI: "comment",
  ELLIPSE_ROI: "ellipse",
  COPY_ROI: "copy",
  SELECTION_ROI: "selection",
  FILTERANNOTATIONS_ROI: "smallestroi",
  PLOTNEAREST_ROI: "nearestroi",
  FILL: "fill",
  IAM: "iam",
  // active learning
  AL: "activeLearning",
  AITRAINING: "aiTraining",
  GRIDTOOL: "gridtool",
  GRIDANNOTATIONTOOL: "gridannotationtool",
  SELECTION_Tile: "selectiontile",
  POINTCOUNTING_Tile: "pointcountingtile",
  // tileRegistration
  LANDMARK: "landmark",
  HEATMAP: "heatmap",
};

// define the component's styling
const styles = (theme) => ({
  toolBar: {
    position: "relative",
    margin: 0,
    padding: 0,
    marginRight: 5,
    background: "#fff",
    height: "100%",
    display: "grid",
    gridTemplateRows: "1fr auto",
    overflowX: "hidden",
    overflowY: "auto",
  },
  toolbarButton: {
    display: "inline-block",
    width: 40,
    height: 40,
    padding: 0,
    margin: 0,
    fontSize: 22,
  },
  toolbarButtonIcon: {
    verticalAlign: "-4px",
  },
  textIcon: {
    fontSize: 18,
    fontWeight: "bold",
  },
  toolbarButtonChecked: {
    width: 40,
    color: theme.palette.primary.main,
  },
  divider: {
    marginLeft: 5,
    marginRight: 5,
  },
});

class VerticalToolBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      anchorEl: null,
    };
  }

  componentDidMount = () => {
    this.setToolBarWidth(false);
  };

  // trigger on window resize: set toolbarwidth to 1 col, 2 col or 2 cols with scrollbar
  setToolBarWidth = (viewerToGallery) => {
    if (this.containerElement && this.topElement && this.bottomElement) {
      const { verticalToolBarWidth } = this.props;
      let containerHeight = this.containerElement.clientHeight;
      let topHeight = this.topElement.clientHeight;
      let bottomHeight = this.bottomElement.clientHeight;
      let resultWidth = verticalToolBarWidth;
      if (verticalToolBarWidth > 45) {
        if ((topHeight + bottomHeight) * 2 < containerHeight) {
          resultWidth = 45;
        } else if (containerHeight < topHeight + bottomHeight) {
          resultWidth = 101;
        } else {
          resultWidth = 90;
        }
      } else if (topHeight + bottomHeight > containerHeight) {
        resultWidth = 90;
      }
      if (verticalToolBarWidth !== resultWidth - 5) {
        this.props.updateToolBarWidth(resultWidth, viewerToGallery);
      }
    }
  };

  checkToolInConfig(toolName) {
    if (this.props.viewerConfig) {
      if (this.props.viewerConfig.project.toolsInProject[toolName]) {
        return true;
      } else {
        return false;
      }
    }
  }

  exportAllFiles = (idx, projectId) => {
    // export images of structure to folder in png format
    let fileIDs = this.props.project.files;
    this.props.spinloader.show();
    Backend.exportGallery(projectId, fileIDs[idx].id, (e) => {
      let str_1 = "Export File " + fileIDs[idx].id;
      let str_2 = "Gallery exported to: " + e.path;
      if (idx + 1 < this.props.project.files.length) {
        this.showMessage(str_1);
        this.exportAllFiles(idx + 1, projectId, fileIDs[idx + 1].id);
      } else {
        this.showMessage(str_1);
        this.showMessage(str_2);
        this.props.setAlruns(false);
        this.props.spinloader.hide();
      }
    });
  };

  exportCurrentFile = (idx, projectId, fileId) => {
    // export images of structure to folder in png format
    this.props.spinloader.show();
    Backend.exportGallery(projectId, fileId, (e) => {
      let str_1 = "Export File " + fileId;
      let str_2 = "Gallery exported to: " + e.path;
      this.showMessage(str_1);
      this.showMessage(str_2);
      this.props.setAlruns(false);
      this.props.spinloader.hide();
    });
  };

  showMessage = (x) => {
    window.showSuccessSnackbar(x);
  };

  setStructureIds = () => {
    const { structures, roiLayers } = this.props;
    // make sure thath all rois have structureIds --> somtimes have no ids after run iams
    for (let i = 0; i < structures.length; i++) {
      // if parent structure set structureIds because parent rois do not have ids after iam / job
      roiLayers[i].layer.regionRois.forEach((roi) => {
        // if roi is no subtype an has no structureId yet
        if (roi.structureId === 0 && !roi.isSubtype) {
          roi.structureId = structures[i].id;
          roi.color = structures[i].color;
        }
      });
    }
  };

  onSaveImages(allScenes) {
    // make sure that all rois have a structure id (if only run job parents do not have structureId)
    this.setStructureIds();
    // save and export images
    this.props.saveChangesGallery(true);
    this.props.setAlruns(true);

    if (allScenes) {
      // wait until saving is finished (project json) --> then start export
      setTimeout(() => {
        this.exportAllFiles(0, this.props.projectId, this.props.fileId);
      }, 2000);
    } else {
      // wait until saving is finished (project json) --> then start export
      setTimeout(() => {
        this.exportCurrentFile(0, this.props.projectId, this.props.fileId);
      }, 2000);
    }
  }

  setZLevelforRois = () => {
    const { roiLayers, selectedLayer } = this.props;
    // set z level of rois to selected z level in viewer
    let zLevelClassification = this.props.tiles.getZLevel();
    roiLayers[selectedLayer].layer.regionRois.forEach((roi) => {
      roi.z = zLevelClassification;
    });
  };

  ApplyModel = () => {
    const { structures, selectedLayer } = this.props;
    // apply trained dl model to selected structure

    // check if selectedLayer is parent
    if (structures[selectedLayer].classificationSubtype) {
      this.props.trainingWarning("subtype");
      return;
    }

    // check if structure has childs
    // check if selectedLayer is parent
    if (!structures[selectedLayer].hasChild) {
      this.props.trainingWarning("hasNoChilds");
      return;
    }

    // make warning that not changing layer while applying model
    window.showWarningSnackbar(
      "Please do not change structure while applying the model."
    );

    // set z-Index of rois to selected z-Level
    this.setZLevelforRois();
    this.props.saveChangesGallery();
    this.props.setAlruns(true);
    let passiveLearning = true;
    let applyModel = true;
    this.props.applyDL(
      this.props.tools["alm_gallery_tool"],
      this.props.projectId,
      this.props.fileId,
      passiveLearning,
      applyModel
    );
  };

  findClassificationChilds = () => {
    const { structures, selectedLayer } = this.props;
    // return direct classification subtypes
    return structures.filter(
      (element) =>
        element.parentId === structures[selectedLayer].id &&
        element.classificationSubtype
    );
  };

  enoughAnnotations = (childs) => {
    const { roiLayers, selectedLayer } = this.props;
    // check if enough annotations for training of dl model (at least one class with 5 annotations and one other class with 1 annotation)
    let minFive = false;
    let twoAnnotated = 0;
    childs.forEach((element) => {
      // get number of elements for child
      let numberElements = roiLayers[selectedLayer].layer.regionRois.filter(
        (roi) => roi.structureId === element.id
      ).length;

      // check if minimum of five in one class
      if (numberElements >= 5) {
        minFive = true;
      }
      // check if two classes with at least one element
      if (numberElements >= 1) {
        twoAnnotated = twoAnnotated + 1;
      }
    });

    // if one class with at least five and one other class with at least one annotation --> enough annotations for training
    let enough = false;
    if (minFive && twoAnnotated >= 2) {
      enough = true;
    }
    return enough;
  };

  onStartTraining = () => {
    const { structures, selectedLayer } = this.props;
    // start training for dl model

    // check if selectedLayer is parent
    if (structures[selectedLayer].classificationSubtype) {
      this.props.trainingWarning("subtype");
      return;
    }

    // check if structure has childs
    // check if selectedLayer is parent
    if (!structures[selectedLayer].hasChild) {
      this.props.trainingWarning("hasNoChilds");
      return;
    }

    // check if enough objects per class
    let childs = this.findClassificationChilds();
    let enoughAnnos = this.enoughAnnotations(childs);
    if (!enoughAnnos) {
      this.props.trainingWarning("tooFewAnnotations");
      return;
    }

    this.props.saveChangesGallery();
    this.props.setAlruns(true);
    let passiveLearning = true;
    this.props.applyDL(
      this.props.tools["alm_gallery_tool"],
      this.props.projectId,
      this.props.fileId,
      passiveLearning
    );
  };

  saveProject = () => {
    // save contextObject in local storage
    this.props.persistentStorage.save(
      "contextObject",
      this.props.tiles.getStrAnnoCount()
    );

    // save SubtypesPagesObject in local storage
    this.props.persistentStorage.save(
      "subtypesPagesObject",
      this.props.tiles.getSubtypesPages()
    );

    // save project
    this.props.onSave();
  };

  showSubtypes = (str) => {
    const { structures } = this.props;
    // unfold subtypes when using specific tool (so suptypes are displayed in subtype color not parent color)
    structures.forEach(function (element) {
      if (element.parentId === str.id) {
        element.isUnfolded = true;
      }
    });

    this.props.projectContext.setState({ structures });
  };

  render() {
    const {
      classes,
      viewerConfig,
      structures,
      tools,
      onChangeTool,
      activeTool,
      displayTimeBar,
      onToggleTimeBar,
      displayZStackBar,
      showGallery,
      filesGalleryActive,
      show3DViewer,
      onToggle3DViewer,
      showPointCloud,
      onToggleMeshView,
      showTilesGallery,
      selectedLayer,
      onSaveScreenshot,
      onZoomDelta,
      onZoomFit,
      onZoomOriginal,
      onToggleSideBar,
      onSave,
      projectHistory,
      verticalToolBarWidth,
      ome,
      activeTab,
      rendererRef,
      annotationsAreReduced,
    } = this.props;

    const { anchorEl, open } = this.state;

    // check which elements should be displayed in specific modules
    let showZoomLevels = true;
    let showUndoRedo = true;
    let showSaveTool = true;
    let showToggleSideBar = true;
    if (
      this.props.project &&
      (this.props.project.type.includes("HistoPointCounting") ||
        this.props.project.type.includes("HistoClassification"))
    ) {
      showZoomLevels = activeTab !== 1;
      showUndoRedo = activeTab !== 1;
      showSaveTool = activeTab !== 1;
      showToggleSideBar = activeTab !== 1;
      if (showGallery) {
        showToggleSideBar = false;
      }
      if (show3DViewer) {
        showToggleSideBar = false;
        showUndoRedo = false;
        showSaveTool = false;
        showZoomLevels = false;
      }
    }
    return (
      <div
        ref={(containerElement) => {
          this.containerElement = containerElement;
        }}
        className={classes.toolBar}
        style={{
          width: verticalToolBarWidth - 5,
        }}
      >
        <div>
          {!(
            this.props.projectContext.activeTab > 0 &&
            this.props.project &&
            (this.props.project.type.includes("HistoPointCounting") ||
              this.props.project.type.includes("HistoClassification"))
          ) && (
            <VerticalResizeBorder resizeSideBar={this.props.resizeSideBar} />
          )}
          <Popper open={open} anchorEl={anchorEl} placement="left" transition>
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <Paper
                  style={{
                    height: 40,
                  }}
                >
                  <ToggleButton
                    classes={classes}
                    title={"Toggle time bar"}
                    name={"showTimeBar"}
                    value={rendererRef.getLayoutElement("showTimeBar")}
                    icon={"faStopwatch"}
                    //onToggle={rendererRef.onToggle}
                    onToggle={onToggleTimeBar}
                    disabled={Boolean(!displayTimeBar)}
                  />

                  <ToggleButton
                    classes={classes}
                    title={"Toggle Z-stack"}
                    name={"showZStackBar"}
                    value={rendererRef.getLayoutElement("showZStackBar")}
                    icon={"faLayerGroup"}
                    onToggle={rendererRef.onToggle}
                    disabled={Boolean(!displayZStackBar)}
                  />

                  <ToggleButton
                    classes={classes}
                    title={"Toggle scalebar"}
                    name={"showScaleBar"}
                    value={rendererRef.getLayoutElement("showScaleBar")}
                    icon={"faRuler"}
                    onToggle={rendererRef.onToggle}
                    disabled={Boolean(ome && ome.physicalSizeX)}
                  />

                  <ToggleButton
                    classes={classes}
                    title={"Toggle Object Index"}
                    name={"showObjectIdx"}
                    value={rendererRef.getLayoutElement("showObjectIdx")}
                    icon={"faInfoCircle"}
                    onToggle={rendererRef.onToggle}
                  />

                  <ToggleButton
                    classes={classes}
                    title={"Toggle Navigator"}
                    name={"showMiniMap"}
                    value={rendererRef.getLayoutElement("showMiniMap")}
                    icon={"MapIcon"}
                    onToggle={rendererRef.onToggle}
                  />

                  <ToggleButton
                    classes={classes}
                    title={"Toggle image info"}
                    name={"showImageInfo"}
                    value={rendererRef.getLayoutElement("showImageInfo")}
                    icon={"faInfo"}
                    onToggle={rendererRef.onToggle}
                  />

                  <ToggleButton
                    classes={classes}
                    title={"Toggle zoom info bar"}
                    name={"showZoomBar"}
                    value={rendererRef.getLayoutElement("showZoomBar")}
                    icon={"LinearScaleIcon"}
                    onToggle={rendererRef.onToggle}
                  />

                  <ToggleButton
                    classes={classes}
                    title={"Toggle result table"}
                    name={"showResultTable"}
                    value={rendererRef.getLayoutElement("showResultTable")}
                    icon={"faTable"}
                    onToggle={rendererRef.onToggle}
                  />

                  <ToggleButton
                    classes={classes}
                    title={"Toggle File navigation buttons"}
                    name={"showFileNavButtons"}
                    value={rendererRef.getLayoutElement("showFileNavButtons")}
                    icon={"UnfoldMore"}
                    onToggle={rendererRef.onToggle}
                  />
                </Paper>
              </Fade>
            )}
          </Popper>
          <div
            ref={(topElement) => {
              this.topElement = topElement;
            }}
          >
            {this.checkToolInConfig("GalleryTool") && !show3DViewer && (
              <Tooltip
                disableInteractive
                placement="left"
                title="Toggle gallery"
              >
                <IconButton
                  className={classNames(
                    classes.toolbarButton,
                    showGallery && classes.toolbarButtonChecked
                  )}
                  onClick={() => {
                    if (this.props.isImporting) {
                      window.showWarningSnackbar(
                        "File import is running, please be patient!"
                      );
                      return;
                    }
                    this.props.onToggleGallery(!showGallery);
                    if (showGallery === true) {
                      this.props.tiles.setGalleryVisible(false);
                      this.props.setGalleryTool();
                      this.props.changeToSelectedFile(); // if files gallery is active change to selected file
                    } else {
                      this.setToolBarWidth(true);
                      this.props.tiles.setGalleryVisible(true);
                      this.props.setGalleryTool();
                    }
                  }}
                  size="large"
                >
                  <FontAwesomeIcon icon={faImages} />
                </IconButton>
              </Tooltip>
            )}
            {/* ADD 3D-Converting Tool -> start converting with apply */}
            {this.checkToolInConfig("3DViewer") && (
              <Tooltip
                disableInteractive
                placement="left"
                title="Show 3D visualisation"
              >
                <IconButton
                  className={classNames(
                    classes.toolbarButton,
                    show3DViewer && classes.toolbarButtonChecked
                  )}
                  onClick={() => {
                    onToggle3DViewer(!show3DViewer);
                  }}
                  size="large"
                >
                  <FontAwesomeIcon icon={faCube} />
                </IconButton>
              </Tooltip>
            )}
            {this.checkToolInConfig("3DPointCloudView") && show3DViewer && (
              <Tooltip
                disableInteractive
                placement="left"
                title="Toggle between Point-Cloud and Mesh-View"
              >
                <IconButton
                  className={classNames(
                    classes.toolbarButton,
                    showPointCloud && classes.toolbarButtonChecked
                  )}
                  onClick={() => {
                    onToggleMeshView(!showPointCloud);
                  }}
                  size="large"
                >
                  <FontAwesomeIcon icon={faBraille} />
                </IconButton>
              </Tooltip>
            )}

            {this.checkToolInConfig("TilesTool") && (
              <Tooltip
                disableInteractive
                placement="left"
                title="Toggle tiles [CTRL + G]"
              >
                <IconButton
                  className={classNames(
                    classes.toolbarButton,
                    showTilesGallery && classes.toolbarButtonChecked
                  )}
                  onClick={() => {
                    this.props.onToggleTilesGallery(!showTilesGallery);
                    this.props.setGalleryTool();
                  }}
                  size="large"
                >
                  <FontAwesomeIcon icon={faSquareFull} />
                </IconButton>
              </Tooltip>
            )}

            {this.checkToolInConfig("FilterAnnotationsTool") &&
              !show3DViewer &&
              !showGallery &&
              !showTilesGallery && (
                <Tooltip
                  disableInteractive
                  placement="left"
                  title="Filter Annotations"
                >
                  <IconButton
                    className={classNames(
                      classes.toolbarButton,
                      activeTool === Tools.FILTERANNOTATIONS_ROI &&
                        classes.toolbarButtonChecked
                    )}
                    onClick={() =>
                      onChangeTool(
                        activeTool === Tools.FILTERANNOTATIONS_ROI
                          ? Tools.NONE
                          : Tools.FILTERANNOTATIONS_ROI
                      )
                    }
                    size="large"
                  >
                    <FilterAlt className={classes.toolbarButtonIcon} />
                  </IconButton>
                </Tooltip>
              )}

            {this.checkToolInConfig("PlotNearestTool") &&
              !show3DViewer &&
              !showGallery &&
              !showTilesGallery && (
                <Tooltip
                  disableInteractive
                  placement="left"
                  title="Show nearest objects"
                >
                  <IconButton
                    className={classNames(
                      classes.toolbarButton,
                      activeTool === Tools.PLOTNEAREST_ROI &&
                        classes.toolbarButtonChecked
                    )}
                    onClick={() =>
                      onChangeTool(
                        activeTool === Tools.PLOTNEAREST_ROI
                          ? Tools.NONE
                          : Tools.PLOTNEAREST_ROI
                      )
                    }
                    size="large"
                  >
                    <ScatterPlotIcon className={classes.toolbarButtonIcon} />
                  </IconButton>
                </Tooltip>
              )}
            {this.props.splitscreenCount > 1 && (
              <Tooltip
                disableInteractive
                placement="left"
                title="Toggle fullscreen"
              >
                <IconButton
                  className={classes.toolbarButton}
                  size="small"
                  onClick={() => this.props.onToggleFullscreen()}
                >
                  {this.props.showFullscreen ? (
                    <FullscreenExitIcon className={classes.toolbarButtonIcon} />
                  ) : (
                    <FullscreenIcon className={classes.toolbarButtonIcon} />
                  )}
                </IconButton>
              </Tooltip>
            )}
            {(this.props.showFullscreen || this.props.splitscreenCount === 1) &&
              this.checkToolInConfig("AdjustLayoutTool") &&
              !show3DViewer &&
              !showGallery &&
              !showTilesGallery && (
                <Tooltip
                  disableInteractive
                  placement="top"
                  title="Adjust layout"
                >
                  <IconButton
                    className={classes.toolbarButton}
                    size="small"
                    onClick={(e) =>
                      this.setState({
                        open: !this.state.open,
                        anchorEl: e.target,
                      })
                    }
                  >
                    <ViewQuilt className={classes.toolbarButtonIcon} />
                  </IconButton>
                </Tooltip>
              )}
            {viewerConfig && viewerConfig.project.annotations !== "pixel" ? (
              <React.Fragment>
                <Divider className={classes.divider} />
                {this.checkToolInConfig("DrawRectangleAnnotationTool") &&
                  !show3DViewer &&
                  !showTilesGallery && (
                    <Tooltip
                      disableInteractive
                      placement="left"
                      title="Draw rectangle annotation"
                    >
                      <span>
                        <IconButton
                          className={classNames(
                            classes.toolbarButton,
                            activeTool === Tools.RECT_ROI &&
                              classes.toolbarButtonChecked
                          )}
                          onClick={() =>
                            onChangeTool(
                              activeTool === Tools.RECT_ROI
                                ? Tools.NONE
                                : Tools.RECT_ROI
                            )
                          }
                          size="large"
                        >
                          <FontAwesomeIcon icon={faPlus} />
                        </IconButton>
                      </span>
                    </Tooltip>
                  )}
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Divider className={classes.divider} />
                {this.checkToolInConfig("DrawPixelAnnotationTool") &&
                  !filesGalleryActive &&
                  !show3DViewer &&
                  !showTilesGallery && (
                    <Tooltip
                      disableInteractive
                      placement="left"
                      title={
                        annotationsAreReduced
                          ? "Sample Annotations can not be changed!"
                          : "Draw pixel annotation [Ctrl]+[1]"
                      }
                    >
                      <span>
                        <IconButton
                          id="toolPenBtn"
                          disabled={annotationsAreReduced}
                          className={classNames(
                            classes.toolbarButton,
                            activeTool === Tools.PEN_ROI &&
                              classes.toolbarButtonChecked
                          )}
                          onClick={() =>
                            onChangeTool(
                              activeTool === Tools.PEN_ROI
                                ? Tools.NONE
                                : Tools.PEN_ROI
                            )
                          }
                          size="large"
                        >
                          <FontAwesomeIcon icon={faPen} />
                        </IconButton>
                      </span>
                    </Tooltip>
                  )}

                {this.checkToolInConfig("DrawMagicWandAnnotationTool") &&
                  !show3DViewer &&
                  !showTilesGallery && (
                    <Tooltip
                      disableInteractive
                      placement="left"
                      title={
                        annotationsAreReduced
                          ? "Sample Annotations can not be changed!"
                          : "Use magic wand"
                      }
                    >
                      <span>
                        <IconButton
                          className={classNames(
                            classes.toolbarButton,
                            activeTool === Tools.MAGICWAND_ROI &&
                              classes.toolbarButtonChecked
                          )}
                          disabled={annotationsAreReduced}
                          onClick={() =>
                            onChangeTool(
                              activeTool === Tools.MAGICWAND_ROI
                                ? Tools.NONE
                                : Tools.MAGICWAND_ROI
                            )
                          }
                          size="large"
                        >
                          <FontAwesomeIcon icon={faMagic} />
                        </IconButton>
                      </span>
                    </Tooltip>
                  )}

                {this.checkToolInConfig("DrawRectangleAnnotationTool") &&
                  !filesGalleryActive &&
                  !show3DViewer &&
                  !showTilesGallery && (
                    <Tooltip
                      disableInteractive
                      placement="left"
                      title={
                        annotationsAreReduced
                          ? "Sample Annotations can not be changed!"
                          : "Draw rectangle annotation"
                      }
                    >
                      <span>
                        <IconButton
                          id="toolRectangleBtn"
                          className={classNames(
                            classes.toolbarButton,
                            activeTool === Tools.RECTANGLE_ROI &&
                              classes.toolbarButtonChecked
                          )}
                          disabled={annotationsAreReduced}
                          onClick={() =>
                            onChangeTool(
                              activeTool === Tools.RECTANGLE_ROI
                                ? Tools.NONE
                                : Tools.RECTANGLE_ROI
                            )
                          }
                          size="large"
                        >
                          <CheckBoxOutlineBlank
                            className={classes.toolbarButtonIcon}
                          />
                        </IconButton>
                      </span>
                    </Tooltip>
                  )}

                {this.checkToolInConfig("DrawEllipseAnnotationTool") &&
                  !filesGalleryActive &&
                  !show3DViewer &&
                  !showTilesGallery && (
                    <Tooltip
                      disableInteractive
                      placement="left"
                      title={
                        annotationsAreReduced
                          ? "Sample Annotations can not be changed!"
                          : "Draw circle annotation"
                      }
                    >
                      <span>
                        <IconButton
                          id="toolCircleBtn"
                          className={classNames(
                            classes.toolbarButton,
                            activeTool === Tools.ELLIPSE_ROI &&
                              classes.toolbarButtonChecked
                          )}
                          disabled={annotationsAreReduced}
                          onClick={() =>
                            onChangeTool(
                              activeTool === Tools.ELLIPSE_ROI
                                ? Tools.NONE
                                : Tools.ELLIPSE_ROI
                            )
                          }
                          size="large"
                        >
                          <FontAwesomeIcon icon={faCircle} mask={["far"]} />
                        </IconButton>
                      </span>
                    </Tooltip>
                  )}

                {this.checkToolInConfig("DrawRegionAnnotationTool") &&
                  !filesGalleryActive &&
                  !show3DViewer &&
                  !showTilesGallery && (
                    <Tooltip
                      disableInteractive
                      placement="left"
                      title={
                        annotationsAreReduced
                          ? "Sample Annotations can not be changed!"
                          : "Draw region annotation [Ctrl]+[2]"
                      }
                    >
                      <span>
                        <IconButton
                          id="toolRegionBtn"
                          className={classNames(
                            classes.toolbarButton,
                            activeTool === Tools.REGION_ROI &&
                              classes.toolbarButtonChecked
                          )}
                          disabled={annotationsAreReduced}
                          onClick={() =>
                            onChangeTool(
                              activeTool === Tools.REGION_ROI
                                ? Tools.NONE
                                : Tools.REGION_ROI
                            )
                          }
                          size="large"
                        >
                          <FontAwesomeIcon icon={faDrawPolygon} />
                        </IconButton>
                      </span>
                    </Tooltip>
                  )}

                {(this.checkToolInConfig("DrawRegionGrowingAnnotationTool") ||
                  this.checkToolInConfig("DrawGrabCutAnnotationTool")) && (
                  <Divider className={classes.divider} />
                )}

                {this.checkToolInConfig("DrawRegionGrowingAnnotationTool") &&
                  !show3DViewer &&
                  !showTilesGallery &&
                  !filesGalleryActive && (
                    <Tooltip
                      disableInteractive
                      placement="left"
                      title={
                        annotationsAreReduced
                          ? "Sample Annotations can not be changed!"
                          : "Use region growing tool"
                      }
                    >
                      <span>
                        <IconButton
                          className={classNames(
                            classes.toolbarButton,
                            activeTool === Tools.REGIONGROWING_ROI &&
                              classes.toolbarButtonChecked
                          )}
                          disabled={annotationsAreReduced}
                          onClick={() =>
                            onChangeTool(
                              activeTool === Tools.REGIONGROWING_ROI
                                ? Tools.NONE
                                : Tools.REGIONGROWING_ROI
                            )
                          }
                          size="large"
                        >
                          <AllOutIcon className={classes.toolbarButtonIcon} />
                        </IconButton>
                      </span>
                    </Tooltip>
                  )}

                {this.checkToolInConfig("DrawGrabCutAnnotationTool") &&
                  !show3DViewer &&
                  !showTilesGallery &&
                  !filesGalleryActive && (
                    <Tooltip
                      disableInteractive
                      placement="left"
                      title={
                        annotationsAreReduced
                          ? "Sample Annotations can not be changed!"
                          : "Use smart object segmentation tool"
                      }
                    >
                      <span>
                        <IconButton
                          id="toolGrabCutBtn"
                          className={classNames(
                            classes.toolbarButton,
                            activeTool === Tools.REGIONGRABCUT_ROI &&
                              classes.toolbarButtonChecked
                          )}
                          disabled={annotationsAreReduced}
                          onClick={() =>
                            onChangeTool(
                              activeTool === Tools.REGIONGRABCUT_ROI
                                ? Tools.NONE
                                : Tools.REGIONGRABCUT_ROI
                            )
                          }
                          size="large"
                        >
                          <CustomSvgIcon
                            name="GrabCutIcon"
                            width="22.86"
                            height="23.8"
                            stroke="currentColor"
                            vertAlign="-4px"
                          />
                        </IconButton>
                      </span>
                    </Tooltip>
                  )}

                {this.checkToolInConfig("HeatmapTool") &&
                  !show3DViewer &&
                  !showTilesGallery && (
                    <Tooltip
                      disableInteractive
                      placement="left"
                      title="heatmap"
                    >
                      <IconButton
                        id="toolHeatmapBtn"
                        className={classNames(
                          classes.toolbarButton,
                          activeTool === Tools.HEATMAP &&
                            classes.toolbarButtonChecked
                        )}
                        onClick={() =>
                          onChangeTool(
                            activeTool === Tools.HEATMAP
                              ? Tools.NONE
                              : Tools.HEATMAP
                          )
                        }
                        size="large"
                      >
                        <CustomSvgIcon
                          name="HeatmapIcon"
                          width="22.86"
                          height="23.8"
                          stroke="currentColor"
                          vertAlign="-4px"
                        />
                      </IconButton>
                    </Tooltip>
                  )}

                {(showGallery || showTilesGallery || show3DViewer) && (
                  <Divider className={classes.divider} />
                )}

                {/*viewerConfig &&
              viewerConfig.project.structures[selectedLayer] &&
              viewerConfig.project.structures[selectedLayer].tools.map(
                tool =>
                  tool.name &&
                  tool.name === "alm_gallery_tool" &&
                  showGallery &&
                  !this.props.alruns &&
                  this.checkToolInConfig("OtherTools") && (
                    <Tooltip disableInteractive placement="left"
                      key={tool.name}
                      title={tool.tooltip ? tool.tooltip : "not defined"}
                    >
                      <IconButton
                        className={classNames(
                          classes.toolbarButton,
                          activeTool === tool.name &&
                            classes.toolbarButtonChecked
                        )}
                        style={{ padding: 0 }}
                        onClick={() => {
                          onChangeTool(
                            activeTool === tool.name ? Tools.NONE : tool.name
                          );
                        }}
                      >
                        <InlineSVG src={tool.icon} />
                      </IconButton>
                    </Tooltip>
                  )
                      )*/}
              </React.Fragment>
            )}

            {showGallery || showTilesGallery ? (
              <span></span>
            ) : (
              <span>
                <Divider className={classes.divider} />
                {viewerConfig &&
                viewerConfig.project.annotations !== "pixel" ? (
                  <React.Fragment>
                    {/*this.checkToolInConfig("DrawRectangleAnnotationTool") && (
                  <Tooltip disableInteractive placement="left" title="draw rectangle annotation">
                    <IconButton
                      className={classNames(
                        classes.toolbarButton,
                        activeTool === Tools.RECT_ROI &&
                          classes.toolbarButtonChecked
                      )}
                      onClick={() =>
                        onChangeTool(
                          activeTool === Tools.RECT_ROI
                            ? Tools.NONE
                            : Tools.RECT_ROI
                        )
                      }
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </IconButton>
                  </Tooltip>
                    )*/}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {this.checkToolInConfig("FillHoleAnnotationTool") &&
                      !show3DViewer && (
                        <Tooltip
                          disableInteractive
                          placement="left"
                          title={
                            annotationsAreReduced
                              ? "Sample Annotations can not be changed!"
                              : "Fill hole annotation"
                          }
                        >
                          <span>
                            <IconButton
                              id="toolFillBtn"
                              className={classNames(
                                classes.toolbarButton,
                                activeTool === Tools.FILL &&
                                  classes.toolbarButtonChecked
                              )}
                              disabled={annotationsAreReduced}
                              onClick={() =>
                                onChangeTool(
                                  activeTool === Tools.FILL
                                    ? Tools.NONE
                                    : Tools.FILL
                                )
                              }
                              size="large"
                            >
                              <FontAwesomeIcon icon={faFillDrip} />
                            </IconButton>
                          </span>
                        </Tooltip>
                      )}

                    {this.checkToolInConfig("SelectRegionAnnotationTool") &&
                      !show3DViewer && (
                        <Tooltip
                          disableInteractive
                          placement="left"
                          title={
                            annotationsAreReduced
                              ? "Sample Annotations can not be changed!"
                              : "Select region annotation [Ctrl]+[3]"
                          }
                        >
                          <span>
                            <IconButton
                              id="toolSelectBtn"
                              className={classNames(
                                classes.toolbarButton,
                                activeTool === Tools.SELECTION_ROI &&
                                  classes.toolbarButtonChecked
                              )}
                              disabled={annotationsAreReduced}
                              onClick={() => {
                                onChangeTool(
                                  activeTool === Tools.SELECTION_ROI
                                    ? Tools.NONE
                                    : Tools.SELECTION_ROI
                                );
                                // make showsubtypes true for selected structure an unfold subtypes
                                structures[selectedLayer].showSubtypes = true;
                                this.showSubtypes(structures[selectedLayer]);
                              }}
                              size="large"
                            >
                              <FontAwesomeIcon icon={faMousePointer} />
                            </IconButton>
                          </span>
                        </Tooltip>
                      )}

                    {this.checkToolInConfig("CopyRegionAnnotationTool") &&
                      !show3DViewer && (
                        <Tooltip
                          disableInteractive
                          placement="left"
                          title={
                            annotationsAreReduced
                              ? "Sample Annotations can not be changed!"
                              : "Copy region annotation [Ctrl]+[4]"
                          }
                        >
                          <span>
                            <IconButton
                              id="toolCopyBtn"
                              className={classNames(
                                classes.toolbarButton,
                                activeTool === Tools.COPY_ROI &&
                                  classes.toolbarButtonChecked
                              )}
                              disabled={annotationsAreReduced}
                              onClick={() =>
                                onChangeTool(
                                  activeTool === Tools.COPY_ROI
                                    ? Tools.NONE
                                    : Tools.COPY_ROI
                                )
                              }
                              size="large"
                            >
                              <FontAwesomeIcon icon={faClone} />
                            </IconButton>
                          </span>
                        </Tooltip>
                      )}

                    {this.checkToolInConfig("AITrainingTool") && !show3DViewer && (
                      <Tooltip
                        disableInteractive
                        placement="left"
                        title="Train AI model"
                      >
                        <IconButton
                          id="toolAITrainingBtn"
                          className={classNames(
                            classes.toolbarButton,
                            activeTool === Tools.AITRAINING &&
                              classes.toolbarButtonChecked
                          )}
                          onClick={() =>
                            onChangeTool(
                              activeTool === Tools.AITRAINING
                                ? Tools.NONE
                                : Tools.AITRAINING
                            )
                          }
                          size="large"
                        >
                          <FontAwesomeIcon icon={faBrain} />
                        </IconButton>
                      </Tooltip>
                    )}

                    {this.checkToolInConfig("GridTool") &&
                      !this.props.tiles.getHistoClassificationStarted() &&
                      !this.props.resultTab.getGridExists() &&
                      !show3DViewer && (
                        <Tooltip
                          disableInteractive
                          placement="left"
                          title={
                            annotationsAreReduced
                              ? "Sample Annotations can not be changed!"
                              : "Create grid"
                          }
                        >
                          <span>
                            <IconButton
                              id="toolGridBtn"
                              className={classNames(
                                classes.toolbarButton,
                                activeTool === Tools.GRIDTOOL &&
                                  classes.toolbarButtonChecked
                              )}
                              disabled={annotationsAreReduced}
                              onClick={() => {
                                onChangeTool(
                                  activeTool === Tools.GRIDTOOL
                                    ? Tools.NONE
                                    : Tools.GRIDTOOL
                                );
                              }}
                              size="large"
                            >
                              <FontAwesomeIcon icon={faThLarge} />
                            </IconButton>
                          </span>
                        </Tooltip>
                      )}

                    {this.checkToolInConfig("GridAnnotationTool") &&
                      !show3DViewer && (
                        <Tooltip
                          disableInteractive
                          placement="left"
                          title={
                            annotationsAreReduced
                              ? "Sample Annotations can not be changed!"
                              : "Create annotation grid"
                          }
                        >
                          <span>
                            <IconButton
                              id="toolAnnoGridBtn"
                              className={classNames(
                                classes.toolbarButton,
                                activeTool === Tools.GRIDANNOTATIONTOOL &&
                                  classes.toolbarButtonChecked
                              )}
                              disabled={annotationsAreReduced}
                              onClick={() =>
                                onChangeTool(
                                  activeTool === Tools.GRIDANNOTATIONTOOL
                                    ? Tools.NONE
                                    : Tools.GRIDANNOTATIONTOOL
                                )
                              }
                              size="large"
                            >
                              <FontAwesomeIcon icon={faTh} />
                            </IconButton>
                          </span>
                        </Tooltip>
                      )}
                  </React.Fragment>
                )}

                <Divider className={classes.divider} />

                {structures &&
                  structures[selectedLayer] &&
                  structures[selectedLayer].tools.map((tool) => {
                    const invalidToolNames = [
                      "alm_gallery_tool",
                      "iam_ai_inference",
                    ];
                    return (
                      tool.name &&
                      tools[tool.name] &&
                      !invalidToolNames.includes(tool.name) &&
                      this.checkToolInConfig("OtherTools") &&
                      !show3DViewer && (
                        <Tooltip
                          disableInteractive
                          placement="left"
                          key={tool.name}
                          title={
                            tools[tool.name].toolConfig.tooltip
                              ? tools[tool.name].toolConfig.tooltip
                              : "not defined"
                          }
                        >
                          <span>
                            <IconButton
                              className={classNames(
                                classes.toolbarButton,
                                activeTool === tool.name &&
                                  classes.toolbarButtonChecked
                              )}
                              style={{ padding: 0 }}
                              disabled={annotationsAreReduced}
                              onClick={() => {
                                onChangeTool(
                                  activeTool === tool.name
                                    ? Tools.NONE
                                    : tool.name
                                );
                              }}
                              size="large"
                            >
                              <InlineSVG
                                src={tools[tool.name].toolConfig.icon}
                              />
                            </IconButton>
                          </span>
                        </Tooltip>
                      )
                    );
                  })}
                {structures &&
                  structures[selectedLayer] &&
                  this.props.aiUsedStructures.map(
                    (struct, idx) =>
                      struct.structureName &&
                      tools["iam_ai_inference"] &&
                      structures[selectedLayer].label ===
                        struct.structureName &&
                      structures[selectedLayer].id === struct.id &&
                      this.checkToolInConfig("OtherTools") &&
                      !show3DViewer && (
                        <Tooltip
                          disableInteractive
                          placement="left"
                          key={idx}
                          title={
                            tools["iam_ai_inference"].toolConfig.tooltip
                              ? tools["iam_ai_inference"].toolConfig.tooltip
                              : "not defined"
                          }
                        >
                          <IconButton
                            className={classNames(
                              classes.toolbarButton,
                              activeTool === "iam_ai_inference" &&
                                classes.toolbarButtonChecked
                            )}
                            style={{ padding: 0 }}
                            onClick={() => {
                              onChangeTool(
                                activeTool === "iam_ai_inference"
                                  ? Tools.NONE
                                  : "iam_ai_inference",
                                {
                                  selectedModel: struct.selectedModel,
                                  selectedVersion: struct.selectedVersion,
                                }
                              );
                            }}
                            size="large"
                          >
                            <InlineSVG
                              src={tools["iam_ai_inference"].toolConfig.icon}
                            />
                          </IconButton>
                        </Tooltip>
                      )
                  )}
              </span>
            )}

            {false &&
              this.checkToolInConfig("PassiveLearningTool") &&
              !show3DViewer &&
              showGallery &&
              !this.props.alruns && (
                <Tooltip
                  disableInteractive
                  placement="left"
                  title="Start training and apply model manually"
                >
                  <IconButton
                    className={classes.toolbarButton}
                    onClick={() => this.onStartTraining()}
                    size="large"
                  >
                    <FontAwesomeIcon icon={faBrain} />
                  </IconButton>
                </Tooltip>
              )}

            {this.checkToolInConfig("ApplyModelTool") &&
              !show3DViewer &&
              !showGallery &&
              !this.props.alruns && (
                <Tooltip
                  disableInteractive
                  placement="left"
                  title="Apply model manually"
                >
                  <IconButton
                    className={classes.toolbarButton}
                    onClick={() => this.ApplyModel()}
                    size="large"
                  >
                    <FontAwesomeIcon icon={faPlayCircle} />
                  </IconButton>
                </Tooltip>
              )}

            {this.checkToolInConfig("SaveImagesTool") &&
              !show3DViewer &&
              showGallery &&
              !this.props.alruns && (
                <Tooltip
                  disableInteractive
                  placement="left"
                  title="Export images (all scenes)"
                >
                  <IconButton
                    className={classes.toolbarButton}
                    onClick={() => this.onSaveImages(true)}
                    size="large"
                  >
                    <FontAwesomeIcon icon={faDownload} />
                  </IconButton>
                </Tooltip>
              )}
            {this.checkToolInConfig("SaveImagesTool") &&
              !show3DViewer &&
              showGallery &&
              !this.props.alruns && (
                <Tooltip
                  disableInteractive
                  placement="left"
                  title="Export images (current scene)"
                >
                  <IconButton
                    className={classes.toolbarButton}
                    onClick={() => this.onSaveImages(false)}
                    size="large"
                  >
                    <FontAwesomeIcon icon={faFileDownload} />
                  </IconButton>
                </Tooltip>
              )}
          </div>
        </div>
        <div>
          <div
            className={classes.toolBarRow}
            ref={(bottomElement) => {
              this.bottomElement = bottomElement;
            }}
          >
            {!showGallery && !showTilesGallery && !show3DViewer ? (
              <div>
                {this.checkToolInConfig("CommentTool") && (
                  <React.Fragment>
                    <Divider className={classes.divider} />
                    <Tooltip
                      disableInteractive
                      placement="left"
                      title="Add comments"
                    >
                      <IconButton
                        className={classNames(
                          classes.toolbarButton,
                          activeTool === Tools.COMMENT_ROI &&
                            classes.toolbarButtonChecked
                        )}
                        onClick={() =>
                          onChangeTool(
                            activeTool === Tools.COMMENT_ROI
                              ? Tools.NONE
                              : Tools.COMMENT_ROI
                          )
                        }
                        size="large"
                      >
                        <RateReviewIcon className={classes.toolbarButtonIcon} />
                      </IconButton>
                    </Tooltip>
                  </React.Fragment>
                )}

                {this.props.showFullscreen && (
                  <Tooltip disableInteractive placement="left" title="Overlay">
                    <IconButton
                      className={classes.toolbarButton}
                      onClick={() => this.props.onToggleOverlay()}
                      size="large"
                    >
                      {this.props.showOverlay ? (
                        <FlipIcon
                          className={classes.toolbarButtonIcon}
                          style={{
                            color: "#0673C1",
                            transform: "rotate(270deg)",
                          }}
                        />
                      ) : (
                        <FlipIcon
                          className={classes.toolbarButtonIcon}
                          style={{ transform: "rotate(270deg)" }}
                        />
                      )}
                    </IconButton>
                  </Tooltip>
                )}

                {this.checkToolInConfig("LandmarkTool") && (
                  <Tooltip
                    disableInteractive
                    placement="left"
                    title="Add Landmarks"
                  >
                    <IconButton
                      className={classNames(
                        classes.toolbarButton,
                        activeTool === Tools.LANDMARK &&
                          classes.toolbarButtonChecked
                      )}
                      onClick={() =>
                        onChangeTool(
                          activeTool === Tools.LANDMARK
                            ? Tools.NONE
                            : Tools.LANDMARK
                        )
                      }
                      size="large"
                    >
                      <PinDropIcon className={classes.toolbarButtonIcon} />
                    </IconButton>
                  </Tooltip>
                )}

                {this.props.showFullscreen && this.props.showOverlay && (
                  <Tooltip
                    disableInteractive
                    placement="left"
                    title="WindowTool"
                  >
                    <IconButton
                      className={classes.toolbarButton}
                      onClick={() => this.props.onToggleWindowTool()}
                      size="large"
                    >
                      {this.props.showWindowTool ? (
                        <SelectAllIcon
                          className={classes.toolbarButtonIcon}
                          style={{
                            color: "#0673C1",
                          }}
                        />
                      ) : (
                        <SelectAllIcon className={classes.toolbarButtonIcon} />
                      )}
                    </IconButton>
                  </Tooltip>
                )}
                {this.checkToolInConfig("ScreenshotTool") && (
                  <Tooltip
                    disableInteractive
                    placement="left"
                    title="Download current view [Ctrl]+[P]"
                  >
                    <IconButton
                      id="toolPrintBtn"
                      className={classes.toolbarButton}
                      onClick={onSaveScreenshot}
                      size="large"
                    >
                      <FontAwesomeIcon icon={faCamera} />
                    </IconButton>
                  </Tooltip>
                )}

                <Divider className={classes.divider} />

                {this.checkToolInConfig("ZoomOriginalTool") && showZoomLevels && (
                  <Tooltip
                    disableInteractive
                    placement="left"
                    title="Zoom 100% [Shift]+[*]"
                  >
                    <IconButton
                      id="toolZoomOriginalBtn"
                      className={classes.toolbarButton}
                      onClick={onZoomOriginal}
                      size="large"
                    >
                      <div className={classes.textIcon}>1:1</div>
                    </IconButton>
                  </Tooltip>
                )}

                {this.checkToolInConfig("ZoomOriginalTool") && showZoomLevels && (
                  <Tooltip disableInteractive placement="left" title="Zoom 75%">
                    <IconButton
                      id="toolZoom75lBtn"
                      className={classes.toolbarButton}
                      onClick={() => this.props.onZoomOneToN(0.25)}
                      size="large"
                    >
                      <div className={classes.textIcon}>1:4</div>
                    </IconButton>
                  </Tooltip>
                )}

                {this.checkToolInConfig("ZoomOriginalTool") && showZoomLevels && (
                  <Tooltip disableInteractive placement="left" title="Zoom 10%">
                    <IconButton
                      id="toolZoom10Btn"
                      className={classes.toolbarButton}
                      onClick={() => this.props.onZoomOneToN(0.1)}
                      size="large"
                    >
                      <div className={classes.textIcon}>1:10</div>
                    </IconButton>
                  </Tooltip>
                )}

                {this.checkToolInConfig("ZoomFitTool") && showZoomLevels && (
                  <Tooltip
                    disableInteractive
                    placement="left"
                    title="Zoom fit [Shift]+[0]"
                  >
                    <IconButton
                      id="toolZoomFitBtn"
                      className={classes.toolbarButton}
                      onClick={onZoomFit}
                      size="large"
                    >
                      <ZoomOutMap className={classes.toolbarButtonIcon} />
                    </IconButton>
                  </Tooltip>
                )}

                {this.checkToolInConfig("ZoomInOutTool") && showZoomLevels && (
                  <Tooltip
                    disableInteractive
                    placement="left"
                    title="Zoom in [Shift]+[+]"
                  >
                    <IconButton
                      id="toolZoomInBtn"
                      className={classes.toolbarButton}
                      onClick={onZoomDelta.bind(this, -1)}
                      size="large"
                    >
                      <ZoomIn className={classes.toolbarButtonIcon} />
                    </IconButton>
                  </Tooltip>
                )}
                {this.checkToolInConfig("ZoomInOutTool") && showZoomLevels && (
                  <Tooltip
                    disableInteractive
                    placement="left"
                    title="Zoom out [Shift]+[-]"
                  >
                    <IconButton
                      id="toolZoomOutBtn"
                      className={classes.toolbarButton}
                      onClick={onZoomDelta.bind(this, 1)}
                      size="large"
                    >
                      <ZoomOut className={classes.toolbarButtonIcon} />
                    </IconButton>
                  </Tooltip>
                )}

                <Divider className={classes.divider} />

                {this.checkToolInConfig("SaveTool") && showToggleSideBar && (
                  <Tooltip
                    disableInteractive
                    placement="left"
                    title="Toggle sidebar"
                  >
                    <IconButton
                      className={classes.toolbarButton}
                      onClick={onToggleSideBar}
                      size="large"
                    >
                      <FontAwesomeIcon icon={faExchangeAlt} />
                    </IconButton>
                  </Tooltip>
                )}

                <Divider className={classes.divider} />

                {showUndoRedo && (
                  <Tooltip
                    disableInteractive
                    placement="left"
                    title="Undo [Ctrl]+[Z]"
                  >
                    <span>
                      <IconButton
                        className={classNames(
                          classes.toolbarButton,
                          classes.toolbarButtonChecked
                        )}
                        disabled={!projectHistory.canUndo}
                        onClick={projectHistory.undo}
                        size="large"
                      >
                        <UndoIcon className={classes.toolbarButtonIcon} />
                      </IconButton>
                    </span>
                  </Tooltip>
                )}

                {showUndoRedo && (
                  <Tooltip
                    disableInteractive
                    placement="left"
                    title="Redo [Ctrl]+[Y]"
                  >
                    <span>
                      <IconButton
                        className={classNames(
                          classes.toolbarButton,
                          classes.toolbarButtonChecked
                        )}
                        disabled={!projectHistory.canRedo}
                        onClick={projectHistory.redo}
                        size="large"
                      >
                        <RedoIcon className={classes.toolbarButtonIcon} />
                      </IconButton>
                    </span>
                  </Tooltip>
                )}

                {this.checkToolInConfig("SaveTool") && showSaveTool && (
                  <Tooltip
                    disableInteractive
                    placement="left"
                    title="Save [Ctrl]+[S]"
                  >
                    <IconButton
                      className={classNames(
                        classes.toolbarButton,
                        classes.toolbarButtonChecked
                      )}
                      onClick={onSave}
                      size="large"
                    >
                      <Save className={classes.toolbarButtonIcon} />
                    </IconButton>
                  </Tooltip>
                )}
              </div>
            ) : (
              <div style={{ position: "absolute", bottom: 0 }}>
                <Divider className={classes.divider} />
                {this.checkToolInConfig("SaveTool") &&
                  showToggleSideBar &&
                  !show3DViewer && (
                    <Tooltip
                      disableInteractive
                      placement="left"
                      title="Toggle sidebar"
                    >
                      <IconButton
                        className={classes.toolbarButton}
                        onClick={onToggleSideBar}
                        size="large"
                      >
                        <FontAwesomeIcon icon={faExchangeAlt} />
                      </IconButton>
                    </Tooltip>
                  )}
                <Divider className={classes.divider} />
                {this.checkToolInConfig("SaveTool") &&
                  showSaveTool &&
                  !show3DViewer && (
                    <Tooltip
                      disableInteractive
                      placement="left"
                      title="Save [Ctrl]+[S]"
                    >
                      <IconButton
                        className={classNames(
                          classes.toolbarButton,
                          classes.toolbarButtonChecked
                        )}
                        onClick={() => this.saveProject()}
                        size="large"
                      >
                        <Save className={classes.toolbarButtonIcon} />
                      </IconButton>
                    </Tooltip>
                  )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

// define the component's interface
VerticalToolBar.propTypes = {
  classes: PropTypes.object.isRequired,
  verticalToolBarWidth: PropTypes.number,
  viewerConfig: PropTypes.object,
  structures: PropTypes.array,
  // change view layout
  onToggleTimeBar: PropTypes.func,
  showTimeBar: PropTypes.bool,
  displayTimeBar: PropTypes.bool,
  onToggleMiniMap: PropTypes.func,
  showObjectIdx: PropTypes.bool,
  showMiniMap: PropTypes.bool,
  onToggleScaleBar: PropTypes.func,
  showScaleBar: PropTypes.bool,
  showGallery: PropTypes.bool,
  show3DViewer: PropTypes.bool,
  showPointCloud: PropTypes.bool,
  onToggleZStackBar: PropTypes.func,
  showZStackBar: PropTypes.bool,
  displayZStackBar: PropTypes.bool,
  onToggleImageInfo: PropTypes.func,
  onToggleResultTable: PropTypes.func,
  showImageInfo: PropTypes.bool,
  showZoomBar: PropTypes.bool,
  onToggleGallery: PropTypes.func,
  // tools
  onChangeTool: PropTypes.func,
  activeTool: PropTypes.string,
  // change layer
  selectedLayer: PropTypes.number,
  // screenshot functions
  onSaveScreenshot: PropTypes.func,
  // zoom functions
  onZoomOriginal: PropTypes.func,
  onZoomOneToN: PropTypes.func,
  onZoomDelta: PropTypes.func,
  onZoomFit: PropTypes.func,
  // toggle Sidebar
  onToggleSideBar: PropTypes.func,
  // save changes
  onSave: PropTypes.func,
  resizeSideBar: PropTypes.func,
  // from withTiles
  tiles: PropTypes.object,
  updateToolBarWidth: PropTypes.func,
  isImporting: PropTypes.bool,
  //not ordered
  project: PropTypes.object,
  spinloader: PropTypes.object,
  setAlruns: PropTypes.func,
  roiLayers: PropTypes.array,
  saveChangesGallery: PropTypes.func,
  projectId: PropTypes.string,
  fileId: PropTypes.string,
  trainingWarning: PropTypes.func,
  applyDL: PropTypes.func,
  tools: PropTypes.array,
  persistentStorage: PropTypes.object,
  projectContext: PropTypes.object,
  onToggle3DViewer: PropTypes.func,
  onToggleMeshView: PropTypes.func,
  showTilesGallery: PropTypes.bool,
  projectHistory: PropTypes.object,
  ome: PropTypes.object,
  activeTab: PropTypes.number,
  rendererRef: PropTypes.object,
  setGalleryTool: PropTypes.func,
  changeToSelectedFile: PropTypes.func,
  onToggleTilesGallery: PropTypes.func,
  splitscreenCount: PropTypes.number,
  onToggleFullscreen: PropTypes.func,
  showFullscreen: PropTypes.bool,
  resultTab: PropTypes.object,
  alruns: PropTypes.bool,
  onToggleOverlay: PropTypes.func,
  showOverlay: PropTypes.bool,
  onToggleWindowTool: PropTypes.func,
  showWindowTool: PropTypes.bool,
  filesGalleryActive: PropTypes.bool,
  aiUsedStructures: PropTypes.array,
  annotationsAreReduced: PropTypes.bool,
};

export default withAllViewerContexts(
  withTiles(withResultTab(withStyles(styles)(VerticalToolBar)))
);
