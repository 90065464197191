import React, { Component } from "react";
import PropTypes from "prop-types";

import Histogram from "../Histogram";
import FileTreeView from "../FileTreeView";

import withStyles from "@mui/styles/withStyles";

import { Grid, Typography } from "@mui/material";

import { withAllViewerContexts } from "../../contexts/AllViewerContexts";
import { withTiles } from "../../contexts/TilesContext";
import { withResultTab } from "../../contexts/ResultTabContext";

// define the component's styling
const styles = () => ({
  flexRowRemainingHeight: {
    flex: "1 1 auto",
    overflowY: "auto",
  },
  spacing: {
    padding: 10,
    paddingBottom: 0,
    paddingTop: 0,
  },
  histogramContainer: {
    minHeight: 280,
  },
});

class SideBarTabView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 0,
      channelSelectMode: "all",
      availableModels: [],
      isMounted: false,
    };
  }

  componentDidMount = () => {
    this.setState({ isMounted: true });
  };

  changeChannelSelectMode = (mode) => {
    this.setState({
      channelSelectMode: mode,
    });
  };

  checkToolInConfig(toolName) {
    if (this.props.viewerConfig) {
      if (this.props.viewerConfig.project.toolsInProject[toolName]) {
        return true;
      } else {
        return false;
      }
    }
  }

  render() {
    const {
      classes,
      fileId,
      histogramConfig,
      ome,
      onChangeChannels,
      onSelectFile,
      onExcludeFilesToggle,
      project,
      projectContext,
      sideBarWidth,
      splitscreenFileIds,
      tiles,
      visible,
    } = this.props;

    if (!visible) return null;

    return (
      <Grid container className={classes.flexRowRemainingHeight}>
        {histogramConfig[fileId] && (
          <Grid className={classes.spacing} item xs={12}>
            <Typography variant="h6" style={{ fontSize: "18px" }}>
              Histogram:
            </Typography>
            <div className={classes.histogramContainer}>
              {this.props.ome && this.state.isMounted && (
                <Histogram
                  sideBarWidth={sideBarWidth}
                  histogramConfig={histogramConfig[fileId]}
                  bitDepth={ome.bitDepth}
                  id={fileId}
                  channelSelectMode={this.state.channelSelectMode}
                  changeChannelSelectMode={this.changeChannelSelectMode}
                  onChange={onChangeChannels}
                  style={{ borderBottom: "#EBEBEB solid" }}
                  projectType={project.type}
                  projectContext={projectContext}
                />
              )}
            </div>

            {!tiles.getHistoClassificationStarted() && (
              <FileTreeView
                project={project}
                fileId={fileId}
                splitscreenFileIds={splitscreenFileIds}
                onSelectFile={onSelectFile}
                onExcludeFilesToggle={onExcludeFilesToggle}
              />
            )}
          </Grid>
        )}
      </Grid>
    );
  }
}

// define the component's interface
SideBarTabView.propTypes = {
  classes: PropTypes.object.isRequired,
  // sidebar tab properties
  sideBarWidth: PropTypes.number,
  visible: PropTypes.bool,
  // general properties
  id: PropTypes.string.isRequired,
  viewerConfig: PropTypes.object,
  // historgram
  ome: PropTypes.object,
  histogramConfig: PropTypes.object,
  onChangeChannels: PropTypes.func,
  // file management
  onSelectFile: PropTypes.func,
  onExcludeFilesToggle: PropTypes.func,
  splitscreenFileIds: PropTypes.array,
  // not ordered
  fileId: PropTypes.string,
  project: PropTypes.object,
  projectContext: PropTypes.object,
  tiles: PropTypes.object,
  resultTab: PropTypes.object,
};

export default withAllViewerContexts(
  withTiles(withResultTab(withStyles(styles)(SideBarTabView)))
);
