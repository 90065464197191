//array functions

import convert from "color-convert";

//returns the nth largest value from array
export function nthlargest(arra, highest) {
  let x = 0;
  let y = 0;
  let z = 0;
  let temp = 0;
  const tnum = arra.length;
  let flag = false;
  let result = false;

  while (x < tnum) {
    y = x + 1;

    if (y < tnum) {
      for (z = y; z < tnum; z++) {
        if (arra[x] < arra[z]) {
          temp = arra[z];
          arra[z] = arra[x];
          arra[x] = temp;
          flag = true;
        } else {
          continue;
        }
      }
    }

    if (flag) {
      flag = false;
    } else {
      x++;
      if (x === highest) {
        result = true;
      }
    }
    if (result) {
      break;
    }
  }

  return arra[highest - 1];
}

/**
 *
 * @param {Array} colors
 * @returns {Array}
 */
export function sortColors(colors) {
  return colors.sort((a, b) => {
    const colorA = convert.hex.lab.raw(a);
    const colorB = convert.hex.lab.raw(b);

    // When lab[0] values are identical, start comparing lab[1] values
    return colorA[0] - colorB[0] || colorA[1] - colorB[1];
  });
}
