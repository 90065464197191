import React, { Component } from "react";
import PropTypes from "prop-types";

import withStyles from "@mui/styles/withStyles";
import {
  CircularProgress,
  IconButton,
  LinearProgress,
  Tooltip,
  Fade,
  Popper,
  Paper,
} from "@mui/material";
import { trackTransforms, getBaseLog } from "../utils/CanvasUtil";
import {
  configureImage,
  colorInImage,
  tileRegistration,
  calculateTransformationMatrix,
} from "../utils/RendererUtils";
import { getParentIndexLayer, getParentIndex } from "../utils/StructuresUtils";
import Backend from "../../common/utils/Backend";
import { RectROI, RegionROI } from "../utils/ROI";
// import { knn } from "../utils/rbush-knn";
import RBush from "rbush";
import ScaleBar from "./ScaleBar";
import ZoomBar from "./ZoomBar";
import MiniMap from "./MiniMap";
import ResultTable from "./ResultTable";
import { Tools } from "./VerticalToolBar";
import TimeLineTool from "./TimeLineTool";
import ZStackBar from "./ZStackBar";
import ImageInfo from "./ImageInfo";
import ToggleButton from "./ToggleButton";
import { Resizable } from "react-resizable";
import "react-resizable/css/styles.css";

import { withPersistentStorage } from "../contexts/PersistentStorageContext";
import { withTiles } from "../contexts/TilesContext";
import { withResultTab } from "../contexts/ResultTabContext";
import { ViewQuilt } from "@mui/icons-material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import h337 from "heatmapjs";

// debug flags
const FPS = false;

const styles = {
  canvas: {
    position: "relative",
    display: "block",
    width: "100%",
    backgroundColor: "#D3D3D3",
  },

  root: {
    height: "100%",
  },

  heatmapContainer: {
    pointerEvents: "none",
    position: "absolute !important",
    zIndex: "9998",
    width: "100%",
    height: "100%",
  },

  toolbarButtonRoot: {
    color: "#666",
    position: "absolute",
    top: 5,
    right: 75,
    zIndex: 100,
  },
  toolbarButtonRootSingle: {
    color: "#666",
    position: "absolute",
    top: 5,
    right: 5,
    zIndex: 100,
  },
  toolbarButton: {
    position: "relative",
    display: "inline-block",

    width: 40,
    height: 40,
    padding: 8,
    margin: 0,
  },
  toolbarButtonIcon: {
    verticalAlign: "-4px",
  },
  toolbarButtonChecked: {
    width: 40,
    color: "#0673C1",
  },
  progress: {
    position: "absolute",
    margin: -20,
    left: "50%",
    top: "50%",
    zIndex: 1000,
  },
  fps: {
    position: "absolute",
    right: 50,
    top: 50,
    color: "white",
    fontSize: 20,
    pointerEvents: "none",
  },
  resizableContainer: {
    "& .react-resizable-handle": {
      pointerEvents: "all",
    },
    "& .react-resizable-handle-se::before": {
      content: "''",
      display: "block",
      position: "absolute",
      bottom: 3,
      right: 3,
      width: 6,
      height: 6,
      borderBottom: "1px solid #0673c1",
      borderRight: "1px solid #0673c1",
    },
  },
  fileNavLeftBtn: {
    position: "absolute",
    left: 5,
    top: "50%",
    marginTop: "-40px",
    "& svg": {
      fontSize: "80px",
    },
  },
  fileNavRightBtn: {
    position: "absolute",
    right: 5,
    top: "50%",
    marginTop: "-40px",
    "& svg": {
      fontSize: "80px",
    },
  },
};

Number.prototype.toBase = function (base) {
  var symbols =
    "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");
  var decimal = this;
  var conversion = "";

  if (base > symbols.length || base <= 1) {
    return false;
  }

  while (decimal >= 1) {
    conversion =
      symbols[decimal - base * Math.floor(decimal / base)] + conversion;
    decimal = Math.floor(decimal / base);
  }

  return base < 11 ? parseInt(conversion) : conversion;
};

const debounce = (func, delay) => {
  let inDebounce;
  return function () {
    const context = this;
    const args = arguments;
    clearTimeout(inDebounce);
    inDebounce = setTimeout(() => func.apply(context, args), delay);
  };
};

const rgbToHex = (r, g, b) => {
  if (r > 255 || g > 255 || b > 255) throw "Invalid color component";
  return "#" + ((r << 16) | (g << 8) | b).toString(16);
};

class Renderer extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    // transmit context to componentRef
    if (props.componentRef) props.componentRef(this);

    this.state = {
      previewWidth: 100,
      previewHeight: 100,
      dragStart: null,
      isDrawing: false,
      mouseOnCanvas: false,
      ome: props.ome,
      initialized: false,
      initializedZ: false,
      initialScale: 1,
      tMin: 0,
      tMax: 0,
      t: 0,
      z: Math.floor(props.ome.sizeZ / 2),
      minZ: 0,
      maxZ: props.ome.sizeZ - 1,
      fps: 0,
      // time playback
      sr: 60,
      // z playback
      zsr: 60,
      bufferSize: 100,
      playing: false,
      playingZ: false,
      playDirection: 1,
      playDirectionZ: 1,
      miniMapKey: 0,
      // tools
      rois: [],
      selectedROI: null,
      limitVisibleRegions: false,
      layerTrees: [],
      previewRectChanged: false,
      isBrightfield:
        props.ome &&
        props.ome.channels.length === 1 &&
        props.ome.channels[0].type === "brightfield",
      scaleBarData: null,
      startY: 0,
      showMiniMap: true,
      showObjectIdx: false,
      miniMapReady: false,
      open: false,
      anchorEl: null,
      displayTimeBar: false,
      displayZStackBar: false,
      showScaleBar: true,
      showTimeBar: false,
      showZStackBar: false,
      showImageInfo: true,
      showZoomBar: true,
      showResultTable: false,
      showFileNavButtons: false,
      hideMiniMap: false,
      colorPickerActive: false,
    };
    this.updateCounter = 0;
    this.visibleRegionsLimit = 3000;
    this.visibleRegionsRadius = 1000;
    this.structureRegionLimits = [];
    this.slowestStructureIdx = -1;
    this.fastestStructureIdx = -1;
    this.fileIdStateData = {};

    this.initFrameArray();
    this.updateExistingStructures();

    this.lineIdx = 0;
    this.keepRendering = true;

    this.lastMoveTime = performance.now();

    this.selROI = null;
    window.setNewSelRoi = this.setNewSelRoi;
    window.getMousePositionInImage = this.getMousePositionInImage;
    window.setZoomLevelForGridSize = this.setZoomLevelForGridSize;

    // frontend cached alpha channel images
    this.visibleImage = [];
    // frontend cached colored images
    this.coloredImages = [];

    this.hMat = [];
    this.imgRegistered = true;
    this.callbackCounter = 0;

    this.oldVisibleRegionCount = 0;

    this.gridTileCount = 0;

    // fps history to smooth fps counter update
    this.lastFps = Array.from(Array(10), () => 60);

    this.createBackgroundPattern();
    this.tempLayer = null;
  }

  setMountedState = (stateObject, callback) => {
    if (this._isMounted) {
      this.setState(stateObject, callback);
    }
  };

  createBackgroundPattern = () => {
    this.backgroundPattern = document.createElement("canvas");
    const patternContext = this.backgroundPattern.getContext("2d");

    // Give the pattern a width and height of 50
    const w = 30;
    const h = 30;
    this.backgroundPattern.width = w;
    this.backgroundPattern.height = h;

    // Give the pattern a background color and draw an arc
    patternContext.fillStyle = "transparent";
    patternContext.strokeStyle = "#999";
    patternContext.fillRect(0, 0, w, h);
    patternContext.lineWidth = 1;
    patternContext.moveTo(-w / 2, h);
    patternContext.lineTo(w, -h / 2);
    patternContext.moveTo(0, h + h / 2);
    patternContext.lineTo(w + w / 2, 0);
    patternContext.stroke();
  };

  componentDidMount = () => {
    this._isMounted = true;
    let stateObject = {};
    this.canvas = document.getElementById(this.props.canvasId);
    this.props.resultTab.setRendererCanvas(this.canvas);
    // get drawing context from canvas
    this.ctx = this.canvas.getContext("2d");
    this.props.resultTab.setRendererCtx(this.ctx);

    // bind mouse wheel events
    this.canvas.addEventListener("DOMMouseScroll", this.mousewheel, true);
    this.canvas.addEventListener("mousewheel", this.mousewheel, {
      passive: false,
    });
    // bind mouse events
    window.addEventListener("mouseup", this.mouseup, { passive: true });
    window.addEventListener("mousemove", this.mousemove, { passive: true });

    this.heatmap = h337.create({
      container: document.getElementById("heatmapDiv"),
      gradient: {
        ".25": "#0000ff",
        ".5": "#00ff00",
        ".75": "#ffff00",
        ".99": "#ff0000",
      },
      maxOpacity: 0.8,
      minOpacity: 0,
      blur: 0.99,
    });

    // extend context with some fancy additional transformation methods
    trackTransforms(this.ctx);

    // converts the relative size css information into an absolute size which we can access
    this.canvas.width = this.canvas.offsetWidth;
    this.canvas.height = this.canvas.offsetHeight;

    // initialize position
    stateObject.lastX = this.canvas.width / 2;
    stateObject.lastY = this.canvas.height / 2;

    this.t0 = performance.now();

    let loadObject = [
      "hideMiniMap",
      "showObjectIdx",
      "showMiniMap",
      "showScaleBar",
      "showTimeBar",
      "showZStackBar",
      "showImageInfo",
      "showZoomBar",
      "showResultTable",
      "showFileNavButtons",
    ];

    let i = null;
    if (this.props.showFullscreen) {
      loadObject.forEach((element) => {
        i = this.props.persistentStorage.load(element + "Full");
        if (i === true || i === false) stateObject[element] = i;
      });
    } else {
      loadObject.forEach((element) => {
        i = this.props.persistentStorage.load(element);
        if (i === true || i === false) stateObject[element] = i;
      });
    }

    stateObject["displayTimeBar"] = this.props.displayTimeBar;
    stateObject["displayZStackBar"] = this.props.displayZStackBar;
    stateObject["showZStackBar"] = this.props.showZStackBar;
    stateObject["showTimeBar"] = this.props.showTimeBar;

    this.setMountedState(stateObject, () => {
      this.checkMiniMapVisivility();
    });
    //set mouse postition to center of canvas
    this.mousePos = this.getMousePositionInImage();
    this.draw();
  };

  loadFrameArray(frameIndex) {
    this.applyFrameArray(frameIndex);
  }

  initFrameArray() {
    const ome = this.state.ome;
    const frameIndex = this.state.t * ome.sizeZ + this.state.z;
    this.loadFrameArray(frameIndex);
  }

  // looks for annotations to visible frame excluding base roi
  applyFrameArray(frameIndex) {
    const roiLayers = this.props.roiLayers;
    if (Object.keys(this.props.frameArrayDict[this.props.fileId]).length === 0)
      return;

    for (let idx = 1; idx < this.props.structures.length; idx++) {
      const structure = this.props.structures[idx];
      let newRoiLayer;
      newRoiLayer = this.findRoiLayer(
        this.props.frameArrayDict[this.props.fileId][frameIndex],
        structure
      );
      roiLayers[idx] = newRoiLayer;
    }
  }

  storeRoiLayersToFrameArray(frameIndex) {
    let updatedFrameArrayFrame = [];
    for (let structure of this.props.structures) {
      if (structure.id === 1) {
        continue;
      }
      updatedFrameArrayFrame.push(
        this.findRoiLayer(this.props.roiLayers, structure)
      );
    }
    this.props.frameArrayDict[this.props.fileId][frameIndex] =
      updatedFrameArrayFrame;
  }

  findRoiLayer(roiLayers, structure) {
    const roiLayer = roiLayers
      ? roiLayers.find((x) => structure.id === x.id)
      : roiLayers;
    if (roiLayer === undefined) {
      return this.getClearedRoiLayer(structure.id);
    }
    return roiLayer;
  }

  flipRoiLayers(prevState) {
    const prevT = Math.floor(prevState.t);
    const prevZ = Math.floor(prevState.z);
    const curT = Math.floor(this.state.t);
    const curZ = Math.floor(this.state.z);
    const prevFrameIndex = prevT * this.state.ome.sizeZ + prevZ;
    const frameIndex = curT * this.state.ome.sizeZ + curZ;

    this.storeRoiLayersToFrameArray(prevFrameIndex);
    this.applyFrameArray(frameIndex);
    this.storeRoiLayersToFrameArray(frameIndex);
  }

  updateFrameArray() {
    const curT = Math.floor(this.state.t);
    const curZ = Math.floor(this.state.z);
    const frameIndex = curT * this.state.ome.sizeZ + curZ;
    this.storeRoiLayersToFrameArray(frameIndex);
    const structure = this.props.structures[this.props.selectedLayer];
    if (!structure) {
      return;
    }
    const roiLayer = this.findRoiLayer(this.props.roiLayers, structure);
    roiLayer.isKeyFrame = roiLayer.layer.regionRois.length > 0;
  }

  getClearedRoiLayer(id) {
    return {
      id: id,
      layer: {
        inverted: false,
        regionRois: [],
      },
      tree: new RBush(),
    };
  }

  /**
   * Clear roiLayers except base roi
   */
  clearRoiLayers() {
    for (let i = 1; i < this.props.roiLayers.length; i++) {
      this.props.roiLayers[i] = this.getClearedRoiLayer(
        this.props.roiLayers[i].id
      );
    }
  }

  removeLayersFromFrameArray(removedStructures) {
    for (let structureId of removedStructures) {
      for (let frameIndex in this.props.frameArrayDict[this.props.fileId]) {
        const roiLayers =
          this.props.frameArrayDict[this.props.fileId][frameIndex];
        for (let i = roiLayers.length - 1; i >= 0; i--) {
          if (roiLayers[i].id === structureId) {
            roiLayers.splice(i, 1);
          }
        }
      }
    }
  }

  updateExistingStructures() {
    this.existingStructures = {};
    for (let structure of this.props.structures) {
      this.existingStructures[structure.id] = true;
    }
  }

  getRemovedStructures() {
    const removedStructures = [];
    for (let layerId in this.existingStructures) {
      if (!this.props.structures.find((x) => x.id === layerId)) {
        removedStructures.push(layerId);
      }
    }
    return removedStructures;
  }

  componentDidUpdate(prevProps, prevState) {
    const oldFileId = prevProps.fileId;
    const newFileId = this.props.fileId;
    const oldFrameIndex = prevState.t * prevState.ome.sizeZ + prevState.z;
    //const newFrameIndex = this.state.t * this.state.ome.sizeZ + this.state.z;
    const prevT = Math.floor(prevState.t);
    const prevZ = Math.floor(prevState.z);
    const curT = Math.floor(this.state.t);
    const curZ = Math.floor(this.state.z);

    const removedStructures = this.getRemovedStructures();
    this.removeLayersFromFrameArray(removedStructures);
    this.updateExistingStructures();

    if (prevT !== curT || prevZ !== curZ) {
      this.flipRoiLayers(prevState);
    }

    // on file change => reset renderer cache
    if (oldFileId && oldFileId !== newFileId) {
      // TODO: [BP-35] fix this part for histo modules (fix hot fix correctly)
      let histoModule =
        this.props.project.type.includes("HistoClassification") ||
        this.props.project.type.includes("HistoPointCounting");
      if (
        !this.props.frameArrayDict[newFileId][oldFrameIndex] &&
        !histoModule
      ) {
        this.clearRoiLayers();
      } else {
        this.applyFrameArray(oldFrameIndex);
      }
      this.fileIdStateData[oldFileId] = prevState;

      if (this.fileIdStateData[newFileId]) {
        this.setMountedState(this.fileIdStateData[newFileId]);
      }

      setTimeout(() => this.zoomFit(), 100);
      this.setMountedState({
        miniMapKey: new Date().getTime(),
      });
    }
    if (
      prevProps.loadedAnnotationsCounter !== this.props.loadedAnnotationsCounter
    ) {
      this.initFrameArray();
    }

    let idx = this.props.structures.findIndex((s) => s.label === "Grid");
    if (this.props.roiLayers[idx]) {
      if (idx > -1) {
        this.gridTileCount = this.props.roiLayers[idx].layer.regionRois.length;
      } else {
        this.gridTileCount = 0;
      }
    }
  }

  componentWillUnmount = () => {
    this._isMounted = false;
    // unbind mouse wheel events
    this.canvas.removeEventListener("DOMMouseScroll", this.mousewheel);
    this.canvas.removeEventListener("mousewheel", this.mousewheel);
    // unbind mouse events
    window.removeEventListener("mouseup", this.mouseup, { passive: true });
    window.removeEventListener("mousemove", this.mousemove, { passive: true });
    this.keepRendering = false;
  };

  reset = () => {
    // resets renderer to initial state
    //TODO: adapt for Splitscreen, so that not all frames are reseted
    this.props.tiles.clearTiles();
    // frontend cached alpha channel images
    this.props.tiles.setVisibleImage([]);
    // frontend cached colored images
    this.props.tiles.setColoredImages([]);

    this.setMountedState({
      dragStart: null,
      playing: false,
      //initialized: false,
    });

    this.loadAllTiles();
    this.props.setChangingFile(false);
    //this.props.setAIObjects();
  };

  updatePreviewRect = (activeTool) => {
    if (this.props.tools[activeTool] && this.canvas) {
      let p2 = this.getPointInCanvas({
        x: this.canvas.offsetWidth / 2,
        y: this.canvas.offsetHeight / 2,
      });
      this.props.tools[activeTool].setPreviewRect(p2.x, p2.y); //set position
      if (typeof this.props.tools[activeTool].previewRect !== "undefined")
        this.updatePreviewRectSize({
          width: this.props.tools[activeTool].previewRect.w,
          height: this.props.tools[activeTool].previewRect.h,
        });
    }
  };

  updateObjectMode = (value) => {
    this.objectMode = value;
  };

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    const { structures, selectedLayer } = this.props;
    // set canvas context for pen tool
    if (
      structures[selectedLayer] &&
      structures[selectedLayer].isSubtype &&
      structures[selectedLayer].classificationSubtype
    ) {
      let parentIndex = getParentIndexLayer(
        structures[selectedLayer],
        structures
      );
      if (
        nextProps.tools[nextProps.activeTool] &&
        this.props.isActive &&
        nextProps.roiLayers[parentIndex]
      ) {
        nextProps.tools[nextProps.activeTool].setLayer({
          layer: nextProps.roiLayers[parentIndex].layer,
          ome: nextProps.ome,
          fileId: nextProps.fileId,
          projectId: nextProps.projectId,
          structures: nextProps.structures,
          roiLayers: nextProps.roiLayers,
          drawLayer: nextProps.drawLayer,
          selectedLayer: nextProps.selectedLayer,
          updateProject: nextProps.updateProject,
          commentLayer: nextProps.commentLayer,
          landmarkLayer: nextProps.landmarkLayer,
          landmarkLayers: nextProps.landmarkLayers,
          allRoiLayers: nextProps.allRoiLayers,
          viewerConfig_project: nextProps.viewerConfig.project,
          project: nextProps.project,
          ctx: this.ctx,
          histogramConfig: nextProps.histogramConfig,
          rendererDict: nextProps.rendererDict,
          updateObjectMode: this.updateObjectMode,
          splitscreenFileIds: nextProps.splitscreenFileIds,
        });
      }
    } else {
      let offset = 0;
      if (
        nextProps.structures[nextProps.selectedLayer - offset] &&
        nextProps.tools[nextProps.activeTool] &&
        nextProps.roiLayers &&
        this.props.isActive
      ) {
        nextProps.tools[nextProps.activeTool].setLayer({
          layer: nextProps.roiLayers.find(
            (x) =>
              x.id === nextProps.structures[nextProps.selectedLayer - offset].id
          ).layer,
          ome: nextProps.ome,
          fileId: nextProps.fileId,
          projectId: nextProps.projectId,
          structures: nextProps.structures,
          roiLayers: nextProps.roiLayers,
          drawLayer: nextProps.drawLayer,
          selectedLayer: nextProps.selectedLayer,
          updateProject: nextProps.updateProject,
          commentLayer: nextProps.commentLayer,
          landmarkLayer: nextProps.landmarkLayer,
          landmarkLayers: nextProps.landmarkLayers,
          allRoiLayers: nextProps.allRoiLayers,
          viewerConfig_project: nextProps.viewerConfig.project,
          project: nextProps.project,
          ctx: this.ctx,
          histogramConfig: nextProps.histogramConfig,
          rendererDict: nextProps.rendererDict,
          updateObjectMode: this.updateObjectMode,
          splitscreenFileIds: nextProps.splitscreenFileIds,
        });
      }
    }

    // update preview rect
    if (!nextProps.showGallery) {
      if (!this.state.previewRectChanged)
        this.setMountedState({ previewRectChanged: true });
    }
  };

  getPage = (c, z, t) => {
    // calculate page index from coordinates
    return (
      Math.round(t) * this.props.ome.channels.length * this.props.ome.sizeZ +
      c * this.props.ome.sizeZ +
      Math.round(z)
    );
  };

  getPageForChannel = (c) => {
    // calculate page index from coordinates
    return (
      Math.round(this.state.t) *
        this.props.ome.channels.length *
        this.props.ome.sizeZ +
      Math.round(this.state.z) * this.props.ome.channels.length +
      c
    );
  };

  getMousePositionInImage = () => {
    let x = window.getMousePosition()[0];
    let y = window.getMousePosition()[1];
    this.lastX = x - this.canvas.getBoundingClientRect().left;
    this.lastY = y - this.canvas.getBoundingClientRect().top; //- this.canvas.offsetTop

    // calculate mouse position in image coordinates
    let p1 = this.getPointInCanvas({
      x: this.lastX,
      y: this.lastY,
    });
    let p2 = this.getPointInCanvas({ x: 0, y: 0 });
    let p3 = this.getPosition();

    p1.x += p2.x - p3.x / this.getScale();
    p1.y += p2.y - p3.y / this.getScale();

    return p1;
  };

  getPositionInImage = (position) => {
    // calculate corner points top left (position==0), bottom right (position==1), center (position==2)
    let X;
    let Y;
    if (position === 0) {
      // top left
      X = this.canvas.getBoundingClientRect().left;
      Y = this.canvas.getBoundingClientRect().top; //64 - this.canvas.offsetTop + 32; // 32 is half of blue header
    } else if (position === 1) {
      // bottom right
      X = this.canvas.getBoundingClientRect().right;
      Y = this.canvas.getBoundingClientRect().bottom;
      //- this.canvas.offsetTop + 32; // 32 is half of blue header
    } else {
      // center
      X =
        (this.canvas.getBoundingClientRect().left +
          this.canvas.getBoundingClientRect().right) /
        2;
      Y =
        (this.canvas.getBoundingClientRect().top +
          this.canvas.getBoundingClientRect().bottom) /
        2;
      //+32; // 32 is half of blue header
    }

    // calculate mouse position in image coordinates
    let p1 = this.getPointInCanvas({
      x: X - this.canvas.getBoundingClientRect().left,
      y: Y - this.canvas.getBoundingClientRect().top,
    });
    let p2 = this.getPointInCanvas({ x: 0, y: 0 });
    let p3 = this.getPosition();
    p1.x += p2.x - p3.x / this.getScale();
    p1.y += p2.y - p3.y / this.getScale();

    return p1;
  };

  toolMouseForward = (event) => {
    const parentId = this.props.structures[this.props.selectedLayer].parentId;

    let parentIdx = this.props.structures.findIndex(
      (item) => item.id === parentId
    );
    parentIdx = this.props.selectedLayer > 0 ? Math.max(0, parentIdx) : -1;
    let p1 = this.mousePos;
    p1.x = p1.x < 0 ? 0 : p1.x;
    p1.x = p1.x > this.vw ? this.vw : p1.x;
    p1.y = p1.y < 0 ? 0 : p1.y;
    p1.y = p1.y > this.vh ? this.vh : p1.y;
    const isSubtype =
      this.props.structures[this.props.selectedLayer].isSubtype &&
      this.props.structures[this.props.selectedLayer].classificationSubtype;

    let selection = this.props.tools[this.props.activeTool].mouse({
      event: event,
      p: p1,
      color: this.props.structures[this.props.selectedLayer].color,
      subtype: isSubtype,
      name: isSubtype
        ? this.props.structures[this.props.selectedLayer].label
        : "",
      positionInRoiLayer: false,
      fullyLoaded: false,
      parentIdx: parentIdx,
      selectedId: this.props.structures[this.props.selectedLayer].id,
      vw: this.vw,
      vh: this.vh,
      scale: this.getScale(),
      renderer: this,
    });
    return selection;
  };

  getPointInCanvasCoord = (x, y) => {
    let pt = {
      x: Math.round(x * this.ctx.getTransform().a + this.ctx.getTransform().e),
      y: Math.round(y * this.ctx.getTransform().d + this.ctx.getTransform().f),
    };
    return pt;
  };

  getPointInCanvas = (p) => {
    try {
      let tp = this.ctx.transformedPoint(p.x, p.y);
      return {
        x: tp.x,
        y: tp.y,
      };
    } catch (ex) {
      // Skip this frame
      // console.error(ex);
      return {
        x: -1,
        y: -1,
      };
    }
  };

  mousedown = (event) => {
    let p1 = this.getMousePositionInImage();
    this.mousePos = p1;
    this.props.tiles.chainLeaderId = this.props.canvasId;

    if (this.props.tools[this.props.activeTool] && !event.ctrlKey) {
      // only drawing on image is allowed
      if (p1.x >= 0 && p1.x <= this.vw && p1.y >= 0 && p1.y <= this.vh) {
        let selection = this.toolMouseForward(event);
        // if tool is selection then save selected roi
        if (
          this.props.tools[this.props.activeTool].name === "Selection" &&
          selection
        ) {
          this.selROI = selection;
          this.props.setSelectedRoi(selection);
          if (selection.classify) {
            this.classifySelectedRoi(selection, true);
          }
        } else if (
          this.props.activeTool === "iam_region_growing" &&
          this.state.colorPickerActive &&
          event.button !== 1
        ) {
          const x = event.clientX - this.canvas.getBoundingClientRect().left;
          const y = event.clientY - this.canvas.getBoundingClientRect().top;
          //draw image to get pixelcolor w/o annotations
          this.drawVisibleImage();
          const pickedColor = this.ctx.getImageData(x, y, 1, 1).data;
          const hexColor = rgbToHex(
            pickedColor[0],
            pickedColor[1],
            pickedColor[2]
          );
          const colors_foreground =
            this.props.tools[this.props.activeTool].configFormRef.state
              .colors_foreground;
          const colors_background =
            this.props.tools[this.props.activeTool].configFormRef.state
              .colors_background;
          const colorListName =
            event.button === 0 ? "colors_foreground" : "colors_background";
          const colors =
            this.props.tools[this.props.activeTool].configFormRef.state[
              colorListName
            ];

          if (
            !colors_foreground.includes(hexColor) &&
            !colors_background.includes(hexColor)
          ) {
            colors.push(hexColor);
            this.props.tools[
              this.props.activeTool
            ].configFormRef.onParameterChange(colorListName, colors);
            this.props.tools[
              this.props.activeTool
            ].configFormRef.onParameterChange("lastColor", hexColor);
            this.props.tools[this.props.activeTool].configFormRef.onUseIAM();
          } else {
            window.showWarningSnackbar("Color already in use");
          }
        }
      }
    } else if (event.button === 0 && !event.ctrlKey) {
      if (this.props.activeTool === Tools.RECT_ROI) {
        // only drawing on image is allowed
        if (p1.x >= 0 && p1.x <= this.vw && p1.y >= 0 && p1.y <= this.vh) {
          // create new rectangle in drawing mode
          let newROI = new RectROI(this.ctx, p1.x, p1.y, 0, 0, true);
          // select new rectangle for resizing
          this.resizeROI = newROI;

          // update component state
          this.props.onChangeROIs([...this.props.rois, newROI]);
        }
      } else {
        // corner radius of our rectangles
        for (let roi of this.props.rois) {
          switch (roi.handleMouseDown(p1)) {
            case 1: // dragging
              this.dragROI = roi;
              break;
            case 2: // resizing
              this.resizeROI = roi;
              this.dragROI = null;
              return;
            default:
              // no collision
              break;
          }
        }
      }
    }

    // start dragging if middle mouse button is down or not tool selected
    if (
      event.button === 1 ||
      (event.button === 0 && event.ctrlKey) ||
      (!this.dragROI &&
        !this.resizeROI &&
        ![
          Tools.MAGICWAND_ROI,
          Tools.REGIONGROWING_ROI,
          Tools.REGIONGRABCUT_ROI,
          Tools.PEN_ROI,
          Tools.RectROI,
          Tools.REGION_ROI,
          Tools.RECTANGLE_ROI,
          Tools.COMMENT_ROI,
          Tools.ELLIPSE_ROI,
          Tools.COPY_ROI,
          Tools.FILL,
          Tools.SELECTION_ROI,
          Tools.GRIDANNOTATIONTOOL,
          Tools.LANDMARK,
        ].includes(
          //deactivate dragging for this tools
          this.props.activeTool
        ))
    ) {
      let dragStart = this.getPointInCanvas({ x: this.lastX, y: this.lastY });
      this.setMountedState({
        dragStart: dragStart,
      });
      if (this.props.isChained) {
        if (this.props.showFullscreen) {
          for (const value of Object.values(this.props.splitscreenFileIds)) {
            let fDragStart = this.props.rendererDict[
              "Full" + value
            ].getPointInCanvas({
              x: this.lastX,
              y: this.lastY,
            });
            this.props.rendererDict["Full" + value].setState({
              dragStart: fDragStart,
            });
          }
        } else {
          for (const value of Object.values(this.props.chainListFileIds)) {
            let fDragStart = this.props.rendererDict[value].getPointInCanvas({
              x: this.lastX,
              y: this.lastY,
            });
            this.props.rendererDict[value].setState({
              dragStart: fDragStart,
            });
          }
        }
      }

      this.canvas.style.cursor = "move";

      if ((event.button === 0 || event.button === 1) && event.altKey) {
        this.tempChainToggle = true;

        if (this.props.showFullscreen) {
          this.props.fsChain();
        } else {
          this.props.onChangeChain(
            false,
            this.props.splitscreenIdx,
            this.props.fileId
          );
        }
      }

      // dont forward any event upwards
      event.preventDefault();
      return false;
    } else {
      if (!this.state.isDrawing) {
        this.setMountedState({ isDrawing: true });
      }
    }
  };

  setHeatmapData = (data, maxValue, show = true) => {
    this.showHeatmap = show;
    let heatmapData = {
      max: maxValue,
      data: data,
    };
    this.heatmap.setData(heatmapData);
  };

  mouseBusy = () => {
    return !this.canvas.style.cursor !== "default";
  };

  changeCursorStyle = (p1) => {
    // handle roi drawing mode
    if (p1.x >= 0 && p1.x <= this.vw && p1.y >= 0 && p1.y <= this.vh) {
      // only show crosshair if cursor on the image
      this.canvas.style.cursor = "crosshair";
    } else {
      this.canvas.style.cursor = "default";
    }
  };

  mousemove = (event) => {
    if (this.props.resultTab.getZoomLevelFixed()) {
      return;
    }
    let p1 = this.getMousePositionInImage();
    if (this.props.activeTool === "iam_region_growing") {
      if (this.state.colorPickerType) {
        this.changeCursorStyle(p1);
      } else {
        this.canvas.style.cursor = "default";
      }
    }
    if (this.state.isDrawing || this.state.mouseOnCanvas) {
      this.mousePos = p1;
    } else {
      this.middleMousePos();
    }
    if (this.dragROI) {
      // handle roi dragging
      this.dragROI.drag(p1, this.vw, this.vh);
    } else if (this.resizeROI) {
      // resize the roi if inside the image
      this.resizeROI.resize(p1, this.vw, this.vh);
    } else if (
      this.state.dragStart &&
      this.props.tiles.chainLeaderId === this.props.canvasId
    ) {
      // handle canvas dragging
      let pt = this.getPointInCanvas({ x: this.lastX, y: this.lastY });
      // translate canvas

      this.ctx.translate(
        pt.x - this.state.dragStart.x,
        pt.y - this.state.dragStart.y
      );
      if (this.props.isChained) {
        if (this.props.showFullscreen) {
          for (const value of Object.values(this.props.splitscreenFileIds)) {
            let fPt = this.props.rendererDict["Full" + value].getPointInCanvas({
              x: this.lastX,
              y: this.lastY,
            });
            this.props.rendererDict["Full" + value].chainMouseDrag(
              fPt.x,
              fPt.y
            );
          }
        } else {
          for (const value of Object.values(this.props.chainListFileIds)) {
            let fPt = this.props.rendererDict[value].getPointInCanvas({
              x: this.lastX,
              y: this.lastY,
            });
            this.props.rendererDict[value].chainMouseDrag(fPt.x, fPt.y);
          }
        }
      }
      this.zoomMoveActionDebounced();
    } else if (
      this.props.tools[this.props.activeTool] &&
      this.props.activeTool !== Tools.LANDMARK
    ) {
      // only drawing on image is allowed
      this.toolMouseForward(event);
    } else if (this.props.activeTool === Tools.LANDMARK) {
      this.changeCursorStyle(p1);
      this.toolMouseForward(event);
    } else if (this.props.activeTool === Tools.RECT_ROI) {
      this.changeCursorStyle(p1);
    } else {
      // handle hover events etc.
      this.canvas.style.cursor = "default";
      for (let roi of this.props.rois) {
        roi.handleMouseMove(p1);
      }
    }
    this.lastMoveTime = performance.now();

    // redraw screen
    //this.forceUpdate();
  };

  chainMouseDrag = (x, y) => {
    if (this.props.tiles.chainLeaderId !== this.props.canvasId) {
      if (this.props.isChained) {
        this.ctx.translate(
          x - this.state.dragStart.x,
          y - this.state.dragStart.y
        );
      }
    }
  };

  mouseEnter = () => {
    this.setMountedState({ mouseOnCanvas: true });
  };

  mouseLeave = () => {
    this.setMountedState({ mouseOnCanvas: false });
  };

  middleMousePos = () => {
    let p1 = this.getPointInCanvas({ x: 0, y: 0 });
    let p2 = this.getPointInCanvas({
      x: this.canvas.width,
      y: this.canvas.height,
    });
    let centerX = p1.x + (p2.x - p1.x) / 2;
    let centerY = p1.y + (p2.y - p1.y) / 2;
    this.mousePos = { x: centerX, y: centerY };
  };

  mouseup = (event) => {
    let p1 = this.getMousePositionInImage();
    this.mousePos = p1;

    if (
      this.props.tools[this.props.activeTool] &&
      (this.state.isDrawing || this.props.activeTool === "gridannotationtool")
    ) {
      this.toolMouseForward(event);
      this.updateFrameArray();
    }

    // stop dragging the canvas
    if (this.state.dragStart) {
      this.setMountedState({ dragStart: null });
      this.canvas.style.cursor = "default";
    }

    // stop resizing a roi
    if (this.resizeROI) {
      this.resizeROI.handleMouseUp();
      this.resizeROI = null;
    }

    // stop dragging a roi
    if (this.dragROI) {
      this.dragROI.handleMouseUp();
      this.dragROI = null;
    }

    if (this.state.isDrawing) {
      this.setMountedState({ isDrawing: false });
    }

    if (this.tempChainToggle) {
      this.tempChainToggle = false;
      if (this.props.showFullscreen) {
        this.props.fsChain();
      } else {
        this.props.onChangeChain(
          true,
          this.props.splitscreenIdx,
          this.props.fileId
        );
      }
    }

    if (this.state.showResultTable) {
      window.updateResultTable();
    }

    // update view
    this.forceUpdate();
  };

  onScaleOnly = (event) => {
    let delta = 0;

    if (event.wheelDelta) {
      /* IE/Opera. */
      delta = -(event.wheelDelta / 120);
    } else if (event.detail) {
      /* Mozilla */
      delta = event.detail / 3;
    }

    if (delta) {
      // zoom in (1) or out (-1)
      this.zoomDirection = delta < 0 ? 1 : -1;

      // set destination scale value
      let newZoom = this.getScale() * Math.pow(2, this.zoomDirection);

      // max zoom out value
      this.zoomValueOut = (0.75 * this.canvas.height) / this.vh;

      if ((0.75 * this.canvas.width) / this.vw < this.zoomValueOut) {
        this.zoomValueOut = (0.75 * this.canvas.width) / this.vw;
      }

      if (this.props.isActive === false && this.props.isChained) {
        this.zoomValueOut = this.props.showFullscreen
          ? this.props.rendererDict["Full" + this.props.activeFileId]
              .zoomValueOut
          : this.props.rendererDict[this.props.activeFileId].zoomValueOut;
      }

      if (newZoom < this.zoomValueOut) {
        newZoom = this.zoomValueOut;
      }

      // Limit maximum zoom in value
      const maxZoomIn = 32;
      newZoom = newZoom > maxZoomIn ? maxZoomIn : newZoom;

      this.zoomTo = newZoom;
      // redraw ui
      this.forceUpdate();
    }

    // prevent other scroll actions
    if (event.preventDefault) {
      event.preventDefault();
    }
    event.returnValue = false;
  };

  mousewheel = (event) => {
    // save zoom destination point
    this.props.tiles.chainLeaderId = this.props.canvasId;
    if (
      this.state.initialized &&
      !event.ctrlKey &&
      !this.props.resultTab.getZoomLevelFixed()
    ) {
      this.zoomPoint = {
        x: this.lastX,
        y: this.lastY, // - this.canvas.getBoundingClientRect().top,
      };
      this.onScaleOnly(event);
    }

    if (this.props.isChained) {
      if (this.props.showFullscreen) {
        for (const value of Object.values(this.props.splitscreenFileIds)) {
          this.props.rendererDict["Full" + value].chainMousewheel(
            event,
            this.zoomPoint
          );
        }
      } else {
        for (const value of Object.values(this.props.chainListFileIds)) {
          this.props.rendererDict[value].chainMousewheel(event, this.zoomPoint);
        }
      }
    }
    event.preventDefault();
  };

  chainMousewheel = (event, leaderZoomPoint) => {
    // save zoom destination point
    if (this.props.tiles.chainLeaderId !== this.props.canvasId) {
      if (this.props.isChained) {
        if (
          this.state.initialized &&
          !event.ctrlKey &&
          !event.shiftKey &&
          !this.props.resultTab.getZoomLevelFixed()
        ) {
          this.zoomPoint = leaderZoomPoint;
          this.onScaleOnly(event);
        }
      }
      event.preventDefault();
    } else return;
  };

  miniMapZoom = (event, x, y) => {
    this.props.tiles.chainLeaderId = this.props.canvasId;
    if (
      this.state.initialized &&
      !event.ctrlKey &&
      !this.props.resultTab.getZoomLevelFixed()
    ) {
      let ctx = this.miniMapRef.getCtx();
      let tpt = ctx.transformedPoint(x, y);
      let pt = {
        x: tpt.x * this.ctx.getTransform().a + this.ctx.getTransform().e,
        y: tpt.y * this.ctx.getTransform().d + this.ctx.getTransform().f,
      };
      pt.x = Math.round(pt.x);
      pt.y = Math.round(pt.y);
      if (
        pt.x < this.canvas.width &&
        pt.x > 0 &&
        pt.y < this.canvas.height &&
        pt.y > 0
      ) {
        this.zoomPoint = pt;
      } else {
        return;
      }
      this.onScaleOnly(event);
    }
    if (this.props.isChained) {
      let l = this.props.showFullscreen
        ? this.props.splitscreenFileIds
        : this.props.chainListFileIds;
      for (const value of Object.values(l)) {
        if (value !== this.props.fileId) {
          let r = this.props.showFullscreen
            ? this.props.rendererDict["Full" + value]
            : this.props.rendererDict[value];
          let c = r.miniMapRef.getCtx();
          let p = c.transformedPoint(x, y);
          let pt = {
            x: p.x * r.ctx.getTransform().a + r.ctx.getTransform().e,
            y: p.y * r.ctx.getTransform().d + r.ctx.getTransform().f,
          };
          pt.x = Math.round(pt.x);
          pt.y = Math.round(pt.y);
          if (
            pt.x < r.canvas.width &&
            pt.x > 0 &&
            pt.y < r.canvas.height &&
            pt.y > 0
          ) {
            r.zoomPoint = pt;
          } else {
            return;
          }
          r.chainMousewheel(event, pt);
        }
      }
    }
    event.preventDefault();
  };

  // returns the current zoom scale
  getScale = () => {
    return this.ctx ? this.ctx.getTransform().a : 1;
  };

  // returns position of our image
  getPosition = () => {
    return {
      x: -this.ctx.getTransform().e,
      y: -this.ctx.getTransform().f,
    };
  };

  // zoom out to show the whole image
  zoomOut = () => {
    // center image first
    let pt = this.getPointInCanvas({
      x: (this.canvas.width - this.vw) / 2,
      y: (this.canvas.height - this.vh) / 2,
    });
    this.ctx.translate(pt.x, pt.y);
    // calc our transformation offset
    pt = this.getPointInCanvas({
      x: this.canvas.width / 2,
      y: this.canvas.height / 2,
    });
    // move to origin
    this.ctx.translate(pt.x, pt.y);
    // calc scale factor so we can fit the whole image on our screen
    let factor = Math.min(
      this.canvas.height / this.vh,
      this.canvas.width / this.vw
    );
    // perform scaling
    this.ctx.scale(factor, factor);
    // restore our offset
    this.ctx.translate(-pt.x, -pt.y);
    // save initial scale into our state
    this.setMountedState({
      initialized: true,
      initialScale: factor,
    });
    this.props.resultTab.setRendererInitialized(true);
  };

  moveTo = (e) => {
    // get curent position (center of screen) and transform into world coordinates
    let pt = this.getPointInCanvas({
      x: this.canvas.width / 2,
      y: this.canvas.height / 2,
    });
    // translate by the offset to the destination point
    this.ctx.translate(-(e.x - pt.x), -(e.y - pt.y));
    // redraw screen
    this.forceUpdate();
    this.zoomMoveAction();
  };

  chainMoveTo = (x, y) => {
    // get curent position (center of screen) and transform into world coordinates
    let pt = this.getPointInCanvas({
      x: this.canvas.width / 2,
      y: this.canvas.height / 2,
    });
    // translate by the offset to the destination point
    this.ctx.translate(-(x - pt.x), -(y - pt.y));
    // redraw screen
    this.forceUpdate();
    this.zoomMoveAction();
  };

  chainMouseMove = (x, y, e) => {
    if (this.props.isChained) {
      if (this.props.showFullscreen) {
        for (const value of Object.values(this.props.splitscreenFileIds)) {
          if (value !== this.props.fileId) {
            let ctx =
              this.props.rendererDict["Full" + value].miniMapRef.getCtx();
            let pt = ctx.transformedPoint(x, y);
            this.props.rendererDict["Full" + value].chainMoveTo(pt.x, pt.y, e);
          }
        }
      } else {
        for (const value of Object.values(this.props.chainListFileIds)) {
          if (value !== this.props.fileId) {
            let ctx = this.props.rendererDict[value].miniMapRef.getCtx();
            let pt = ctx.transformedPoint(x, y);
            this.props.rendererDict[value].chainMoveTo(pt.x, pt.y, e);
          }
        }
      }
    }
  };

  hoverROI = (roi, hovered) => {
    // update hovered parameter of roi
    roi.hovered = hovered;
    // redraw screen
    this.forceUpdate();
  };

  centerROI = (roi) => {
    // center roi
    this.moveTo({ x: roi.x - roi.w / 2, y: roi.y - roi.h / 2 });
  };

  transformPoint = (x, y, m, tfact) => {
    m[2] *= tfact;
    m[5] *= tfact;
    let a = m[0] * x + m[1] * y + m[2];
    let b = m[3] * x + m[4] * y + m[5];
    return [a, b];
  };

  transformLandmark = (h, tfact) => {
    for (let roi of this.props.landmarkLayer.landmarkRois) {
      let p = roi.regions;
      roi.regions = this.transformPoint(p[0], p[1], h, tfact);
    }
  };

  transformLandmarkNoOffset = (h, tfact) => {
    let m = [];
    for (let roi of this.props.landmarkLayer.landmarkRois) {
      let p = roi.regions;
      m.push(this.transformPoint(p[0], p[1], h, tfact));
    }
    return m;
  };

  transformAnnotation = (h, tfact) => {
    for (let struct of this.props.roiLayers) {
      let newRegionList = [];
      if (struct.layer.regionRois.length > 0) {
        for (let roi of struct.layer.regionRois) {
          let newRegion = [];
          for (let region of roi.regions[0]) {
            let point = this.transformPoint(region[0], region[1], h, tfact);
            newRegion.push(point);
          }
          let temp = roi;
          temp.regions = newRegion;
          let newRoi = new RegionROI(temp);
          newRegionList.push(newRoi);
        }
        struct.tree.clear();
        struct.layer.regionRois = newRegionList;
        struct.tree.load(newRegionList.map((item) => item.treeItem));
      }
    }
  };

  generateTransformationOffset = (marks) => {
    let origCenter = { x: this.vw / 2, y: this.vh / 2 };

    let xValues = [];
    let yValues = [];
    for (let i = 0; i < marks.length; i++) {
      xValues.push(marks[i][0]);
      yValues.push(marks[i][1]);
    }
    let xMin = Math.min(...xValues);
    let xMax = Math.max(...xValues);
    let yMin = Math.min(...yValues);
    let yMax = Math.max(...yValues);

    let bbCenter = { x: xMin + (xMax - xMin) / 2, y: yMin + (yMax - yMin) / 2 };
    let offset = { x: origCenter.x - bbCenter.x, y: origCenter.y - bbCenter.y };
    return offset;
    // return { x: 0, y: 0 };
  };

  generateLandmarkTransformation = (
    pointBase,
    pointShift,
    baseId,
    targetId,
    baseOme
  ) => {
    calculateTransformationMatrix(
      pointBase,
      pointShift,
      this.props.ome,
      baseId,
      targetId,
      baseOme,
      (h, tfact) => {
        let marks = this.transformLandmarkNoOffset(h, 1);
        let tOffset = this.generateTransformationOffset(marks);
        h[2] += tOffset.x;
        h[5] += tOffset.y;
        let offset = [];
        offset.push(tOffset.x);
        offset.push(tOffset.y);
        this.transformLandmark(h, 1);
        this.transformAnnotation(h, 1);
        this.props.tiles.setTransformationMatnFactnOff(
          h,
          this.props.fileId,
          tfact,
          offset
        );
        this.reset();
      }
    );
  };

  pushRegTile = (tileId, colImg, channel) => {
    let mat = this.props.tiles.getTransformationMatrix(this.props.fileId);
    let fact = this.props.tiles.getTransformationFactor(this.props.fileId);
    if (Array.isArray(mat)) {
      if (mat.length > 0) {
        this.imgRegistered = false;
        this.callbackCounter += 1;
        if (this.state.isLoadingTiles === false) {
          this.setMountedState({ isLoadingTiles: true });
        }
        var t0 = performance.now();
        tileRegistration(colImg, tileId, mat, fact, channel, (Img) => {
          this.props.tiles.pushColoredImages(Img, tileId);
          var t1 = performance.now();
          console.log("Tile registration took ", t1 - t0, " ms.");
          if (mat.length === 0) {
            console.log("empty mat");
          }
          this.callbackCounter -= 1;
          if (this.callbackCounter === 0) {
            this.imgRegistered = true;
            this.setMountedState({ isLoadingTiles: false });
          }
        });
      }
    }
  };

  pushColoredImage = (c, tileId, visImg) => {
    let channel = this.props.histogramConfig.channels[c];
    if (
      (this.props.histogramConfig.channels.length < 2 &&
        channel.color === "#ffffff") ||
      channel.color === -1
    ) {
      // rgb channel
      if (!this.props.tiles.getColoredImage(tileId)) {
        let colImg = configureImage(visImg, channel);
        this.props.tiles.pushColoredImages(colImg, tileId);
        this.pushRegTile(tileId, visImg, channel);
      }
    } else {
      // colored images cache
      if (!this.props.tiles.getColoredImage(tileId) && visImg.width > 0) {
        let colImg = colorInImage(visImg, channel);
        this.props.tiles.pushColoredImages(colImg, tileId);
        this.pushRegTile(tileId, visImg, channel);
      }
    }
  };

  loadAllTiles = () => {
    for (let lv = 0; lv < Math.min(2, this.props.ome.maxLevel + 1); lv++) {
      // calculate the number of grid columns for this level
      let cols = Math.pow(2, lv);
      for (let x = 0; x < cols; x++) {
        for (let y = 0; y < cols; y++) {
          for (let c = 0; c < this.props.ome.channels.length; c++) {
            let page = this.getPageForChannel(c);
            // load image first if missing
            let tileId =
              page + "," + lv + "," + x + "," + y + "," + this.props.fileId;
            if (!this.props.tiles.getVisibleImage(tileId)) {
              let visImg = new Image();
              visImg.src = Backend.renderRegion({
                id: this.props.fileId,
                page: page,
                lv: lv,
                x: x,
                y: y,
              });
              visImg.onload = () => {
                this.pushColoredImage(c, tileId, visImg);
                this.props.tiles.pushVisibleImage(visImg, tileId);
                // set context width and height
                this.props.tiles.setImgWidth(visImg.width);
                this.props.tiles.setImgHeight(visImg.height);
              };
            } else if (this.props.tiles.getVisibleImage(tileId).complete) {
              // set context width and height
              let img = this.props.tiles.getVisibleImage(tileId);
              this.props.tiles.setImgWidth(img.width);
              this.props.tiles.setImgHeight(img.height);
              this.pushColoredImage(
                c,
                tileId,
                this.props.tiles.getVisibleImage(tileId)
              );
            }
          }
        }
      }
    }

    this.preloadNextFrames();

    this.props.changeHiConfig(true);
    this.props.tiles.setHistogramConfig(this.props.histogramConfig);
  };

  preloadNextFrames = () => {
    if (this.state.playDirection > 0) {
      for (
        let t = this.state.t;
        t <
        Math.min(
          this.props.ome.sizeT - 1,
          this.state.t + this.state.bufferSize
        );
        t++
      ) {
        this.preloadFrame(this.state.z, t);
      }
    } else {
      for (
        let t = this.state.t;
        t >= Math.max(0, this.state.t - this.state.bufferSize);
        t--
      ) {
        this.preloadFrame(this.state.z, t);
      }
    }
  };

  preloadNextZFrames = () => {
    if (this.state.playDirectionZ > 0) {
      for (
        let z = this.state.z;
        z <
        Math.min(
          this.props.ome.sizeZ - 1,
          this.state.z + this.state.bufferSize
        );
        z++
      ) {
        this.preloadFrame(z, this.state.t);
      }
    } else {
      for (
        let z = this.state.z;
        z >= Math.max(0, this.state.z - this.state.bufferSize);
        z--
      ) {
        this.preloadFrame(z, this.state.t);
      }
    }
  };

  preloadFrame = (z, t) => {
    for (let lv = 0; lv < 1; lv++) {
      // calculate the number of grid columns for this level
      let cols = Math.pow(2, lv);
      for (let x = 0; x < cols; x++) {
        for (let y = 0; y < cols; y++) {
          for (let c = 0; c < this.props.ome.channels.length; c++) {
            let page = this.getPage(c, z, t);
            // load image first if missing
            let tileId =
              page + "," + lv + "," + x + "," + y + "," + this.props.fileId;
            if (!this.props.tiles.getVisibleImage(tileId)) {
              let visImg = new Image();
              visImg.src = Backend.renderRegion({
                id: this.props.fileId,
                page: page,
                lv: lv,
                x: x,
                y: y,
              });
              visImg.onload = () => {
                this.pushColoredImage(c, tileId, visImg);
              };
            } else if (this.props.tiles.getVisibleImage(tileId).complete) {
              this.pushColoredImage(
                c,
                tileId,
                this.props.tiles.getVisibleImage(tileId)
              );
            }
          }
        }
      }
    }
  };

  zoomToFactor = (factor) => {
    let scaleTarget = this.state.initialScale * factor;
    this.zoomTo = scaleTarget;
    this.zoomPoint = { x: this.canvas.width / 2, y: this.canvas.height / 2 };
    this.zoomDirection =
      this.getScale() < scaleTarget ? scaleTarget : -scaleTarget;

    setTimeout(() => this.forceUpdate(), 10);
  };

  zoomOriginal = () => {
    // no zooming enabled
    if (
      this.props.resultTab.getZoomLevelFixed() &&
      this.props.resultTabActive()
    ) {
      return;
    }

    this.zoomOneToN(1);
  };

  zoomOneToN = (x) => {
    // no zooming enabled
    if (
      this.props.resultTab.getZoomLevelFixed() &&
      this.props.resultTabActive()
    ) {
      return;
    }

    this.zoomTo = x;
    this.zoomPoint = { x: this.canvas.width / 2, y: this.canvas.height / 2 };
    this.zoomDirection = this.getScale() < x ? 1 : -1;

    setTimeout(() => this.forceUpdate(), 10);
  };

  zoomDelta = (delta) => {
    // no zooming enabled
    if (
      this.props.resultTab.getZoomLevelFixed() &&
      this.props.resultTabActive()
    ) {
      return;
    }

    // zoom middle
    this.zoomPoint = { x: this.canvas.width / 2, y: this.canvas.height / 2 };
    // zoom in (1) or out (-1)
    this.zoomDirection = delta < 0 ? 1 : -1;

    // set destination scale value
    let newZoom = this.getScale() * Math.pow(1.2, this.zoomDirection);

    // max zoom out value
    if (newZoom < (0.75 * this.canvas.height) / this.vh) {
      newZoom = (0.75 * this.canvas.height) / this.vh;
    }

    // max zoom in value
    if (newZoom > (2 * this.vh) / this.canvas.height) {
      newZoom = (2 * this.vh) / this.canvas.height;
    }

    this.zoomTo = newZoom;

    // redraw ui
    this.forceUpdate();
  };

  zoomFit = () => {
    // no zooming enabled
    if (
      this.props.resultTab.getZoomLevelFixed() &&
      this.props.resultTabActive()
    ) {
      return;
    }

    this.canvasTranform(1, 0, 0, 1, 0, 0);
    this.zoomOut();
    this.zoomMoveActionDebounced(); //to save zoomObject
  };

  /**
   * Performs a context.setTransform, whilst ensuring that the pixel
   * representation is according to zoomlevel
   * @param {number} a Horizontal scaling. A value of 1 results in no scaling.
   * @param {number} b Vertical skewing.
   * @param {number} c Horizontal skewing.
   * @param {number} d Vertical scaling. A value of 1 results in no scaling.
   * @param {number} e Horizontal translation (moving).
   * @param {number} f Vertical translation (moving).
   */
  canvasTranform = (a, b, c, d, e, f) => {
    // disable smoothing to see pixels of image
    if (this.getScale() > 5) {
      this.ctx.imageSmoothingEnabled = false;
    } else {
      this.ctx.imageSmoothingEnabled = true;
    }
    // console.debug(this.getScale());
    // Actual transformation
    this.ctx.setTransform(a, b, c, d, e, f);
  };

  /**
   * Zoom the canvas to the specified recangle.
   * @param {object} rect The rectangle to zoom to. Contains left, right, top, bottom as pixel values.
   */
  centerRoi = (rect) => {
    const { roiLayers, selectedLayer, project } = this.props;
    // make roi/rect in center of screen
    if (this.state.initialized) {
      // if change file in histo classification or point counting module
      if (!rect) {
        let selRoi = this.props.resultTab.getSelectedRoi();
        rect = roiLayers[selectedLayer].layer.regionRois[selRoi].bounds;
        let toolName = "none";
        if (project.type.includes("HistoClassification")) {
          toolName = "selectiontile";
        } else if (project.type.includes("HistoPointCounting")) {
          toolName = "pointcountingtile";
        }
        this.props.onChangeTool(toolName);

        let roiItem = {
          maxX: roiLayers[selectedLayer].layer.regionRois[selRoi].bounds.left,
          maxY: roiLayers[selectedLayer].layer.regionRois[selRoi].bounds.top,
          minX: roiLayers[selectedLayer].layer.regionRois[selRoi].bounds.right,
          minY: roiLayers[selectedLayer].layer.regionRois[selRoi].bounds.bottom,
          roi: roiLayers[selectedLayer].layer.regionRois[selRoi],
        };

        this.props.tools[toolName].setNextTile(roiItem);

        // zoom to corresponding zoom level
        if (
          project.type.includes("HistoClassification") ||
          project.type.includes("HistoPointCounting")
        ) {
          let gridSize = Math.sqrt(
            roiLayers[selectedLayer].layer.regionRois.length
          );
          this.setZoomLevelForGridSize(gridSize);
        }
      }

      let xKoord = (rect.left + rect.right) / 2; // x- and y-coordinate for zoomPoint
      let yKoord = (rect.top + rect.bottom) / 2;
      let pt1 = { x: xKoord, y: yKoord };

      // make roi in center of screen:
      let pt2 = pt1;
      this.ctx.translate(
        this.getPositionInImage(2).x - pt2.x,
        this.getPositionInImage(2).y - pt2.y
      );
    } else {
      setTimeout(() => this.centerRoi(rect), 400);
    }
    this.zoomMoveActionDebounced();
  };

  zoomInRoi = () => {
    // make roi in center of screen
    if (this.props.zoomROI && this.state.initialized) {
      let xKoord = (this.props.zoomLeft + this.props.zoomRight) / 2; // x- and y-coordinate for zoomPoint
      let yKoord = (this.props.zoomTop + this.props.zoomBottom) / 2;
      let pt1 = {
        x: xKoord,
        y: yKoord,
      };

      // make roi in center of screen:
      let pt2 = pt1;
      this.ctx.translate(
        this.getPositionInImage(2).x - pt2.x,
        this.getPositionInImage(2).y - pt2.y
      );

      // zoom to roi:
      while (
        this.getPositionInImage(0).x < this.props.zoomLeft &&
        this.getPositionInImage(1).x > this.props.zoomRight &&
        this.getPositionInImage(0).y < this.props.zoomTop &&
        this.getPositionInImage(1).y > this.props.zoomBottom
      ) {
        // as long as roi is completely inside
        this.ctx.translate(pt1.x, pt1.y);
        let factor = Math.pow(20, 40 / 1000);
        this.ctx.scale(factor, factor);
        this.ctx.translate(-pt1.x, -pt1.y);
      }

      //this.props.zoomROI1(1);
      this.props.tzoomROI1(this.props.fileId);
      this.zoomMoveActionDebounced();
    }
  };

  //set structureIds if not set in first roi of
  setStructureIds = () => {
    for (let i = 1; i < this.props.roiLayers.length; i++) {
      let regionRois = this.props.roiLayers[i].layer.regionRois;
      let structure = this.props.structures[i];
      if (regionRois.length > 0 && regionRois[0].structureId === 0) {
        this.props.roiLayers[i].layer.regionRois = regionRois.map((roi) => {
          roi.structureId = structure.id;
          return roi;
        });
      }
    }
  };

  setSubtypeRois = () => {
    // check if rois are in subtype roilayer (e.g. opening project or run iam) --> put in parent roilayer
    // get all childstructures
    let childStructures = [];
    for (let i = 0; i < this.props.structures.length; i++) {
      if (
        this.props.structures[i].isSubtype &&
        this.props.structures[i].classificationSubtype
      ) {
        childStructures.push(i);
      }
    }

    // make childrois in parentlayer
    for (let i = 0; i < this.props.structures.length; i++) {
      if (
        this.props.roiLayers &&
        this.findRoiLayer(this.props.roiLayers, this.props.structures[i]).layer
          .regionRois.length !== 0 &&
        childStructures.includes(i)
      ) {
        let layer = [];
        let strs = this.props.structures;

        // get parentlayer rois
        let parentIndex = getParentIndexLayer(
          this.props.structures[i],
          this.props.structures
        );
        let parentLayerRois =
          this.props.roiLayers[parentIndex].layer.regionRois;
        const parentLayerTree = this.props.roiLayers[parentIndex].tree;

        this.props.roiLayers[i].layer.regionRois.forEach(function (element) {
          // check if object exists in parent layer
          let doubleObject = false;
          let doubleObjectIndex = 0;
          const overlappingParentRois = parentLayerTree.search(
            element.treeItem
          );
          overlappingParentRois.forEach((parentTreeItem) => {
            const parentRoi = parentTreeItem.roi;
            if (
              Math.abs(parentRoi.bounds.left - element.bounds.left) <= 5 &&
              Math.abs(parentRoi.bounds.top - element.bounds.top) <= 5 &&
              Math.abs(parentRoi.bounds.right - element.bounds.right) <= 5 &&
              Math.abs(parentRoi.bounds.bottom - element.bounds.bottom) <= 5
            ) {
              doubleObject = true;
              doubleObjectIndex = parentLayerRois.indexOf(parentRoi);
            }
          });

          // set element properties
          element.subtypeName = strs[i].label;
          element.color = strs[i].color;
          element.structureId = strs[i].id;
          element.isSubtype = true;

          if (!doubleObject) {
            // add to new layer that gets appended at end of parent
            layer.push(element);
          } else {
            // replace element in parent layer with this element
            parentLayerRois.splice(doubleObjectIndex, 1, element);
          }
        });

        // make childlayer empty and put child rois in parentlayer
        let arrayA = parentLayerRois;
        let newArray = arrayA.concat(layer);
        this.props.roiLayers[parentIndex].layer.regionRois = newArray;
        this.props.roiLayers[i].layer.regionRois = [];
        // build tree for parent
        this.props.roiLayers[parentIndex].tree = new RBush();
        for (let regionRoi of newArray) {
          this.props.roiLayers[parentIndex].tree.insert(regionRoi.treeItem);
        }
        this.props.roiLayers[i].tree = new RBush();
        window.forceSidebarUpdate(); // update Sidebar, so annotations count
      }
    }
  };

  // triggered by moving or zooming
  zoomMoveAction = () => {
    const p1 = this.getPointInCanvas({ x: 0, y: 0 });
    const p2 = this.getPointInCanvas({
      x: this.canvas.width,
      y: this.canvas.height,
    });
    // save current zoom and position for reload
    const zoomObject = {
      zoomRoi: true,
      zoomLeft: p1.x,
      zoomRight: p1.x + p2.x - p1.x,
      zoomTop: p1.y,
      zoomBottom: p1.y + p2.y - p1.y,
    };
    if (!this.props.showFullscreen) {
      this.props.persistentStorage.save(
        "zoomObject" + this.props.fileId,
        zoomObject
      );
    }
    this.props.updateVisibleROIDebounced(p1, p2);
  };

  zoomMoveActionDebounced = debounce(this.zoomMoveAction, 100);

  moveAnnotations = () => {
    for (let struct of this.props.roiLayers) {
      let newRegionList = [];
      if (struct.layer.regionRois.length > 0) {
        for (let roi of struct.layer.regionRois) {
          let newRegion = [];
          for (let region of roi.regions[0]) {
            let point = [region[0] + 1, region[1] - 0.5];
            newRegion.push(point);
          }
          let newRoi = new RegionROI(newRegion);
          newRegionList.push(newRoi);
        }
        struct.tree.clear();
        struct.layer.regionRois = newRegionList;
        struct.tree.load(newRegionList.map((item) => item.treeItem));
      }
    }
  };

  updateZ = (z, minZ = this.state.minZ, maxZ = this.state.maxZ) => {
    this.setMountedState({ z: z, minZ: minZ, maxZ: maxZ });
    this.props.updateGlobalZ(z, minZ, maxZ);
  };

  updateT = (t) => {
    this.setMountedState({ t: t });
    this.props.updateGlobalT(t);
  };

  drawVisibleImage = () => {
    this.zoomInRoi(); // zoom to roi if middle mouse button clicked on gallery image
    this.setStructureIds(); //set structureIds if first one is 0 and not base roi
    this.setSubtypeRois(); // set subtypes if iam is finished

    // calc deltatime for animations and fps
    let t1 = performance.now();
    let dt = t1 - this.t0;
    this.t0 = t1;

    // calc smoothed fps
    this.lastFps.pop();
    this.lastFps = [1000 / dt, ...this.lastFps];
    this.fps = Math.round(
      this.lastFps.reduce((acc, val) => (acc += val)) / this.lastFps.length
    );
    if (FPS) {
      this.setMountedState({
        fps: this.fps,
      });
    }

    // time playback
    if (this.state.playing) {
      let newT =
        this.state.t + (dt / 60000) * this.state.sr * this.state.playDirection;
      if (newT >= this.props.ome.sizeT) {
        newT = 0;
      } else if (newT < 0) {
        newT = this.props.ome.sizeT - 0.001;
      }
      this.updateT(newT);
      this.preloadNextFrames();
    }

    // time playback
    if (this.state.playingZ) {
      let newZ =
        this.state.z +
        (dt / 60000) * this.state.zsr * this.state.playDirectionZ;
      if (newZ > this.props.ome.sizeZ - 1) {
        newZ = 0;
      } else if (newZ < 0) {
        newZ = this.props.ome.sizeZ - 1;
      } else {
        newZ = newZ < 0 ? this.props.ome.sizeZ - 1 : newZ;
      }
      this.updateZ(newZ);
      this.preloadNextZFrames();
    }

    // nothing to draw if we haven't loaded our meta data yet
    if ((!this.props.ome || !this.canvas) && this.keepRendering) {
      // request next animation frame
      requestAnimationFrame(() => this.draw());
      return;
    }

    // call the initialization if the viewer is not initalized yet
    if (!this.state.initialized) {
      // do we have our top region already loaded? (then -> this.vw is greater 0)
      if (this.imgLoaded && this.imgRegistered) {
        // do initial placement
        this.zoomOut();
      } else {
        this.loadAllTiles();
      }
    }

    // only set z once
    if (!this.state.initializedZ) {
      let z = this.props.persistentStorage.load("z");
      if (typeof z === "undefined") {
        z = Math.floor(this.props.ome.sizeZ / 2);
      }
      let minZ = this.props.persistentStorage.load("minZ");
      if (typeof minZ === "undefined") {
        minZ = 0;
      }
      let maxZ = this.props.persistentStorage.load("maxZ");
      if (typeof maxZ === "undefined") {
        maxZ = this.props.ome.sizeZ - 1;
      }
      this.updateZ(z, minZ, maxZ);
      this.props.tiles.setZLevel(Math.floor(this.props.ome.sizeZ / 2));
      this.setMountedState({
        minZ,
        maxZ,
        initializedZ: true,
      });
    }

    if (this.zoomTo) {
      if (typeof this.zoomPoint === "undefined") {
        this.zoomPoint = { x: this.lastX, y: this.lastY };
      }
      if (this.zoomDirection > 0 && this.getScale() < this.zoomTo) {
        // zoom in
        // calc our transformation offset
        let pt = this.getPointInCanvas({
          x: this.zoomPoint.x,
          y: this.zoomPoint.y,
        });

        this.ctx.translate(pt.x, pt.y);
        // calc scale factor (zoom per second)
        let factor = Math.pow(20, 40 / 1000);
        // perform scaling
        this.ctx.scale(factor, factor);
        if (this.getScale() > this.zoomTo) {
          this.canvasTranform(
            this.zoomTo,
            this.ctx.getTransform().b,
            this.ctx.getTransform().c,
            this.zoomTo,
            this.ctx.getTransform().e,
            this.ctx.getTransform().f
          );
        }
        // restore our offset
        this.ctx.translate(-pt.x, -pt.y);
        // update screen, update scalebar etc
        this.updatePreviewRect(this.props.activeTool);
        this.zoomMoveActionDebounced();
      } else if (this.zoomDirection < 0 && this.getScale() > this.zoomTo) {
        // zoom out
        // calc our transformation offset
        let pt = this.getPointInCanvas({
          x: this.zoomPoint.x,
          y: this.zoomPoint.y,
        });
        this.ctx.translate(pt.x, pt.y);
        // calc scale factor (zoom per second)
        let factor = Math.pow(20, -40 / 1000);
        // perform scaling
        this.ctx.scale(factor, factor);

        if (this.getScale() < this.zoomTo) {
          this.canvasTranform(
            this.zoomTo,
            this.ctx.getTransform().b,
            this.ctx.getTransform().c,
            this.zoomTo,
            this.ctx.getTransform().e,
            this.ctx.getTransform().f
          );
        }
        // restore our offset
        this.ctx.translate(-pt.x, -pt.y);
        // update screen, update scalebar etc

        this.updatePreviewRect(this.props.activeTool);
        this.zoomMoveActionDebounced();
      } else {
        // we reached the destination zoom level
        this.zoomTo = null;
      }
    }

    // get world size from ome metadata
    this.vw = this.props.ome.sizeX;
    this.vh = this.props.ome.sizeY;

    // Clear the entire canvas
    let p1 = this.getPointInCanvas({ x: 0, y: 0 });
    let p2 = this.getPointInCanvas({
      x: this.canvas.width,
      y: this.canvas.height,
    });
    this.ctx.clearRect(p1.x, p1.y, p2.x - p1.x, p2.y - p1.y);
    this.ctx.save();
    this.canvasTranform(1, 0, 0, 1, 0, 0);
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    this.ctx.restore();

    this.ctx.fillStyle = "#000";
    this.ctx.fillRect(0, 0, this.vw, this.vh);

    // get pyramid level based on scaling
    this.level = getBaseLog(2, (this.getScale() / this.state.initialScale) * 2);
    // clip our level at lower bound
    if (this.level < 0 || this.level === Infinity) {
      this.level = 0;
    }
    // clip our level at upper bound
    if (this.level > this.props.ome.maxLevel) {
      this.level = this.props.ome.maxLevel;
    }

    let lv = Math.floor(this.level);

    // calculate the number of grid columns for this level
    let cols = Math.pow(2, lv);

    // get visbile column indices
    let visX = [];
    for (let i = 0; i < cols; i++) {
      if (
        this.getPosition().x / this.getScale() < this.vw * ((i + 1) / cols) &&
        (this.getPosition().x + this.canvas.width) / this.getScale() >
          this.vw * (i / cols)
      ) {
        visX.push(i);
      }
    }
    // out of bounds, add first region
    if (visX.length === 0) visX.push(0);

    // get visbile row indices
    let visY = [];
    for (let i = 0; i < cols; i++) {
      if (
        this.getPosition().y / this.getScale() < this.vh * ((i + 1) / cols) &&
        (this.getPosition().y + this.canvas.height) / this.getScale() >
          this.vh * (i / cols)
      ) {
        visY.push(i);
      }
    }
    // out of bounds, add first region
    if (visY.length === 0) visY.push(0);

    for (let x of visX) {
      for (let y of visY) {
        //let page = this.getPage();
        //for(let page = 0; page < this.props.histogramConfig.channels.length; page++) {
        for (let c = 0; c < this.props.histogramConfig.channels.length; c++) {
          let page = this.getPageForChannel(c);
          let channel = this.props.histogramConfig.channels[c];

          // skip disabled channels
          if (!channel.enabled) continue;
          // file +tile id im string
          // load image first if missing
          let tileId =
            page + "," + lv + "," + x + "," + y + "," + this.props.fileId;
          if (
            this.zoomTo == null &&
            !this.props.tiles.getVisibleImage(tileId) &&
            !this.state.isLoadingTiles &&
            !this.props.changingFile
            /// TODO: Commented out to fix splitscreen, why has it been added originally?
            //&& this.props.tiles.getFileId() === this.props.fileId
          ) {
            let visImg = new Image();
            visImg.src = Backend.renderRegion({
              id: this.props.fileId,
              page: page,
              lv: lv,
              x: x,
              y: y,
            });
            this.props.tiles.pushVisibleImage(visImg, tileId);
          }

          let img = this.props.tiles.getVisibleImage(tileId);
          this.imgLoaded = img && img.complete;
          // see https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/globalCompositeOperation
          const compositionModeFluor = "screen";
          const compositionModeBrightfield = "lighter";
          if (this.imgLoaded && this.imgRegistered) {
            // image is loaded -> draw it
            this.pushColoredImage(c, tileId, img);

            if (
              tileId.includes(this.props.activeFileId) &&
              (this.props.tiles.getImgWidth() !== img.width ||
                this.props.tiles.getImgHeight() !== img.height)
            ) {
              // set context width and height (for splitscreen and gallery)
              this.props.tiles.setImgWidth(img.width);
              this.props.tiles.setImgHeight(img.height);
            }

            // composition should be screen for fluorescence
            if (this.props.ome.channels[c].type === "fluorescence") {
              this.ctx.globalCompositeOperation = compositionModeFluor;
            } else {
              this.ctx.globalCompositeOperation = compositionModeBrightfield;
            }
            if (
              Object.prototype.toString.call(
                this.props.tiles.getColoredImage(tileId)
              ) === "[object HTMLCanvasElement]" &&
              this.props.tiles.getColoredImage(tileId)
            ) {
              // draw colored image layer
              this.ctx.drawImage(
                this.props.tiles.getColoredImage(tileId),
                (x * this.vw) / cols,
                (y * this.vh) / cols,
                this.vw / cols,
                this.vh / cols
              );
            }
          } else {
            // draw image of previus pyramid level instead
            let factor = 1;
            let parentlv = lv;
            while (parentlv > 0) {
              parentlv--;
              factor *= 2;
              // get id of that image
              tileId =
                page +
                "," +
                parentlv +
                "," +
                Math.floor(x / factor) +
                "," +
                Math.floor(y / factor) +
                "," +
                this.props.fileId;

              // image is loaded -> draw it
              let imgOld = this.props.tiles.getVisibleImage(tileId);

              if (imgOld && imgOld.complete) {
                this.pushColoredImage(c, tileId, imgOld);

                // composition should be screen for fluorescence
                // see https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/globalCompositeOperation
                this.ctx.globalCompositeOperation = compositionModeFluor;
                // draw colored image layer
                if (this.props.tiles.getColoredImage(tileId)) {
                  this.ctx.drawImage(
                    this.props.tiles.getColoredImage(tileId),
                    ((x % factor) * imgOld.width) / factor,
                    ((y % factor) * imgOld.height) / factor,
                    imgOld.width / factor,
                    imgOld.height / factor,
                    (x * this.vw) / cols,
                    (y * this.vh) / cols,
                    this.vw / cols,
                    this.vh / cols
                  );
                }
                break;
              }
            }
          }
        }
      }
    }
    // back to default overlay composition operation
    // see https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/globalCompositeOperation
    this.ctx.globalCompositeOperation = "source-over";
  };

  draw = () => {
    if (this.objectMode && this.props.activeTool !== "rectangle") {
      //deactivate if tool not active anymore
      this.objectMode = false;
    }
    const opacity = this.props.opacity;
    // let roiIdx = 1;
    if (this.state.previewRectChanged) {
      this.updatePreviewRect(this.props.activeTool);
    }

    this.drawVisibleImage();

    let p1 = this.getPointInCanvas({ x: 0, y: 0 });
    let p2 = this.getPointInCanvas({
      x: this.canvas.width,
      y: this.canvas.height,
    });

    // draw pixel annotation layer
    this.ctx.globalAlpha = 0.5;
    this.ctx.lineWidth = 1 / this.ctx.getTransform().a;
    const scaleFactor = 1 / this.ctx.getTransform().a;
    let drawLayer = this.props.drawLayer;
    let commentLayer = this.props.commentLayer;
    let landmarkLayer = this.props.landmarkLayer;
    const maxGeometries = 15000;
    if (!this.state.limitVisibleRegions) {
      const roiCount = this.props.roiLayers.reduce(
        (a, b) => a + b.layer.regionRois.length,
        0
      );
      if (roiCount > maxGeometries) {
        this.setMountedState({ limitVisibleRegions: true });
      }
    }

    for (let l = 0; l < this.props.structures.length; l++) {
      let structure = this.props.structures[l];
      const roiLayer = this.props.roiLayers.find((c) => c.id === structure.id);
      if (typeof roiLayer === "undefined") {
        console.log("undefined layer found for roiLayer id", structure.id);
        continue;
      }

      let parentIndex = getParentIndexLayer(structure, this.props.structures);

      let rr = roiLayer.layer;
      let rRoi = {
        regionRois: [],
        inverted: false,
      };

      let centerX = p1.x + (p2.x - p1.x) / 2;
      let centerY = p1.y + (p2.y - p1.y) / 2;

      let visibleRegions = [];
      if (this.state.limitVisibleRegions) {
        if (this.updateCounter > 5) {
          if (this.fps < 10) {
            this.visibleRegionsRadius = this.visibleRegionsRadius * 0.98;
          } else if (this.fps > 20) {
            this.visibleRegionsRadius += 10;
          }
          this.updateCounter = 0;
        }
        this.updateCounter += 1;
        let serachParams = {
          minX: centerX - this.visibleRegionsRadius,
          minY: centerY - this.visibleRegionsRadius,
          maxX: centerX + this.visibleRegionsRadius,
          maxY: centerY + this.visibleRegionsRadius,
        };
        let sarchLayer = structure.isSubtype
          ? this.props.roiLayers[parentIndex]
          : roiLayer;
        visibleRegions = sarchLayer.tree
          .search(serachParams)
          .map((treeItem) => treeItem.roi);
      } else {
        visibleRegions = structure.isSubtype
          ? this.props.roiLayers[parentIndex].layer.regionRois
          : roiLayer.layer.regionRois;
      }

      let structureHasRois = false;
      if (!structure.isSubtype || !structure.classificationSubtype) {
        structureHasRois = visibleRegions.length > 0 ? true : structureHasRois;
        if (structureHasRois) {
          // get rois for parent or substructure
          rr = visibleRegions.filter(
            (element) => !element.isSubtype && !element.aiAnnotated
          );
          rRoi.regionRois = rr;
        }
      } else if (structure.isSubtype) {
        // get subtype rois from parent layer
        structureHasRois = visibleRegions.length > 0 ? true : structureHasRois;

        if (structureHasRois) {
          rr = visibleRegions.filter(
            (element) =>
              element.subtypeName === structure.label &&
              element.color === structure.color // check color because of same structure names
          );
          rRoi.regionRois = rr;
        }
      }

      // check if parent of substructure shows subtype
      let showSubstructure = true;
      if (structure.isSubtype && !structure.classificationSubtype) {
        parentIndex = getParentIndex(structure, this.props.structures);
        if (parentIndex >= 0) {
          showSubstructure = this.props.structures[parentIndex].showSubtypes;
        }
      }

      // check if parent of subtype is substructure and not unfolded
      if (structure.isSubtype && structure.classificationSubtype) {
        let obj = this.subtypeHasSubstructureParent(structure);
        if (obj.hasStrParent) {
          // if parent is substructure --> check if it is visible
          let parentIdx = getParentIndex(
            this.props.structures[obj.index],
            this.props.structures
          );
          if (parentIdx >= 0) {
            showSubstructure = this.props.structures[parentIdx].showSubtypes;
          }
        }
      }
      if (structure.visible && showSubstructure && structureHasRois) {
        let fillstyle = "";
        // This color is the one of the filled shape
        if (!structure.classificationSubtype) {
          // draw parent always in parent color
          if (
            this.props.structures.length > 3 &&
            this.props.structures[3] === structure
          ) {
            //console.log("color2: " + structure.color);
          }
          this.ctx.fillStyle = structure.color;
          fillstyle = structure.color;
        } else {
          // find color for subtypeStructure
          //console.log("color3: " + this.findColor(structure));
          fillstyle = this.findColor(structure);
          this.ctx.fillStyle = fillstyle;
        }

        if (l === 0) {
          let ptrn = this.ctx.createPattern(this.backgroundPattern, "repeat");
          let patternScale = 1 / this.getScale();
          ptrn.setTransform({
            a: patternScale,
            b: 0,
            c: 0,
            d: patternScale,
            e: 0,
            f: 0,
          });
          this.ctx.fillStyle = ptrn;
        }

        // draw base roi
        this.ctx.strokeStyle = this.state.isBrightfield ? "black" : "white";
        if (structure.inversed && rRoi.regionRois.length > 0) {
          this.ctx.lineWidth = 2 / this.ctx.getTransform().a;
          this.ctx.globalAlpha = 1.0;
          this.ctx.strokeStyle = this.state.isBrightfield ? "black" : "white";
          // Draw the shape you want to take out
          this.ctx.beginPath();
          this.ctx.moveTo(0, 0);
          this.ctx.lineTo(this.vw, 0);
          this.ctx.lineTo(this.vw, this.vh);
          this.ctx.lineTo(0, this.vh);
          this.ctx.lineTo(0, 0);

          for (let roi of rRoi.regionRois) {
            for (let region of roi.regions) {
              if (region[region.length - 1]) {
                this.ctx.moveTo(
                  region[region.length - 1][0],
                  region[region.length - 1][1]
                );
                for (let i = 0; i < region.length; i++) {
                  this.ctx.lineTo(region[i][0], region[i][1]);
                }
              }
            }
          }

          this.ctx.closePath();
          this.ctx.fill("evenodd");
          this.ctx.stroke();
        } else {
          this.ctx.lineWidth = 1 / this.ctx.getTransform().a;
          this.ctx.globalAlpha = opacity / 2;
          this.ctx.beginPath();

          visibleRegions = rRoi.regionRois.filter((roi) => {
            return roi.intersects(p1, p2);
          });

          // Draw visible, non-object regions
          for (const roi of visibleRegions.filter((roi) => !roi.isObject)) {
            let comp = roi.boundsAreaCompare(p1, p2);
            let regions = [];
            regions = roi.getDynamicRegions(comp, visibleRegions.length);

            for (let points of regions) {
              // //roi.regions,) {
              let region = {
                points: points,
                bounds: roi.bounds,
              };

              this.ctx.moveTo(points.x, points.y);
              if (points[points.length - 2]) {
                this.ctx.moveTo(
                  points[points.length - 2][0],
                  points[points.length - 2][1]
                );
                for (let i = 0; i < points.length; i++) {
                  this.ctx.lineTo(points[i][0], points[i][1]);
                }
              }

              if (region[0]) {
                this.ctx.moveTo(
                  region[region.length - 1][0],
                  region[region.length - 1][1]
                );
                for (let i = 0; i < region.length; i++) {
                  this.ctx.lineTo(region[i][0], region[i][1]);
                }
              }
              // show grid labels and tile names
              if (this.props.showGridLabels) {
                // draw tile name of roi / tile in upper right corner
                this.ctx.fillStyle = "#ff0000";
                this.ctx.font = "90px Arial";
                this.ctx.globalAlpha = 0.8;
                this.ctx.textAlign = "right";
                if (roi.bounds.right === this.props.ome.sizeX) {
                  this.ctx.textAlign = "center";
                }
                this.ctx.textBaseline = "top";
                this.ctx.fillText(
                  roi.tileName,
                  points[points.length - 2][0] +
                    (roi.bounds.right - roi.bounds.left),
                  points[points.length - 2][1] -
                    (roi.bounds.bottom - roi.bounds.top)
                );

                // if roi / tile is on edge draw col / row name
                this.ctx.fillStyle = "#ff0000";
                this.ctx.font = "300px Arial";
                this.ctx.globalAlpha = 1;
                // left edge
                if (roi.bounds.left === 0) {
                  let txt = roi.tileName.replace(/[^0-9]/g, "");
                  this.ctx.textAlign = "end";
                  this.ctx.textBaseline = "middle";
                  this.ctx.fillText(
                    txt,
                    points[points.length - 2][0] - 30,
                    points[points.length - 2][1] -
                      (roi.bounds.bottom - roi.bounds.top) / 2
                  );
                }
                // top edge
                if (roi.bounds.top === 0) {
                  let txt = roi.tileName.replace(/[^A-Z]/g, "");
                  this.ctx.textAlign = "center";
                  if (roi.bounds.right === this.props.ome.sizeX) {
                    this.ctx.textAlign = "left";
                  }
                  this.ctx.textBaseline = "bottom";
                  this.ctx.fillText(
                    txt,
                    points[points.length - 2][0] +
                      (roi.bounds.right - roi.bounds.left) / 2,
                    points[points.length - 2][1] -
                      (roi.bounds.bottom - roi.bounds.top)
                  );
                }
              }
              this.ctx.globalAlpha = opacity / 2;
              this.ctx.fillStyle = fillstyle;
            }
          }
          this.ctx.closePath();
          this.ctx.fill("evenodd");
          if (opacity === 0) {
            this.ctx.strokeStyle = structure.color;
            this.ctx.lineWidth = 2 / this.ctx.getTransform().a;
          }
          this.ctx.globalAlpha = 1.0;
          this.ctx.stroke();

          let tempStrokeStyle = this.ctx.strokeStyle;
          let tempLineWidth = this.ctx.lineWidth;
          this.ctx.lineWidth = 2 / this.ctx.getTransform().a;
          this.ctx.strokeStyle = structure.color;

          this.ctx.beginPath();

          // Draw non-area objects
          for (const roi of visibleRegions.filter((roi) => roi.isObject)) {
            let regions = roi.getRectRegions();

            for (let points of regions) {
              if (points[points.length - 2]) {
                this.ctx.moveTo(
                  points[points.length - 2][0],
                  points[points.length - 2][1]
                );
                for (let i = 0; i < points.length; i++) {
                  this.ctx.lineTo(points[i][0], points[i][1]);
                }
              }
            }
          }
          this.ctx.stroke();

          if (opacity !== 0) {
            this.ctx.strokeStyle = tempStrokeStyle;
            this.ctx.lineWidth = tempLineWidth;
          }

          if (this.state.showObjectIdx) {
            let layerRois = structure.isSubtype
              ? this.props.roiLayers[parentIndex].layer.regionRois
              : roiLayer.layer.regionRois;
            this.ctx.beginPath();
            // show number for big annotations
            for (const roi of visibleRegions) {
              let comp = roi.boundsAreaCompare(p1, p2);
              // uncomment for showing text in middle of visibly big polys
              if (comp > 0.001) {
                const fontSize = 24 * scaleFactor;
                //const fontMargin = 6 * scaleFactor;
                this.ctx.fillStyle = this.state.isBrightfield
                  ? "black"
                  : "white";
                this.ctx.font = "bold " + fontSize + "px Arial";
                this.ctx.globalAlpha = 1.0;
                this.ctx.textAlign = "center";
                this.ctx.textBaseline = "bottom";
                let center = roi.getPointOnPoly();
                center.y += fontSize / 2;
                let textToShow = layerRois.findIndex(
                  (regionRoi) => regionRoi.uuid === roi.uuid
                );
                textToShow = textToShow + 1;

                this.ctx.fillText(textToShow.toBase(62), center.x, center.y);
              }
            }
            this.ctx.closePath();
            this.ctx.stroke();
          }
          //this.ctx.strokeStyle = this.state.isBrightfield ? "black" : "white";
          //this.ctx.globalAlpha = opacity / 2;
          //this.ctx.lineWidth = 1 / this.ctx.getTransform().a;
        }
      }
    }
    // if deleting with right click, draw drawing inversed
    if (drawLayer.clear) {
      this.ctx.fillStyle = this.props.structures[this.props.selectedLayer]
        .inversed
        ? this.props.structures[this.props.selectedLayer].color
        : "black";
    } else {
      if (this.props.structures[this.props.selectedLayer].isSubtype) {
        this.ctx.fillStyle = this.props.structures[this.props.selectedLayer]
          .inversed
          ? "black"
          : this.props.structures[this.props.selectedLayer].color;
      } else {
        this.ctx.fillStyle = this.props.structures[this.props.selectedLayer]
          .inversed
          ? "black"
          : this.props.structures[this.props.selectedLayer].color;
      }
    }

    //draw only colored outline, if drawing objects
    if (this.objectMode) {
      this.ctx.globalAlpha = 1;
      this.ctx.fillStyle = "transparent";
      this.ctx.strokeStyle =
        this.props.structures[this.props.selectedLayer].color;
      this.ctx.lineWidth = 2 / this.ctx.getTransform().a;
    } else {
      //draw black outline with filled outline
      this.ctx.globalAlpha = opacity / 2;
      this.ctx.strokeStyle = this.state.isBrightfield ? "black" : "white";
    }

    this.ctx.beginPath();
    for (let roi of drawLayer.regionRois) {
      for (let points of roi.regions) {
        if (points.length > 0) {
          this.ctx.moveTo(
            points[points.length - 1][0],
            points[points.length - 1][1]
          );
          for (let i = 0; i < points.length; i++) {
            this.ctx.lineTo(points[i][0], points[i][1]);
          }
        }
      }
    }
    this.ctx.closePath();
    this.ctx.fill("evenodd");

    this.ctx.globalAlpha = 1;
    if (opacity === 0) {
      this.ctx.strokeStyle =
        this.props.structures[this.props.selectedLayer].color;
      this.ctx.lineWidth = 2 / this.ctx.getTransform().a;
    }
    this.ctx.stroke();

    this.ctx.strokeStyle = this.state.isBrightfield ? "black" : "white";
    this.ctx.globalAlpha = opacity / 2;
    this.ctx.lineWidth = 1 / this.ctx.getTransform().a;
    if (
      commentLayer &&
      commentLayer.commentRois &&
      this.props.activeTool === "comment"
    ) {
      this.ctx.globalAlpha = 1;
      this.ctx.fillStyle = "transparent";
      this.ctx.lineWidth = 4 / this.ctx.getTransform().a;

      for (let roi of commentLayer.commentRois) {
        this.ctx.beginPath();
        this.ctx.strokeStyle = roi.type === "commentBox" ? "black" : roi.color;
        let points = roi.regions;
        if (points && points.length > 1) {
          for (let i = 0; i < points.length; i++) {
            this.ctx.lineTo(points[i][0], points[i][1]);
          }
        }
        if (
          roi.type === "rectangle" ||
          roi.type === "region" ||
          roi.type === "commentBox"
        ) {
          this.ctx.closePath();
        }
        this.ctx.stroke();

        this.ctx.globalAlpha = 0.85;
        this.ctx.fillStyle =
          roi.type === "commentBox" ? "white" : "transparent";
        this.ctx.fill();
        this.ctx.globalAlpha = 1;

        if (roi.commentValue !== "" && roi.regions.length > 1) {
          let angle = 0;
          this.ctx.save();

          // Translate Text Positions
          if (roi.type === "rectangle" || roi.type === "region") {
            this.ctx.translate(roi.bounds.right, roi.bounds.bottom + 20);
          } else if (roi.type === "commentBox") {
            this.ctx.translate(
              roi.bounds.left + Math.round(5 * scaleFactor),
              roi.bounds.top + Math.round(5 * scaleFactor)
            );
          } else if (roi.type === "arrow" || roi.type === "distance") {
            let p1 = { x: roi.regions[0][0], y: roi.regions[0][1] };
            let p2 = { x: roi.regions[1][0], y: roi.regions[1][1] };
            let vector = {
              x: p2.x - p1.x,
              y: p2.y - p1.y,
            };
            if (roi.type === "distance") {
              this.ctx.translate(
                (roi.bounds.left + roi.bounds.right) / 2,
                (roi.bounds.top + roi.bounds.bottom) / 2
              );
              vector.x /= 20;
              vector.y /= 20;
              if (vector.x < 0) {
                this.ctx.translate(-vector.y, vector.x);
              } else {
                this.ctx.translate(vector.y, -vector.x);
              }
              angle = Math.atan2(p2.y - p1.y, p2.x - p1.x);
              if (angle > Math.PI / 2 || angle < -Math.PI / 2) angle -= Math.PI;
            } else {
              this.ctx.translate(roi.bounds.left, roi.bounds.top);
              if (p1.x > p2.x) {
                this.ctx.translate(-vector.x, 0);
              }
              if (p1.y > p2.y) {
                this.ctx.translate(0, -vector.y);
              }
            }
          }

          // Do Text Formating
          this.ctx.rotate(angle);
          this.ctx.fillStyle = roi.type === "commentBox" ? "black" : roi.color;
          let fontSize = Math.round(
            20 * roi.fontScaleFactor * Math.min(scaleFactor, 14)
          );
          // comment back in if old behaviour is wanted -> Font-Size depends on Boxlength and not the scaleFactor in the Scene
          //   roi.type === "commentBox"
          //     ? Math.round(20 * roi.fontScaleFactor * Math.min(scaleFactor, 14))
          //    : Math.round(length / 10 * roi.fontScaleFactor);
          this.ctx.font = fontSize + "px Arial";

          if (roi.type === "arrow") {
            let p1 = { x: roi.regions[0][0], y: roi.regions[0][1] };
            let p2 = { x: roi.regions[1][0], y: roi.regions[1][1] };

            this.ctx.textAlign = "right";
            this.ctx.textBaseline = "bottom";
            if (p1.x > p2.x) {
              this.ctx.textAlign = "left";
            }
            if (p1.y > p2.y) {
              this.ctx.textBaseline = "top";
            }
          } else {
            this.ctx.textAlign = "center";
            this.ctx.textAlign =
              roi.type === "rectangle" || roi.type === "region"
                ? "right"
                : "center";
            this.ctx.textAlign = roi.type === "commentBox" ? "left" : "center";
            this.ctx.textBaseline =
              roi.type === "rectangle" ||
              roi.type === "region" ||
              roi.type === "commentBox"
                ? "top"
                : "middle";
          }
          this.wrapText(
            this.ctx,
            roi.commentValue,
            0,
            0,
            roi.width,
            fontSize + 5
          );
          this.ctx.restore();
        }
      }
    }
    if (landmarkLayer && landmarkLayer.landmarkRois && this.props.showMarks) {
      this.ctx.globalAlpha = 1;
      this.ctx.lineWidth = 2 / this.ctx.getTransform().a;
      let linelength = 15 / this.getScale();
      for (let roi of landmarkLayer.landmarkRois) {
        if (typeof roi.regions !== "undefined") {
          this.ctx.beginPath();
          this.ctx.strokeStyle = roi.color;
          let points = roi.regions;

          let x = points[0];
          let y = points[1];
          this.ctx.lineTo(x, y);
          this.ctx.lineTo(x, y - linelength);
          this.ctx.lineTo(x, y + linelength);
          this.ctx.lineTo(x, y);
          this.ctx.lineTo(x - linelength, y);
          this.ctx.lineTo(x + linelength, y);
          this.ctx.lineTo(x, y);

          this.ctx.closePath();
          this.ctx.stroke();

          this.ctx.save();
          this.ctx.translate(x + linelength, y - linelength);

          const scaleFactor = 1 / this.ctx.getTransform().a;
          let fontSize = Math.round(20 * roi.fontScaleFactor * scaleFactor);
          //* Math.min(scaleFactor, 14)
          this.ctx.globalAlpha = 1;
          this.ctx.font = fontSize + "px Arial";
          this.ctx.textAlign = "center";
          this.ctx.textBaseline = "middle";
          this.ctx.fillStyle = roi.color;
          let val = roi.commentValue;
          this.wrapText(this.ctx, String(val), 0, 0, linelength, linelength);
          this.ctx.restore();
        }
      }
    }

    //show heatmap while tool is active
    if (this.props.activeTool !== "heatmap" && this.showHeatmap) {
      this.showHeatmap = false;
      this.heatmap.setData({ max: 100, data: [] });
    }

    // let p1 = this.getPointInCanvas({ x: 0, y: 0 });
    // let p2 = this.getPointInCanvas({
    //   x: this.canvas.width,
    //   y: this.canvas.height,
    // });

    this.ctx.globalAlpha = 1.0;
    // draw scale bar
    if (this.state.showScaleBar && this.state.scaleBarData) {
      this.ctx.beginPath();
      this.ctx.strokeStyle = this.state.isBrightfield ? "black" : "white";
      const scaleFactor = 1 / this.ctx.getTransform().a;
      this.ctx.lineWidth = 3 * scaleFactor;
      const marginRight = 5 * scaleFactor;
      const marginBottom = 14 * scaleFactor;
      const scaleWidth = (this.state.scaleBarData.width - 2) * scaleFactor;
      const stopLineHeight = 16 * scaleFactor;
      const maxScaleWidth = this.state.scaleBarData.maxScaleWidth * scaleFactor;
      const delta = {
        x: this.state.scaleBarData.x * scaleFactor,
        y: this.state.scaleBarData.y * scaleFactor,
      };
      const center = {
        x: p2.x - marginRight - maxScaleWidth / 2 + delta.x,
        y: p2.y - marginBottom + delta.y,
      };
      this.ctx.moveTo(center.x - scaleWidth / 2, center.y);
      this.ctx.lineTo(center.x - scaleWidth / 2, center.y - stopLineHeight / 2);
      this.ctx.lineTo(center.x - scaleWidth / 2, center.y + stopLineHeight / 2);
      this.ctx.lineTo(center.x - scaleWidth / 2, center.y);
      this.ctx.lineTo(center.x + scaleWidth / 2, center.y);
      this.ctx.lineTo(center.x + scaleWidth / 2, center.y - stopLineHeight / 2);
      this.ctx.lineTo(center.x + scaleWidth / 2, center.y + stopLineHeight / 2);
      this.ctx.stroke();

      const fontSize = 14 * scaleFactor;
      const fontMargin = 6 * scaleFactor;
      this.ctx.fillStyle = this.state.isBrightfield ? "black" : "white";
      this.ctx.font = "bold " + fontSize + "px Arial";
      this.ctx.globalAlpha = 1.0;
      this.ctx.textAlign = "center";
      this.ctx.textBaseline = "bottom";
      this.ctx.fillText(
        this.state.scaleBarData.label,
        center.x,
        center.y - fontMargin
      );
    }

    // uncomment to debug pixal annotaion regions
    /*let rects = calcBoundingBoxes(this.layer);
    for(let rect of rects) {
      this.ctx.strokeStyle = "black"; 
      this.ctx.beginPath();
      this.ctx.rect(rect.left, rect.top, rect.right - rect.left, rect.bottom - rect.top)
      this.ctx.closePath();
      this.ctx.stroke();
    }*/

    // draw rois
    for (let roi of this.props.rois.filter((c) => !c.hovered)) {
      roi.draw({
        selected: roi === this.props.selectedROI,
      });
    }
    for (let roi of this.props.rois.filter((c) => c.hovered)) {
      roi.draw({
        selected: roi === this.props.selectedROI,
      });
    }

    // draw custom mouse cursor for pen tool
    if (this.props.tools[this.props.activeTool]) {
      this.ctx.lineWidth = 1 / this.ctx.getTransform().a;
      let centerX = p1.x + (p2.x - p1.x) / 2;
      let centerY = p1.y + (p2.y - p1.y) / 2;
      this.props.tools[this.props.activeTool].drawCustomCursor(
        this.ctx,
        this.mousePos,
        this.getScale(),
        this.canvas,
        { x: centerX, y: centerY },
        this.state.mouseOnCanvas
      );
    }

    // check if tiles are still loading from server
    let isLoadingTiles = false;
    for (let tileId in this.props.tiles.getVisibleImages()) {
      if (this.props.tiles.getVisibleImage(tileId).complete === false) {
        isLoadingTiles = true;
        break;
      }
    }
    if (isLoadingTiles !== this.state.isLoadingTiles) {
      let nowTime = performance.now();
      this.setMountedState(
        {
          isLoadingTiles: isLoadingTiles,
          nowTime: nowTime,
        },
        () => {
          if (!isLoadingTiles) {
            // console.log(
            //   "loading tiles took ",
            //   performance.now() - this.state.nowTime,
            //   "ms,"
            // );
          }
        }
      );
    }
    if (this.props.showWindowTool && this.props.isActive) {
      if (this.props.windowToolRef) {
        this.props.windowToolRef.updateCanvas();
      }
    }

    // request next animation frame
    if (this.keepRendering) {
      requestAnimationFrame(() => this.draw());
    }
  };

  wrapText = (context, text, x, y, maxWidth, lineHeight, maxHeight) => {
    if (text.split("\n").length > 1) {
      for (let line of text.split("\n")) {
        this.wrapText(
          context,
          line,
          x,
          y + this.lineIdx * lineHeight,
          maxWidth,
          lineHeight,
          maxHeight
        );
        this.lineIdx += 1;
      }
      this.lineIdx = 0;
    } else {
      let words = text.split(" ");
      let line = "";

      for (let n = 0; n < words.length; n++) {
        let testLine = line + words[n] + " ";
        let metrics = context.measureText(testLine);
        let testWidth = metrics.width;
        if (testWidth > maxWidth && n > 0) {
          context.fillText(line, x, y);
          line = words[n] + " ";
          y += lineHeight;
          this.lineIdx += 1;
        } else {
          line = testLine;
        }
      }
      context.fillText(line, x, y);
    }
  };

  subtypeHasSubstructureParent = (structure) => {
    // check if subtype has substructure as parent
    let obj = {
      hasStrParent: false,
      index: -1,
    };

    let index = this.props.structures.findIndex(
      (element) => element === structure
    );
    while (
      this.props.structures[index].subtypeLevel !== 0 &&
      !obj.hasStrParent
    ) {
      index = getParentIndex(
        this.props.structures[index],
        this.props.structures
      );
      obj.index = index;
      // if not classification subtype --> substructure
      if (
        !this.props.structures[index].classificationSubtype &&
        this.props.structures[index].isSubtype
      ) {
        obj.hasStrParent = true;
      }
    }
    return obj;
  };

  findColor = (structure) => {
    // get color for subtype depending on if structure is unfolded (if not unfolded color of parent)
    let parentIndex = this.props.structures.findIndex(
      (element) => element === structure
    );
    while (!this.props.structures[parentIndex].isUnfolded) {
      parentIndex = getParentIndex(
        this.props.structures[parentIndex],
        this.props.structures
      );
    }
    return this.props.structures[parentIndex].color;
  };

  getChildsofStructure = (parentStructure) => {
    // return direct classification subtypes
    return this.props.structures.filter(
      (element) =>
        element.parentId === parentStructure.id && element.classificationSubtype
    );
  };

  setNewSelRoi = (roi) => {
    this.selROI = roi;
  };

  setZoomLevelForGridSize = (gridSize) => {
    // make initial zoomlevel
    this.zoomFit();
    // zoom in that tile is size of viewer
    this.ctx.scale(gridSize, gridSize);
  };

  classifySelectedRoi = (classId, withMouse) => {
    const { selectedLayer, roiLayers, structures } = this.props;
    // classify selected roi with key shortcut

    // change getChildsofStructure for multiple subtypelvels
    let childs = this.getChildsofStructure(structures[selectedLayer]);

    // get parent layer
    let parentIndexLayer = getParentIndexLayer(
      structures[selectedLayer],
      structures
    );

    let idxRoi = roiLayers[parentIndexLayer].layer.regionRois.findIndex(
      (element) =>
        element.bounds.left === this.selROI.r.bounds.left &&
        element.bounds.top === this.selROI.r.bounds.top &&
        element.bounds.bottom === this.selROI.r.bounds.bottom &&
        element.bounds.right === this.selROI.r.bounds.right
    );

    let idxStr = structures.findIndex(
      (element) =>
        element.color === this.selROI.colors &&
        element.parentId === structures[selectedLayer].id
    );

    let parentStructure = false;
    let strToUse = null;
    if (withMouse) {
      // with mouse
      // parent structure
      if (idxStr === -1) {
        parentStructure = true;
        idxStr = selectedLayer;
      }
      strToUse = structures[idxStr];
    } else {
      // with keys
      strToUse = childs[classId - 1];
    }

    let selectedRoi = roiLayers[parentIndexLayer].layer.regionRois[idxRoi];
    let historyItem = [];
    let histId = structures[parentIndexLayer].id;
    if (
      !parentStructure &&
      idxRoi !== -1 &&
      (childs[classId - 1] || strToUse) &&
      selectedRoi
    ) {
      historyItem.push({ add: false, id: histId, roi: selectedRoi.copy() });
      // set properties of roi for classification
      selectedRoi.color = strToUse.color;
      selectedRoi.isSubtype = true;
      selectedRoi.isAnnotated = true;
      selectedRoi.subtypeName = strToUse.label;
      selectedRoi.structureId = strToUse.id;
      selectedRoi.isLabeled = true;
      selectedRoi.isSelObj = false;
      selectedRoi.aiAnnotated = false;
      historyItem.push({ add: true, id: histId, roi: selectedRoi.copy() });
      window.projectHistory.add(historyItem);
    }

    if ((classId === 0 || parentStructure) && selectedRoi) {
      historyItem.push({ add: false, id: histId, roi: selectedRoi.copy() });
      selectedRoi.color = structures[selectedLayer].color;
      selectedRoi.isSubtype = false;
      selectedRoi.isAnnotated = false;
      selectedRoi.subtypeName = structures[selectedLayer].label;
      selectedRoi.structureId = structures[selectedLayer].id;
      selectedRoi.isLabeled = false;
      selectedRoi.isSelObj = false;
      selectedRoi.aiAnnotated = false;
      historyItem.push({ add: true, id: histId, roi: selectedRoi.copy() });
      window.projectHistory.add(historyItem);
    }

    // if changeFile is true --> change file after classification
    setTimeout(() => {
      let nextFileId = this.getNextFileId();
      if (
        this.selROI.changeFile === true &&
        this.selROI.automaticFileChange === true &&
        nextFileId
      ) {
        this.props.onSelectFile(nextFileId);
      }
    }, 1000);
  };

  getNextFileId = () => {
    const { project, fileId } = this.props;
    let inxCurrentFile = project.files.findIndex(
      (element) => element.id === fileId
    );

    if (project.files[inxCurrentFile + 1]) {
      return project.files[inxCurrentFile + 1].id;
    } else {
      if (
        this.props.project.type.includes("HistoClassification") ||
        this.props.project.type.includes("HistoPointCounting")
      ) {
        // if last file
        window.showWarningSnackbar("Last file.");
      }
      return null;
    }
  };

  checkToolInConfig = (toolName) => {
    if (this.props.viewerConfig) {
      if (this.props.viewerConfig.project.toolsInProject[toolName]) {
        return true;
      } else {
        return false;
      }
    }
  };

  keyDown = (event) => {
    if (
      this.props.tools[this.props.activeTool] &&
      this.props.tools[this.props.activeTool].rendererKeyDown
    ) {
      this.props.tools[this.props.activeTool].rendererKeyDown(event);
    }
  };

  keyDownSelection = (event) => {
    // change selected roi with arrow keys
    let selection = this.props.tools[this.props.activeTool].onKeyDown(
      event,
      true,
      this.isTilesToolUsed()
    );

    if (selection) {
      this.selROI = selection;
      this.props.setSelectedRoi(selection);
    }
  };

  isTilesToolUsed = () => {
    const { roiLayers, selectedLayer } = this.props;
    // check if tiles tool was used to create rois
    let hasLayerTileNames = false;
    if (roiLayers[selectedLayer].layer.regionRois[0]) {
      hasLayerTileNames =
        roiLayers[selectedLayer].layer.regionRois[0].tileName !== "";
    }

    if (this.checkToolInConfig("TilesTool") && hasLayerTileNames) {
      return true;
    } else {
      return false;
    }
  };

  updateFLChannels = (e) => {
    this.props.tiles.setHistogramConfig(e);

    this.setMountedState({ histogramConfig: e });

    // invalidate all cached coloring images
    //this.coloredImages = [];
    this.props.tiles.setColoredImages([]);
    for (let i = 0; i < this.props.structures.length; i++) {
      if (this.props.roiLayers.length > 1) {
        this.props.roiLayers[1].layer.regionRois.forEach(function (point) {
          point.firstTimeGallery = true;
        });
      }
    }
    //this.props.tiles.setColoredImages([]);

    this.loadAllTiles();
  };

  onPlayPause = (e, dir) => {
    this.setMountedState({ playing: e, playingZ: false, playDirection: dir });
  };

  onPlayPauseZ = (e, dir) => {
    this.setMountedState({ playingZ: e, playing: false, playDirectionZ: dir });
  };

  onSeek = (e, v) => {
    this.updateT(v);
  };

  onChangeT = (e) => {
    this.updateT(e);
  };

  onChangeZValue = (z, minZ = this.state.minZ, maxZ = this.state.maxZ) => {
    this.updateZ(z, minZ, maxZ);
    this.props.tiles.setZLevel(z);
    this.props.persistentStorage.save("z", z); //remember for use after project reload or reopen
    this.props.persistentStorage.save("minZ", minZ); //remember for use after project reload or reopen
    this.props.persistentStorage.save("maxZ", maxZ); //remember for use after project reload or reopen
  };

  onChangeZ = (e, v) => {
    let minZ = v[0];
    let z = v[1];
    let maxZ = v[2];
    this.onChangeZValue(z, minZ, maxZ);
  };

  onChangeSr = (e) => {
    this.setMountedState({ sr: e });
  };

  onChangeZSr = (e) => {
    this.setMountedState({ zsr: e });
  };

  onStep = (e) => {
    this.updateT(
      Math.min(this.props.ome.sizeT - 1, Math.max(0, this.state.t + e))
    );
  };

  onStepZ = (e) => {
    let zLevel = Math.min(
      this.props.ome.sizeZ - 1,
      Math.max(0, this.state.z + e)
    );
    this.onChangeZValue(zLevel);
  };

  updatePreviewRectSize = (size) => {
    let scaledWidth = size.width * this.getScale();
    let scaledHeight = size.height * this.getScale();

    if (
      this.state.previewWidth !== scaledWidth ||
      this.state.previewHeight !== scaledHeight
    ) {
      this.setMountedState({
        previewWidth: scaledWidth,
        previewHeight: scaledHeight,
        previewRectChanged: false,
      });
    }
  };

  onResize = (event, { size }) => {
    size.width = size.width / this.getScale();
    size.height = size.height / this.getScale();

    let minSize = 500;
    let maxSize = 2000;
    //let maxSize = 14000;
    size.width = size.width < minSize ? minSize : size.width;
    size.width = size.width > maxSize ? maxSize : size.width;
    size.height = size.height < minSize ? minSize : size.height;
    size.height = size.height > maxSize ? maxSize : size.height;

    this.props.tools[this.props.activeTool].setPreviewSize(
      size.width,
      size.height
    );
    let newSize = {
      width: this.props.tools[this.props.activeTool].state.prevW,
      height: this.props.tools[this.props.activeTool].state.prevH,
    };
    this.updatePreviewRectSize(newSize);
  };

  onToggle = (key) => {
    let keyExtender = key.startsWith("show") ? "" : this.props.fileId;
    //e.g. "showMiniMap", true
    if (this.props.showFullscreen) {
      this.props.persistentStorage.save(
        key + "Full" + keyExtender,
        !this.state[key]
      );
    } else {
      this.props.persistentStorage.save(key + keyExtender, !this.state[key]);
    }

    this.setMountedState({ [key]: !this.state[key] });
  };

  getLayoutElement = (key) => {
    //used to get e.g. "showMiniMap" via rendererRef
    return this.state[key];
  };

  checkMiniMapVisivility = () => {
    const { hideMiniMap, showMiniMap } = this.state;
    const hideMiniMapThresholdWidth = 600;
    const hideMiniMapThresholdHeight = 400;
    const hideRestrictions =
      this.canvas.width < hideMiniMapThresholdWidth ||
      this.canvas.height < hideMiniMapThresholdHeight;
    let saveString = "hideMiniMap" + this.props.fileId;
    if (this.props.showFullscreen) {
      saveString = "hideMiniMapFull" + this.props.fileId;
    }
    if (hideRestrictions && !hideMiniMap && showMiniMap) {
      this.props.persistentStorage.save(saveString, true);
      this.onToggle("showMiniMap");
      this.setMountedState({ hideMiniMap: true });
    } else if (!hideRestrictions && hideMiniMap && !showMiniMap) {
      this.props.persistentStorage.save(saveString, false);
      this.onToggle("showMiniMap");
      this.setMountedState({ hideMiniMap: false });
    } else if (!hideRestrictions && hideMiniMap && showMiniMap) {
      this.props.persistentStorage.save(saveString, false);
      this.setMountedState({ hideMiniMap: false });
    }
  };

  render = () => {
    const { classes, fileId, project, ome, showTimeBar } = this.props;

    const {
      miniMapKey,
      scaleBarData,
      open,
      anchorEl,
      displayTimeBar,
      displayZStackBar,
      showMiniMap,
      showScaleBar,
      showZStackBar,
      showImageInfo,
      showZoomBar,
      showResultTable,
      showFileNavButtons,
    } = this.state;

    return (
      <div className={classes.root} ref={(el) => (this.container = el)}>
        <Popper
          open={open}
          anchorEl={anchorEl}
          placement="left-start"
          transition
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper
                style={{
                  position: "absolute",
                  top: 5,
                  right: 0,
                }}
              >
                <ToggleButton
                  classes={classes}
                  title={"Toggle Navigator"}
                  name={"showMiniMap"}
                  value={showMiniMap}
                  icon={"MapIcon"}
                  onToggle={this.onToggle}
                />
                {ome && typeof ome.physicalSizeX !== "undefined" && (
                  <ToggleButton
                    classes={classes}
                    title={"Toggle scalebar"}
                    name={"showScaleBar"}
                    value={showScaleBar}
                    icon={"faRuler"}
                    onToggle={this.onToggle}
                  />
                )}
                {displayTimeBar && (
                  <ToggleButton
                    classes={classes}
                    title={"Toggle time bar"}
                    name={"showTimeBar"}
                    value={showTimeBar}
                    icon={"faStopwatch"}
                    onToggle={this.onToggle}
                  />
                )}
                {displayZStackBar && (
                  <ToggleButton
                    classes={classes}
                    title={"Toggle Z-stack"}
                    name={"showZStackBar"}
                    value={showZStackBar}
                    icon={"faLayerGroup"}
                    onToggle={this.onToggle}
                  />
                )}
                <ToggleButton
                  classes={classes}
                  title={"Toggle image info"}
                  name={"showImageInfo"}
                  value={showImageInfo}
                  icon={"faInfo"}
                  onToggle={this.onToggle}
                />
                {!(
                  this.props.project.type.includes("HistoClassification") ||
                  this.props.project.type.includes("HistoPointCounting")
                ) && (
                  <ToggleButton
                    classes={classes}
                    title={"Toggle zoom info bar"}
                    name={"showZoomBar"}
                    value={showZoomBar}
                    icon={"LinearScaleIcon"}
                    onToggle={this.onToggle}
                  />
                )}
                <ToggleButton
                  classes={classes}
                  title={"Toggle result table"}
                  name={"showResultTable"}
                  value={showResultTable}
                  icon={"faTable"}
                  onToggle={this.onToggle}
                />
              </Paper>
            </Fade>
          )}
        </Popper>

        {this.props.splitscreenCount !== 1 && !this.props.showFullscreen && (
          <Tooltip disableInteractive title="Adjust layout">
            <IconButton
              className={
                this.props.splitscreenCount === 1
                  ? classes.toolbarButtonRootSingle
                  : classes.toolbarButtonRoot
              }
              size="small"
              onClick={(e) =>
                this.setMountedState({
                  open: !this.state.open,
                  anchorEl: e.target,
                })
              }
            >
              <ViewQuilt />
            </IconButton>
          </Tooltip>
        )}
        <div id="heatmapDiv" className={classes.heatmapContainer} />
        <canvas
          id={this.props.canvasId}
          className={classes.canvas}
          style={{
            height: showTimeBar
              ? `calc(100% - ${this.props.timeLineHeight}px)`
              : "100%",
            userSelect: "none",
          }}
          onContextMenu={(event) => event.preventDefault()}
          onMouseDown={this.mousedown}
          //onMouseMove={this.mousemove}
          onMouseEnter={this.mouseEnter}
          onMouseLeave={this.mouseLeave}
        />
        {(this.state.isLoadingTiles ||
          this.props.projectContext.isLoadingAnnotations) && (
          <LinearProgress
            style={{
              position: "fixed",
              top: 64,
              left: 0,
              right: this.props.rightSpace,
            }}
          />
        )}
        {FPS && (
          <p style={{ color: "#0673C1" }} className={classes.fps}>
            {this.state.fps + " FPS"}
          </p>
        )}
        {!this.state.initialized && (
          <CircularProgress className={classes.progress} />
        )}
        {this.props.tools[this.props.activeTool] &&
          this.props.tools[this.props.activeTool].previewRect &&
          this.props.tools[this.props.activeTool].state.preview && (
            <Resizable
              style={{ display: "inline-block" }}
              className={classes.resizableContainer}
              height={this.state.previewHeight}
              width={this.state.previewWidth}
              onResize={this.onResize}
              resizeHandles={["se"]}
            >
              <div
                style={{
                  width: this.state.previewWidth + "px",
                  height: this.state.previewHeight + "px",
                  border: "2px solid #0673C1",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginLeft: "-" + this.state.previewWidth / 2 + "px",
                  marginTop: "-" + this.state.previewHeight / 2 + "px",
                  color: "#0673C1",
                  pointerEvents: "none",
                  textAlign: "center",
                }}
              >
                <div
                  style={{
                    position: "relative",
                    left: "-4px",
                    top: "-4px",
                    width: "calc(100% + 8px)",
                    height: "calc(100% + 8px)",
                    border: "2px solid white",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      left: "-4px",
                      top: "-4px",
                      width: "calc(100% + 8px)",
                      height: "calc(100% + 8px)",
                      border: "2px solid #0673C1",
                    }}
                  ></div>
                </div>
              </div>
            </Resizable>
          )}
        {this.state.initialized &&
          showMiniMap &&
          !this.props.resultTab.getZoomLevelFixed() && (
            <MiniMap
              componentRef={(c) => (this.miniMapRef = c)}
              fileId={fileId}
              key={miniMapKey}
              pointerEvents={
                !(
                  this.canvas.style.cursor !== "default" ||
                  this.props.drawLayer.regionRois.length > 0
                )
              }
              ome={this.props.ome}
              histogramConfig={this.props.histogramConfig}
              visibleImage={this.props.tiles.getVisibleImages()}
              coloredImages={this.props.tiles.getColoredImages()}
              position={this.getPosition()}
              zoom={this.getScale()}
              canvas={this.canvas}
              canvasId={this.props.canvasId}
              onMoveTo={this.moveTo}
              onScaleOnly={this.onScaleOnly}
              getPageForChannel={this.getPageForChannel}
              chainMouseMove={this.chainMouseMove}
              zoomMouseWheel={this.miniMapZoom}
            />
          )}

        {this.state.initialized && showZoomBar && (
          <ZoomBar
            key={fileId + "ZoomBar"}
            zoom={this.getScale() / this.state.initialScale}
            changeValue={(v) => this.zoomToFactor(v)}
            clickable={
              !(
                project.type.includes("HistoClassification") ||
                project.type.includes("HistoPointCounting")
              )
            }
          />
        )}
        {this.state.initialized && showScaleBar && (
          <ScaleBar
            key={fileId + "ScaleBar"}
            pointerEvents={
              !(
                this.canvas.style.cursor !== "default" ||
                this.props.drawLayer.regionRois.length > 0
              )
            }
            zoom={this.getScale()}
            ome={this.props.ome}
            setScaleBarData={(data) => {
              if (
                scaleBarData === null ||
                scaleBarData.width !== data.width ||
                scaleBarData.label !== data.label ||
                scaleBarData.x !== data.x ||
                scaleBarData.y !== data.y
              ) {
                this.setMountedState({ scaleBarData: data });
              }
              // else if (scaleBarData.data && scaleBarData.x !== data.x) {
              //  this.setMountedState({ scaleBarData: data });
              // }
            }}
          />
        )}
        {/* {this.state.initialized && showTimeBar && false && (
          <TimeBar
            key={fileId + "TimeBar"}
            pointerEvents={
              !(
                this.canvas.style.cursor !== "default" ||
                this.props.drawLayer.regionRois.length > 0
              )
            }
            time={this.state.t}
            playing={this.state.playing}
            onPlayPause={this.onPlayPause}
            onSeek={this.onSeek}
            onChangeT={this.onChangeT}
            ome={this.props.ome}
            sr={this.state.sr}
            onChangeSr={this.onChangeSr}
            playDirection={this.state.playDirection}
            onStep={this.onStep}
          />
        )} */}
        {this.state.initialized && showTimeBar && (
          <TimeLineTool
            key={fileId + "TimeLineTool"}
            selectedLayer={this.props.selectedLayer}
            structures={this.props.structures}
            frameArray={this.props.frameArrayDict[this.props.fileId]}
            time={this.state.t}
            playing={this.state.playing}
            onPlayPause={this.onPlayPause}
            onSeek={this.onSeek}
            onChangeT={this.onChangeT}
            ome={this.props.ome}
            sr={this.state.sr}
            onChangeSr={this.onChangeSr}
            playDirection={this.state.playDirection}
            onStep={this.onStep}
            resizeTimeLine={this.props.resizeTimeLine}
            timeLineHeight={this.props.timeLineHeight}
          />
        )}
        {this.state.initialized &&
          showZStackBar &&
          this.props.ome.sizeZ > 1 && (
            <ZStackBar
              key={fileId + "ZStackBar"}
              pointerEvents={
                !(
                  this.canvas.style.cursor !== "default" ||
                  this.props.drawLayer.regionRois.length > 0
                )
              }
              z={this.state.z}
              minZ={this.state.minZ}
              maxZ={this.state.maxZ}
              playing={this.state.playingZ}
              onPlayPause={this.onPlayPauseZ}
              zsr={this.state.zsr}
              onChangeZ={this.onChangeZ}
              onChangeZValue={this.onChangeZValue}
              onChangeZSr={this.onChangeZSr}
              playDirection={this.state.playDirectionZ}
              ome={this.props.ome}
              onStep={this.onStepZ}
            />
          )}
        {this.state.initialized && showImageInfo && (
          <ImageInfo ome={this.props.ome} />
        )}
        {this.state.initialized && showResultTable && (
          <ResultTable
            structures={this.props.structures}
            selectedLayer={this.props.selectedLayer}
            roiLayers={this.props.roiLayers}
            allRoiLayers={this.props.allRoiLayers}
            fileId={this.props.fileId}
          />
        )}

        {showFileNavButtons && (
          <React.Fragment>
            <div className={classes.fileNavLeftBtn}>
              <Tooltip
                disableInteractive
                placement="top"
                followCursor
                title="Open previous file [Ctrl] + [<=]"
              >
                <span>
                  <IconButton
                    disabled={
                      this.props.fileId === this.props.project.files[0].id
                    }
                    onClick={() => {
                      this.props.openPrevFile();
                    }}
                  >
                    <KeyboardArrowLeftIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </div>

            <div className={classes.fileNavRightBtn}>
              <Tooltip
                disableInteractive
                placement="top"
                followCursor
                title="Open next file [Ctrl] + [=>]"
              >
                <span>
                  <IconButton
                    disabled={
                      this.props.fileId ===
                      this.props.project.files[
                        this.props.project.files.length - 1
                      ].id
                    }
                    onClick={this.props.openNextFile}
                  >
                    <KeyboardArrowRightIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </div>
          </React.Fragment>
        )}
      </div>
    );
  };
}

// define the component's interface
Renderer.propTypes = {
  classes: PropTypes.object.isRequired,
  project: PropTypes.object,
  tools: PropTypes.array,
  allRoiLayers: PropTypes.object,
  viewerConfig: PropTypes.object,
  structures: PropTypes.array,
  rightSpace: PropTypes.number,
  updateProject: PropTypes.func,
  setSelectedRoi: PropTypes.func,
  fsChain: PropTypes.func,
  onChangeChain: PropTypes.func,
  onSelectFile: PropTypes.func,
  // to access the componenents ref through withStyles
  componentRef: PropTypes.func,
  // meta data stuff
  id: PropTypes.string,
  ome: PropTypes.object,
  histogramConfig: PropTypes.object,
  // roi draing
  rois: PropTypes.array,
  onChangeROIs: PropTypes.func,
  // tool stuff
  activeTool: PropTypes.string,
  // view layout stuff
  //showMiniMap: PropTypes.bool,
  //showScaleBar: PropTypes.bool,
  showGallery: PropTypes.bool,
  showZStackBar: PropTypes.bool,
  showTimeBar: PropTypes.bool,
  //showImageInfo: PropTypes.bool,
  // showZoomBar: PropTypes.bool,
  //showResultTable: PropTypes.bool,
  resultTabActive: PropTypes.func,
  // roiLayers
  roiLayers: PropTypes.array,
  drawLayer: PropTypes.object,
  commentLayer: PropTypes.object,
  landmarkLayers: PropTypes.object,
  landmarkLayer: PropTypes.object,
  selectedLayer: PropTypes.number,
  opacity: PropTypes.number,
  // from withTiles
  tiles: PropTypes.object,
  dimensionsUpdated: PropTypes.bool,
  updateGlobalZ: PropTypes.func,
  updateGlobalT: PropTypes.func,
  //splitscreen stuff
  splitscreenCount: PropTypes.number,
  splitscreenFileIds: PropTypes.array,
  isChained: PropTypes.bool,
  chainListFileIds: PropTypes.object,
  isActive: PropTypes.bool,
  canvasId: PropTypes.string,
  rendererDict: PropTypes.object,
  fileId: PropTypes.string,
  projectId: PropTypes.string,
  activeFileId: PropTypes.string,
  displayTimeBar: PropTypes.bool,
  displayZStackBar: PropTypes.bool,
  loadedAnnotationsCounter: PropTypes.number,
  frameArrayDict: PropTypes.object,
  resultTab: PropTypes.object,
  persistentStorage: PropTypes.object,
  showFullscreen: PropTypes.bool,
  setChangingFile: PropTypes.func,
  //not ordered
  splitscreenIdx: PropTypes.number,
  changeHiConfig: PropTypes.func,
  onChangeTool: PropTypes.func,
  zoomROI: PropTypes.bool,
  zoomLeft: PropTypes.number,
  zoomRight: PropTypes.number,
  zoomTop: PropTypes.number,
  zoomBottom: PropTypes.number,
  tzoomROI1: PropTypes.func,
  changingFile: PropTypes.bool,
  showGridLabels: PropTypes.bool,
  showMarks: PropTypes.bool,
  selectedROI: PropTypes.object,
  showWindowTool: PropTypes.bool,
  windowToolRef: PropTypes.object,
  timeLineHeight: PropTypes.number,
  resizeTimeLine: PropTypes.func,
  openNextFile: PropTypes.func,
  openPrevFile: PropTypes.func,
  updateVisibleROIDebounced: PropTypes.func,
  projectContext: PropTypes.object,
};

export default withPersistentStorage(
  withTiles(withResultTab(withStyles(styles)(Renderer)))
);
