import React from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";

import {
  Grid,
  FormControl,
  TextField,
  FormHelperText,
  Slider,
} from "@mui/material";

const styles = {
  sliderContainer: {
    margin: 0,
    width: "100%",
  },
  input: {
    width: 55,
  },
};

class CustomRangeSlider extends React.Component {
  render() {
    const { params, classes } = this.props;
    return (
      <FormControl key={params.key} component="fieldset" fullWidth>
        <FormHelperText>{params.label}</FormHelperText>
        <Grid
          className={classes.sliderContainer}
          container
          spacing={2}
          alignItems="center"
        >
          <Grid item>
            <TextField
              className={classes.input}
              variant="standard"
              size="small"
              value={params.valueMin}
              margin="dense"
              onChange={(e) => params.onChangeMin(parseInt(e.target.value, 10))}
              onKeyDown={(e) => {
                if (e.code === "Enter") {
                  params.onKeyEnter();
                }
              }}
              inputProps={{
                step: params.step,
                min: params.min,
                max: params.max,
                type: "number",
                "aria-labelledby": "input-slider",
                role: "numberInput1",
              }}
            />
          </Grid>
          <Grid item xs>
            <Slider
              min={params.min}
              max={params.max}
              step={params.step}
              value={[params.valueMin, params.valueMax]}
              onChange={(e) => {
                params.onSliderChange(e.target.value);
              }}
              onChangeCommitted={params.onSliderchangeComitted}
            />
          </Grid>
          <Grid item>
            <TextField
              className={classes.input}
              variant="standard"
              size="small"
              value={params.valueMax}
              margin="dense"
              onChange={(e) => params.onChangeMax(parseInt(e.target.value, 10))}
              onKeyDown={(e) => {
                if (e.code === "Enter") {
                  params.onKeyEnter();
                }
              }}
              inputProps={{
                step: params.step,
                min: params.min,
                max: params.max,
                type: "number",
                "aria-labelledby": "input-slider",
                role: "numberInput2",
              }}
            />
          </Grid>
        </Grid>
      </FormControl>
    );
  }
}

CustomRangeSlider.propTypes = {
  classes: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
};

export default withStyles(styles)(CustomRangeSlider);
