import React, { Component } from "react";
import PropTypes from "prop-types";

import {
  Button,
  FormControl,
  TextField,
  LinearProgress,
  Typography,
} from "@mui/material";
import Backend from "../../../common/utils/Backend";
import { withSpinloader } from "../../../common/components/Spinloader";
import Tool from "./Tool";
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import ReplayRoundedIcon from "@mui/icons-material/ReplayRounded";
import AIModelDialog from "../../../home/dialogs/AIModelDialog";

class AITrainingTool extends Tool {
  constructor(viewer) {
    super();
    this.name = "AI Training";
    this.state = {
      preview: false,
      hideStartTraining: false,
      errorEpochs: false,
      errorAIName: false,
      epochs: "",
      openTrainExistingDialog: false,
      selectedExistingModel: "",
      selectedExistingModelSourcepath: "",
      existingAIModels: [],
      stepsProgress: "-",
      epochsProgress: "-",
      lossProgress: "-",
      meanIoUProgress: "-",
      vallossProgress: "-",
      valmeanIoUProgress: "-",
      buildingModelText: "-",
      showTrainingProgress: false,
      showOptimizationProgress: false,
    };
    this.viewer = viewer;
    // Backend.getAIModelRepository(false, (existingAIModels) => {
    //   this.state.existingAIModels = existingAIModels;
    // });
  }

  updateTrainingProgress = (line) => {
    if (line.includes("Epoch")) {
      this.state.buildingModel = false;
      this.state.showDownloadProgress = false;
      this.state.showTrainingProgress = true;
      this.state.epochsProgress = line.split("Epoch")[1];
    } else if (line.includes("creating dataset...")) {
      this.state.showDownloadProgress = false;
      this.state.hideStartTraining = true;
      this.state.buildingModelText = "Creating dataset...";
      this.state.buildingModel = true;
    } else if (line.includes("loading dataset")) {
      this.state.hideStartTraining = true;
      this.state.buildingModelText = "Creating dataset...";
      this.state.buildingModel = true;
    } else if (line.includes("start training")) {
      this.state.hideStartTraining = true;
      this.state.buildingModel = false;
      this.state.showDownloadProgress = false;
      this.state.showTrainingProgress = true;
    } else if (line.includes("loss:")) {
      this.state.buildingModel = false;
      this.state.stepsProgress = line.split("[")[0];
      this.state.lossProgress = parseFloat(
        line.split("loss:")[1].split(" ")[1],
        10
      ).toString();
      this.state.meanIoUProgress = line.split("loss:")[1].split(" ")[4];
    } else if (line.includes("training done!!!")) {
      this.state.trainingFinished = true;
      this.state.showOptimizationProgress = false;
    } else if (line.includes("training failed!!!"))
      this.state.trainingFailed = true;
    else if (line.includes("time elapsed:"))
      this.state.elapsedTime = line.substring(0, line.indexOf(".")) + "s";
    else if (line.includes("optimization progress:")) {
      this.state.modelOptimizeProgress = line
        .split("optimization progress:")[1]
        .split("%")[0];
      this.state.showOptimizationProgress = true;
    } else if (line.includes("Downloading model from:")) {
      this.state.hideStartTraining = true;
      this.state.modelDownloadProgress = 0;
      this.state.buildingModel = false;
      this.state.showDownloadProgress = true;
    } else if (line.includes("[DownloadProgress]")) {
      this.state.modelDownloadProgress = line
        .split("[DownloadProgress]")[1]
        .split("%")[0];
    } else if (line.includes("building model ...")) {
      this.state.buildingModelText = "Building model...";
    } else if (
      line.includes("No trainingdata, please annotate at least 15+ objects")
    ) {
      this.state.buildingModel = false;
      this.state.showTrainingProgress = false;
      this.state.hideStartTraining = false;
      this.state.trainingFinished = false;
      this.state.trainingFailed = false;
      this.state.epochsProgress = "";
      this.state.stepsProgress = "";
      this.state.meanIoUProgress = "";
      this.state.lossProgress = "";
      this.state.valmeanIoUProgress = "";
      this.state.vallossProgress = "";
    }
    if (line.includes("val_loss:")) {
      this.state.vallossProgress = parseFloat(
        line.split("val_loss:")[1].split(" ")[1],
        10
      ).toString();
      this.state.valmeanIoUProgress = line.split("val_loss:")[1].split(" ")[4];
    }
    this.configForm.setState(this.state);
  };

  /**
   * Update File Specific information
   * @param {Object} layer
   * @param {Object} ome
   * @param {String} fileId
   * @param {String} projectId
   * @param {Object[]} roiLayers
   */
  setLayer(obj) {
    this.id = obj.fileId;
    this.layer = obj.layer;
    this.ome = obj.ome;
    this.projectId = obj.projectId;
    this.roiLayers = obj.roiLayers;
    this.structures = obj.structures;
    this.selectedLayer = obj.selectedLayer;
    this.updateProject = obj.updateProject;

    if (obj.structures[obj.selectedLayer]) {
      this.toolLayerConfig = obj.structures[obj.selectedLayer].tools.find(
        (c) => c.name === "ai_Training"
      );
    }

    if (typeof this.toolLayerConfig !== "undefined") {
      Object.assign(this.state, this.toolLayerConfig.parameters);
    }
  }

  mouse() {}

  /**
   * AITraining for the entire project
   * @param {Component} component
   */
  calcFull(component) {
    // reset previous training stats
    this.state.trainingFinished = false;
    this.state.trainingFailed = false;
    this.state.showTrainingProgress = false;

    this.state.stepsProgress = "-";
    this.state.epochsProgress = "-";
    this.state.lossProgress = "-";
    this.state.meanIoUProgress = "-";
    this.state.vallossProgress = "-";
    this.state.valmeanIoUProgress = "-";

    if (this.state.epochs === "" || this.state.epochs.length < 1) {
      this.state.errorEpochs = true;
    } else if (this.state.errorEpochs !== "undefined")
      this.state.errorEpochs = false;
    this.configForm.setState(this.state);
    if (this.state.errorEpochs || this.state.errorAIName) return;
    component.props.spinloader.show();
    const project = this.viewer.createProjectModel("none");

    let data = {
      parameters: this.state,
      project: project,
    };
    const projectModel = this.viewer.createProjectModel("all");
    Backend.saveProject(projectModel, () => {});

    setTimeout(() => {
      Backend.aiTrainingSignalR(
        data,
        (progress) => {
          console.log(progress);
        },
        () => {
          console.log("finished!");
          component.props.spinloader.hide();
        },
        (error) => {
          component.props.spinloader.hide();
          window.openErrorDialog(error);
        }
      );
    }, 1000);
  }

  /**
   * Draw a custom cursor
   */
  drawCustomCursor() {}

  onParameterChange = (e) => {
    Object.assign(this.state, e);
  };

  setSelectedExistingModel = (value) => {
    this.state.selectedExistingModel = value;
  };

  exit() {}

  /**
   * Renders the Tool Configuration Inputs
   */
  renderConfiguration() {
    return (
      <div>
        <Typography variant="h6">{this.name}:</Typography>
        <ConfigForm
          ome={this.ome}
          toolConfig={this.toolConfig}
          state={this.state}
          onParameterChange={this.onParameterChange}
          setSelectedExistingModel={this.setSelectedExistingModel}
          structures={this.structures}
          selectedLayer={this.selectedLayer}
          onApply={(component) => {
            return this.state.preview
              ? this.calcPreview(component)
              : this.calcFull(component);
          }}
          onApplyAll={(component) => {
            return this.calcFull(component, true);
          }}
          componentRef={(c) => (this.configForm = c)}
        />
      </div>
    );
  }
}

/**
 * Configuration Component to render the parameter inputs dynamically
 */
class ConfigFormRaw extends Component {
  constructor(props) {
    super(props);
    if (props.componentRef) props.componentRef(this);
    this.state = props.state;
  }

  /**
   * Update tool state
   */
  onParameterChange(key, value, e) {
    let stateObject = {};
    stateObject[key] = value;
    setTimeout(() => this.props.onParameterChange(stateObject), 10);
    if (value !== "" && e.target.name === "epochs") {
      stateObject["errorEpochs"] = false;
    }
    this.setState(stateObject);
    this.forceUpdate();
  }

  handleClickOpen = () => {
    this.setState({ openTrainExistingDialog: true });
  };

  render() {
    let { onApply, selectedLayer } = this.props;
    return (
      <div>
        {this.props.structures[selectedLayer].label}
        {!this.state.hideStartTraining && (
          <div>
            <FormControl component="fieldset" fullWidth>
              <TextField
                name="epochs"
                margin="normal"
                required={true}
                type="number"
                size="small"
                error={this.state.errorEpochs}
                label="Epochs"
                placeholder="Enter number of epochs..."
                value={this.state.epochs}
                onChange={(e) =>
                  this.onParameterChange("epochs", e.target.value, e)
                }
                variant="outlined"
                helperText={
                  this.state.errorEpochs ? "Enter number of epochs." : " "
                }
                InputProps={{ inputProps: { min: 2, max: 10000 } }}
              />
            </FormControl>
            <AIModelDialog
              dialog="AISelectExistingModel"
              setSelectedExistingModel={(value) => {
                this.props.setSelectedExistingModel(value);
              }}
              existingAIModels={this.state.existingAIModels}
            />
            <Button
              style={{ marginTop: "5px" }}
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => onApply(this)}
              startIcon={<PlayArrowRoundedIcon />}
            >
              Start Training
            </Button>
          </div>
        )}

        {this.state.showDownloadProgress && (
          <div>
            <TextField
              style={{ marginTop: "10px", textAlign: "center", width: "100%" }}
              label="Downloading weights from existing model:"
              fullWidth
              value={this.state.modelDownloadProgress + "%"}
              InputProps={{
                readOnly: true,
                disableUnderline: true,
              }}
            />
            <LinearProgress
              variant="determinate"
              value={this.state.modelDownloadProgress}
            />
          </div>
        )}

        {this.state.buildingModel && (
          <div>
            <TextField
              style={{ marginTop: "10px", textAlign: "center", width: "100%" }}
              label="Initialization"
              fullWidth
              value={this.state.buildingModelText}
              InputProps={{
                readOnly: true,
                disableUnderline: true,
              }}
            />
            <LinearProgress />
          </div>
        )}

        {this.state.showTrainingProgress && (
          <div>
            <div>
              <TextField
                style={{ margin: "5px", marginTop: "10px" }}
                label="Epochs:"
                value={this.state.epochsProgress}
                InputProps={{
                  readOnly: true,
                  disableUnderline: true,
                }}
              />
              <TextField
                style={{ margin: "5px", marginTop: "10px" }}
                label="Steps:"
                value={this.state.stepsProgress}
                InputProps={{
                  readOnly: true,
                  disableUnderline: true,
                }}
              />
              <TextField
                style={{ margin: "5px" }}
                label="Training Loss:"
                value={this.state.lossProgress}
                InputProps={{
                  readOnly: true,
                  disableUnderline: true,
                }}
              />
              <TextField
                style={{ margin: "5px" }}
                label="Training Mean IoU:"
                value={this.state.meanIoUProgress}
                InputProps={{
                  readOnly: true,
                  disableUnderline: true,
                }}
              />
              <TextField
                style={{ margin: "5px" }}
                label="Validation Loss:"
                value={this.state.vallossProgress}
                InputProps={{
                  readOnly: true,
                  disableUnderline: true,
                }}
              />
              <TextField
                style={{ margin: "5px" }}
                label="Validation Mean IoU:"
                value={this.state.valmeanIoUProgress}
                InputProps={{
                  readOnly: true,
                  disableUnderline: true,
                }}
              />
            </div>

            {this.state.showOptimizationProgress && (
              <div>
                <TextField
                  style={{
                    marginTop: "10px",
                    textAlign: "center",
                    width: "100%",
                  }}
                  label="Optimize Deep Learning Model:"
                  fullWidth
                  value={this.state.modelOptimizeProgress + "%"}
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true,
                  }}
                />
                <LinearProgress
                  variant="determinate"
                  value={this.state.modelOptimizeProgress}
                />
              </div>
            )}

            {this.state.trainingFinished && (
              <div>
                <h4
                  style={{
                    textAlign: "center",
                    marginTop: "20px",
                    color: "green",
                  }}
                >
                  Successful
                </h4>
                <Button
                  style={{ marginTop: "5px" }}
                  fullWidth
                  tooltip="Go back to the start page"
                  variant="contained"
                  color="primary"
                  startIcon={<ReplayRoundedIcon />}
                  onClick={() => {
                    this.setState({
                      buildingModel: false,
                      showTrainingProgress: false,
                      hideStartTraining: false,
                      trainingFinished: false,
                      epochsProgress: "",
                      stepsProgress: "",
                      meanIoUProgress: "",
                      lossProgress: "",
                      valmeanIoUProgress: "",
                      vallossProgress: "",
                    });
                  }}
                >
                  Restart Training
                </Button>
              </div>
            )}
            {this.state.trainingFailed && (
              <div>
                <h4
                  style={{
                    textAlign: "center",
                    marginTop: "20px",
                    color: "green",
                  }}
                >
                  Failed
                </h4>
                <Button
                  style={{ marginTop: "5px" }}
                  fullWidth
                  tooltip="Go back to the start page"
                  variant="contained"
                  color="primary"
                  startIcon={<ReplayRoundedIcon />}
                  onClick={() => {
                    this.setState({
                      buildingModel: false,
                      showTrainingProgress: false,
                      hideStartTraining: false,
                      trainingFinished: false,
                      epochsProgress: "",
                      stepsProgress: "",
                      meanIoUProgress: "",
                      lossProgress: "",
                      valmeanIoUProgress: "",
                      vallossProgress: "",
                    });
                  }}
                >
                  Restart Training
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

ConfigFormRaw.propTypes = {
  componentRef: PropTypes.object,
  state: PropTypes.object,
  onParameterChange: PropTypes.func,
  onApply: PropTypes.func,
  selectedLayer: PropTypes.number,
  structures: PropTypes.array,
  setSelectedExistingModel: PropTypes.func,
};

const ConfigForm = withSpinloader(ConfigFormRaw);

export default AITrainingTool;
