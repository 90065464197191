import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import withStyles from "@mui/styles/withStyles";
import SpectraSideBar from "./components/SpectraSideBar";
import ELineChart from "./components/ELineChart";
import SpectraResultCharts from "./components/SpectraResultCharts";
import FileParamForm from "./components/FileParamForm";

import { Tabs, Tab } from "@mui/material";

import Backend from "../common/utils/Backend";
import { withSpinloader } from "../common/components/Spinloader";
import { withSpectraViewer } from "./contexts/SpectraViewerContext";
import SpectraToolBar from "./components/SpectraToolBar";
import SpectraLeftTableResults from "./components/SpectraLeftTableResults";
import SpectraBottomTableResults from "./components/SpectraBottomTableResults";
import LocalVerticalResizeBorder from "./components/LocalVerticalResizeBorder";
import LocalHorizontalResizeBorder from "./components/LocalHorizontalResizeBorder";

const styles = {
  outerContainer: {
    overflow: "hidden",
    position: "relative",
    width: "100%",
    height: "calc(100% - 64px)",
    background: "#EBEBEB",
    display: "grid",
  },
  borderBackground: {
    background: "#EBEBEB",
  },
  TableContainer: {
    background: "white",
    marginRight: 5,
    overflow: "hidden",
    width: "100%",
    display: "grid",
    gridTemplateRows: "auto 1fr",
  },
  PreviewContainer: {
    background: "white",
  },
  leftTableContainer: {
    overflow: "hidden",
    display: "grid",
    gridTemplateColumns: "1fr 5px",
  },
  bottomTableContainer: {
    overflow: "hidden",
    display: "grid",
    gridTemplateRows: "5px 1fr",
  },
  ToolBarContainer: {
    background: "white",
    margin: "0 5px",
  },
  SideBarContainer: {
    background: "white",
    overflow: "hidden",
    display: "grid",
    gridTemplateRows: "1fr auto",
  },
};

class SpectraViewer extends Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = {
      initialized: false,
      activeMainTab: 0,
      resultChartTypes: [],
      project: null,
      chart: null,
      datasets: [],
      fileParams: [],
      windowSize: [0, 0],
      maxScores: 0,
      xAxisData: {
        minX: 0,
        maxX: 0,
        stepX: 0.1,
        min: 0,
        max: 0,
      },
      showResultTable: false,
      leftTableWidth: 400,
      bottomTableHeight: 300,
      sideBarWidth: 550,
      mainChartDataShown: null,
      setXRangeManually: true,
      xValueMinMax: [330, 344], // default values
    };
  }

  /**
   * Initializes top level container containing all elements of the viewer.
   * Loads saved project from backend.
   */
  componentDidMount = () => {
    this._isMounted = true;
    window.addEventListener("keydown", this.keyDown);
    window.addEventListener("resize", () => this.updateDimensions());
    Backend.loadProject(
      {
        id: this.props.id,
      },
      (project) => {
        if (project.projectData) {
          project.metaData = project.projectData.metaData;
          project.name = project.projectData.name;
        } else {
          project.metaData = JSON.parse(project.metaData);
        }
        window.setNavigationbarTitle(
          `${project.name} (${project.viewerConfig.project.label})`
        );
        Backend.getCurrentUser((user) => {
          let areas = this.props.spectraViewer.areas;
          let operationSets = this.props.spectraViewer.operationSets;
          let useSavedCalcs = this.props.spectraViewer.useSavedCalcs;
          let passingCriteria = this.props.spectraViewer.passingCriteria;
          let passingCriteria_idx =
            this.props.spectraViewer.passingCriteria_idx;
          let passingCriteria_min =
            this.props.spectraViewer.passingCriteria_min;
          let passingCriteria_max =
            this.props.spectraViewer.passingCriteria_max;
          let datasets = [];
          let fileParams = [];
          let xAxisData = this.state.xAxisData;
          let setXRangeManually = true;
          let xValueMinMax = [330, 344]; // default values
          if (project.projectData) {
            let simpleParams = JSON.parse(project.projectData.simpleParams);
            useSavedCalcs = simpleParams.useSavedCalcs
              ? simpleParams.useSavedCalcs
              : true;
            areas = simpleParams.areas;
            xAxisData = simpleParams.xAxisData
              ? simpleParams.xAxisData
              : xAxisData;
            fileParams = simpleParams.fileParams ? simpleParams.fileParams : [];
            // Backwards compatibility
            datasets = simpleParams.datasets
              ? simpleParams.datasets
              : simpleParams.legendData;
            operationSets = simpleParams.operationSets
              ? simpleParams.operationSets
              : this.props.spectraViewer.operationSets;

            passingCriteria = simpleParams.passingCriteria
              ? simpleParams.passingCriteria
              : this.props.spectraViewer.passingCriteria;

            passingCriteria_idx = simpleParams.passingCriteria_idx
              ? simpleParams.passingCriteria_idx
              : this.props.spectraViewer.passingCriteria_idx;

            passingCriteria_min = simpleParams.passingCriteria_min
              ? simpleParams.passingCriteria_min
              : this.props.spectraViewer.passingCriteria_max;

            passingCriteria_max = simpleParams.passingCriteria_max
              ? simpleParams.passingCriteria_max
              : this.props.spectraViewer.passingCriteria_max;
          }
          if (fileParams.length === 0) {
            fileParams = project.files.map((file) => {
              return {
                fileName: file.fileName,
                id: file.id,
                subName: [],
                concentration: [],
              };
            });
          } else if (
            // Check for old format
            typeof fileParams[0].subName === "string" ||
            typeof fileParams[0].concentration === "number"
          ) {
            // Ensure backwards compatibility with single substance spectra
            fileParams = fileParams.map((file) => {
              // Convert to new, array-based format
              return {
                fileName: file.fileName,
                id: file.id,
                subName: file.subName
                  ? typeof file.subName === "string"
                    ? [file.subName]
                    : file.subName
                  : [],
                concentration:
                  file.concentration || file.concentration === 0
                    ? typeof file.concentration === "number"
                      ? [file.concentration]
                      : file.concentration
                    : [],
              };
            });
          }
          this.setState({
            xAxisData,
            fileParams,
            initialized: project.projectData !== null,
            areas,
            datasets,
            user: user.fullName,
            project: this.createProjectModel(project, user.fullName),
          });
          this.props.spectraViewer.setState({
            operationSets,
            useSavedCalcs,
            passingCriteria,
            passingCriteria_idx,
            passingCriteria_min,
            passingCriteria_max,
            xValueMinMax,
            setXRangeManually,
          });
        });
      }
    );
  };

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener("keydown", this.keyDown);
    window.removeEventListener("resize", () => this.updateDimensions());
    this.props.spinloader.hide();
  }

  updateDimensions = () => {
    this.setState({ windowSize: [window.innerWidth, window.innerHeight] });
    if (this.state.chart) this.state.chart.resize();
  };
  /**
   * handle shortcut
   * @param {ActionEvent} e Event when keyboard button is pressed
   */
  keyDown = (e) => {
    if (e.ctrlKey && e.key === "s") {
      this.saveFunction();
      e.preventDefault();
    }
  };

  /**
   * Gets all numbertype rating criteria from an list of ratedSpectra without duplicates.
   * @param {JSON} ratedSpectra A list of ratings, each containing criteria as keys.
   * @returns {List} All keys (= criteria) as strings without duplicates.
   */
  gatherAllCriteria = (ratedSpectra) => {
    let criteria = [];
    ratedSpectra.forEach(
      (spectrum) =>
        (criteria = criteria.concat(
          Object.keys(spectrum.rating).filter(
            // Only get criterium of type number not yet included in criteria
            (key) =>
              typeof spectrum.rating[key] === "number" &&
              criteria.indexOf(key) < 0
          )
        ))
    );
    return criteria;
  };

  /**
   * Gets data from the backend, depending on the requesttype.
   * @param {string} requestType Can be "init", "update", "pca_chosen",
   *  "calculate_pcas", "pca_evaluation", "ai_evaluation", "true_approx_scores",
   *  "predict_spectrum"
   */
  requestData = (requestType) => {
    const { project } = this.state;

    let areas = this.state.xAxisData.length
      ? this.props.spectraViewer.areas.map((area) => [
          area.coordRange[0],
          area.coordRange[1],
        ])
      : [];

    // Add substances and concetrations of spectra files
    let files = project.files;
    files = files.map((file) => {
      // Add checked status an graph color to file data
      const useAll =
        this.props.spectraViewer.rawSpectra.filter((el) => el.checked)
          .length === 0;
      for (let i = 0; i < this.props.spectraViewer.rawSpectra.length; i++) {
        if (
          this.props.spectraViewer.rawSpectra[i].id &&
          this.props.spectraViewer.rawSpectra[i].id === file.id
        ) {
          file.color = this.props.spectraViewer.rawSpectra[i].color;
          // Should no spectra be selected, all are used.
          file.checked = useAll
            ? true
            : this.props.spectraViewer.rawSpectra[i].checked;
        }
      }
      // Look for values in state and add theem to file properties
      for (let i = 0; i < this.state.fileParams.length; i++) {
        if (
          this.state.fileParams[i].id &&
          this.state.fileParams[i].id === file.id
        ) {
          file.id = this.state.fileParams[i].id;
          file.substances = this.state.fileParams[i].subName.map(
            (name, idx) => {
              return {
                name: name,
                concentration: this.state.fileParams[i].concentration[idx],
              };
            }
          );
          // Successful reading of files
          return file;
        }
      }

      return file;
    });
    let input = {
      id: project.id,
      projectType: project.type,
      files: files,
      requestType: requestType, // Definitions: see method description
      areaSet: areas,
      operations: this.props.spectraViewer.operationSets,
      useSavedCalcs: this.props.spectraViewer.useSavedCalcs,
      selectedPcaId: this.props.spectraViewer.selectedPcaId,
      selectedModel:
        this.props.spectraViewer.models[
          this.props.spectraViewer.selectedModelIdx
        ],
      selectedSpectrumId:
        this.props.spectraViewer.rawSpectra[
          this.props.spectraViewer.selectedSpectrum
        ]?.id,
      passingCriteria: {
        criterium:
          this.props.spectraViewer.passingCriteria[
            this.props.spectraViewer.passingCriteria_idx
          ],
        limits: [
          this.props.spectraViewer.passingCriteria_min,
          this.props.spectraViewer.passingCriteria_max,
        ],
      },
      spectraPredictionSettings:
        this.props.spectraViewer.spectraPredictionSettings,
      setXRangeManually: this.props.spectraViewer.setXRangeManually,
      xValueMinMax: this.props.spectraViewer.xValueMinMax,
    };
    // pca chosen simply returns to home screen
    if (input.requestType !== "pca_chosen") {
      this.props.spinloader.show();
    }

    Backend.requestSpectraData(
      input,
      (data) => {
        switch (input.requestType) {
          case "pca_chosen":
            // Only clean up pcas in backend, we're done in the frontend
            break;

          case "calculate_pcas":
            // Only extract the calculated pcas
            this.props.spectraViewer.setState({ pcas: data.pcas });
            this.props.spinloader.hide();
            break;

          case "pca_evaluation":
            // Analyse all selected spectra using the selected pca
            this.props.spectraViewer.setState({
              ratedSpectra: data.rated_spectra,
              passingCriteria: this.gatherAllCriteria(data.rated_spectra),
            });
            this.props.spinloader.hide();
            break;

          case "ai_evaluation":
            // Analyse all selected spectra using the selected ai model
            this.props.spectraViewer.setState({
              ratedSpectra: data.rated_spectra,
              passingCriteria: this.gatherAllCriteria(data.rated_spectra),
            });
            this.props.spinloader.hide();
            break;

          case "true_approx_scores":
            // Calculate pca approximation of a given spectrum for comparison purposes
            this.props.spectraViewer.setState(
              {
                focusedPca: {
                  info: data.focusedPca.info,
                  approximations: data.focusedPca.approximations,
                  scores: data.focusedPca.scores,
                },
              },

              () =>
                this.props.spectraViewer.setState(
                  {
                    currentlyShownData: null,
                    analysisResults: this.structurePcaAnalysisResults(),
                    analysisResultsLoaded: true,
                  },

                  () => this.showAnalysisResults()
                )
            );
            break;

          case "predict_spectrum":
            // Predict a new spectrum from concentration input values.
            this.showPredictionResults(data.predictedSpectra);
            this.props.spinloader.hide();
            break;

          case "init":
            // Get exisiting models
            Backend.getModelMetadata("verified_models", false, (model_data) => {
              model_data = model_data.filter((model) => {
                // Filter models to where any version is a spectra-analysis or a spectra-predict model
                if (
                  !model.versions.some(
                    (version) =>
                      version.modeltype === "spectra-analysis" ||
                      version.modeltype === "spectra-predict"
                  )
                ) {
                  return false;
                }

                // Remove any faulty versions
                model.versions = model.versions.filter(
                  (version) =>
                    version.modeltype === "spectra-analysis" ||
                    version.modeltype === "spectra-predict"
                );

                // Select the first version as default
                model.selectedVersionIdx = 0;

                // Keep only models where versions are still present
                return model.versions.length > 0;
              });

              this.props.spectraViewer.setState({ models: model_data });
            },
            (error) => {
              console.warn(error);
            });

            this.props.spectraViewer.setState(
              {
                pcas: data.pcas,
                rawSpectra: data.series,
                rawSpectraLoaded: true,
                currentlyShownData: null,
              },
              () => {
                this.showRawSpectra();
              }
            );
            break;

          case "update":
          default:
            this.setSeries(data.series, () => {
              this.props.spinloader.hide();
            });
        }
        return true;
      },
      (error) => {
        // Concatenate array of errors to single string
        window.openErrorDialog(
          error.reduce((currentValue, newValue) => {
            return `${currentValue}${newValue}\n`;
          }, "")
        );
        this.props.spinloader.hide();
        return false;
      },
      (backendStatus) => {
        if (this._isMounted) {
          this.props.spinloader.showWithProgress(backendStatus);
        }
      }
    );
  };

  structurePcaAnalysisResults = () => {
    // Build a series of objects, that may be used to accurately display individual components of the analysis.
    let series = [];
    let total =
      this.props.spectraViewer.focusedPca.scores.length +
      this.props.spectraViewer.focusedPca.approximations.length;
    let current = 1;
    // all the pca scores
    this.props.spectraViewer.focusedPca.scores.forEach((score) => {
      this.props.spinloader.showWithProgress({
        message: "Calculating series",
        progress: (current++ / total) * 100,
      });
      series.push({
        name: score.name,
        checked: score.checked ? score.checked : false,
        type: score.type ? score.type : "line",
        symbol: score.symbol ?? "none",
        data: score.data,
        character: "score",
      });
    });

    // all the pca original data, processed data, approximations, and residuals
    this.props.spectraViewer.focusedPca.approximations.forEach(
      (approximation) => {
        this.props.spinloader.showWithProgress({
          message: "Calculating series",
          progress: (current++ / total) * 100,
        });
        // Match with original data
        this.props.spectraViewer.rawSpectra.some((rawSpectrum) => {
          if (rawSpectrum.id === approximation.id) {
            series.push({
              name: `Original`,
              checked: approximation.checked ? approximation.checked : false,
              type: approximation.type ? approximation.type : "line",
              symbol: approximation.symbol ?? "none",
              data: rawSpectrum.data,
              character: "original spectrum",
            });
            return true;
          }
          return false;
        });

        // Preprocessed data
        series.push({
          name: `Preprocessed`,
          checked: approximation.checked ? approximation.checked : false,
          type: approximation.type ? approximation.type : "line",
          symbol: approximation.symbol ?? "none",
          data: approximation.original_data,
          character: "preprocessed spectrum",
        });

        // Approximated Data
        series.push({
          name: `Approximated`,
          checked: approximation.checked ? approximation.checked : false,
          type: approximation.type ? approximation.type : "line",
          symbol: approximation.symbol ?? "none",
          data: approximation.approximated_data,
          character: "approximated spectrum",
        });

        // Residual data
        series.push({
          name: `Residual`,
          checked: approximation.checked ? approximation.checked : false,
          type: approximation.type ? approximation.type : "line",
          symbol: approximation.symbol ?? "none",
          data: approximation.original_data.map((orig_data, idx) => {
            let datapoint = [
              orig_data[0],
              approximation.approximated_data[idx][1] - orig_data[1],
            ];
            return datapoint;
          }),
          character: "residual spectrum",
        });
      }
    );
    return series;
  };

  /**
   * Displays the raw spectra as loaded from backend in graph.
   * @returns {bool} Successful display of raw spectra.
   */
  showRawSpectra = () => {
    if (
      this.props.spectraViewer.rawSpectraLoaded &&
      this.props.spectraViewer.currentlyShownData !== "raw_spectra"
    ) {
      this.props.spinloader.showWithMessage("Loading datapoints...");
      setTimeout(() => {
        this.state.chart.clear();
        this.props.spectraViewer.setState({
          currentlyShownData: "raw_spectra",
        });
        this.setState({
          mainChartDataShown: "raw_spectra",
        });
        this.setSeries(this.props.spectraViewer.rawSpectra, () => {
          this.props.spinloader.hide();
        });
      }, 0);

      return true;
    } else if (this.props.spectraViewer.currentlyShownData === "raw_spectra") {
      return true;
    } else {
      return false;
    }
  };

  /**
   * Displays the analysis results as calculated in backend in graph.
   * @returns {bool} Successful display of analysis results.
   */
  showAnalysisResults = () => {
    if (
      this.props.spectraViewer.analysisResultsLoaded &&
      this.props.spectraViewer.currentlyShownData !== "analysis_results"
    ) {
      this.props.spinloader.showWithMessage("Loading datapoints...");
      setTimeout(() => {
        this.state.chart.clear();
        this.props.spectraViewer.setState({
          currentlyShownData: "analysis_results",
        });
        this.setState({
          mainChartDataShown: "analysis_results",
        });
        this.setSeries(this.props.spectraViewer.analysisResults, () => {
          this.props.spinloader.hide();
        });
      }, 0);
      return true;
    } else if (
      this.props.spectraViewer.currentlyShownData === "analysis_results"
    ) {
      return true;
    } else {
      return false;
    }
  };

  /**
   * Integrate predicted spectra alongside rawSpectra.
   * @param {Array} spectra All predicted spectra to be shown alongside the normal, rawSpectra
   */
  showPredictionResults = (spectra) => {
    // Show spectra alongside normal spectra
    let tmpSpectra = this.props.spectraViewer.rawSpectra;
    // Filter out double names
    let existingNames = [];
    spectra.forEach((spectrum) => existingNames.push(spectrum.name));
    tmpSpectra = tmpSpectra.filter(
      (spectrum) => !existingNames.some((name) => name === spectrum.name)
    );

    tmpSpectra.push(...spectra);
    this.props.spectraViewer.setState(
      {
        rawSpectraLoaded: true,
        currentlyShownData: null,
        rawSpectra: tmpSpectra,
      },
      () => this.showRawSpectra()
    );
  };

  /**
   * Sets the top-level Apache ELineChart
   * @param {ELineChart} chart
   * @returns nothing when no chart is given.
   */
  setChart = (chart) => {
    this.setState({ chart });
    if (chart === null) {
      return;
    }
    // update areas while drawing for settings content in side bar
    chart.on("brush", (params) => {
      this.props.spectraViewer.updateAreas(params.areas);
    });

    // Toggling a single spectrum
    // update checked spectras for spectra tab content in side bar
    chart.on("legendselectchanged", (params) => {
      this.validateDatasets(params);
    });

    // Selecting all spectra
    // Update checked spectras for spectra tab content in side bar
    chart.on("legendselectall", (params) => {
      this.validateDatasets(params);
    });

    // Inversing all spectra
    // Update checked spectras for spectra tab content in side bar
    chart.on("legendinverseselect", (params) => {
      this.validateDatasets(params);
    });

    //reset to show all spectra
    chart.on("restore", () => {
      this.setState({
        datasets: this.state.datasets.map((item) => {
          item.checked = true;
          return item;
        }),
      });
    });
  };

  validateDatasets = (params) => {
    let datasets = JSON.parse(JSON.stringify(this.state.datasets));
    let rawSpectra = this.props.spectraViewer.rawSpectra;
    let analysisResults = this.props.spectraViewer.analysisResults;

    for (let i = 0; i < datasets.length; i++) {
      let key = datasets[i].name;
      let checked = params.selected[key];
      // undefined inputs are defaulted to true by the echart.
      // only once specifically set, they have a boolean value.
      if (typeof checked !== "undefined") {
        datasets[i].checked = checked;
      } else {
        checked = true; // checked is used further down
        datasets[i].checked = checked;
      }

      if (this.props.spectraViewer.currentlyShownData === "raw_spectra") {
        // Update raw spectra
        rawSpectra.filter((spectrum) => spectrum.name === key)[0].checked =
          checked;
      } else if (
        this.props.spectraViewer.currentlyShownData === "analysis_results"
      ) {
        // Update analysis results
        analysisResults.filter((spectrum) => spectrum.name === key)[0].checked =
          checked;
      }
    }
    this.setState({ datasets });
    this.props.spectraViewer.setState({ rawSpectra, analysisResults });
    this.forceUpdate();
  };

  /**
   * Sets options for graph, including series as data
   * @param {array} series Datavalues for series
   * @param {function} callback Checks on success of this function. Is fed true or false.
   */
  setSeries = async (series, callback) => {
    // Graph settings
    let option = {
      animation: false,
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross",
        },
      },
      legend: {
        show: false,
        data: series.map((item) => item.name),
      },
      grid: {
        left: 20,
        right: 20,
        top: 60,
        containLabel: true,
        tooltip: {
          show: true,
        },
      },
      toolbox: {
        dataZoom: {
          show: true,
        },
        feature: {
          dataZoom: {
            yAxisIndex: "none",
          },
          brush: {
            type: ["lineX", "keep", "clear"],
          },
          restore: {},
          saveAsImage: {},
        },
        //orient: "vertical",
        itemSize: 26,
        iconStyle: {
          borderWidth: 3,
          borderColor: "rgba(0, 0, 0, 0.54)",
        },
        right: 20,
      },
      brush: {
        xAxisIndex: "all",
        brushLink: "all",
        outOfBrush: {
          colorAlpha: 0.1,
        },
      },
      xAxis: {
        type: "value",
        scale: true,
        // minInterval: 0.01,
        axisLabel: {
          show: true,
          interval: 1,
        },
      },
      yAxis: {
        type: "value",
        // minInterval: 0.01,
      },
      dataZoom: [
        {
          type: "inside",
          disabled: false,
        },
        {},
      ],
      series: series,
    };
    this.state.chart.setOption(option);

    // Update graph contents
    let success = await this.updateChartData(series);
    callback(success);
  };

  /**
   * Updates graph contents.
   * @param {Array} series Datavalues for graphs.
   * @returns {bool} Success status of data update.
   */
  updateChartData = async (series) => {
    let minX = 10000000;
    let maxX = -10000000;
    let length = 0;
    series.forEach((item) => {
      minX = Math.min(minX, item.data[0][0]);
      maxX = Math.max(maxX, item.data[item.data.length - 1][0]);
      length = Math.max(length, item.data.length);
    });

    const xAxisData = {
      minX: minX,
      maxX: maxX,
      stepX: 0.1,
      length: length,
    };

    this.setState({ datasets: series, xAxisData });
    if (series.length > 0) {
      series.forEach((dataset) => {
        if (!dataset.checked) {
          this.state.chart.dispatchAction({
            type: "legendUnSelect",
            name: dataset.name,
          });
        }
      });
    }

    if (this.props.spectraViewer.areas.length > 0) {
      this.state.chart.dispatchAction({
        type: "brush",
        areas: this.props.spectraViewer.areas,
      });
    }
    this.props.spectraViewer.setMaxScores();
    return true;
  };

  /**
   * Toggles a selected dataseries on or off,
   * influencing the maximum amount of settable scores,
   * and limiting those to be included in analysis/PCA training.
   * @param {string} name Name of the datasereies to toggle
   */
  toggleLegend = (name) => {
    this.state.chart.dispatchAction({
      type: "legendToggleSelect",
      name: name,
    });
    this.props.spectraViewer.setMaxScores();
  };

  /**
   * Toggles all dataseries on or off,
   * including them all in the analysis/PCA training.
   * @param {string} name Name of the dataseries to toggle
   */
  toggleAll = () => {
    let spectra = this.state.datasets;
    this.state.chart.dispatchAction({
      type: "legendAllSelect",
    });

    // Deactivate all spectra should all be activated
    if (spectra.every((spectrum) => spectrum.checked)) {
      this.state.chart.dispatchAction({
        type: "legendInverseSelect",
      });
    }
    this.props.spectraViewer.setMaxScores();
  };

  setSelectedRow = (row) => {
    this.setState({ selectedRow: row });
  };

  onUpdateResultChartTypes = (resultChartTypes) => {
    this.setState({ resultChartTypes });
  };

  onUpdateFileParams = (fileParams) => {
    this.setState({ fileParams });
  };

  createProjectModel = (project, user) => {
    const projectModel = {
      name: project.name,
      user: user,
      id: project.id,
      readableId: project.readableId,
      metaData: project.metaData,
      type: project.type,
      tools: project.viewerConfig.project.tools,
      job: project.viewerConfig.project.job,
      files: project.files,
    };
    return projectModel;
  };

  /**
   * Prepares the data from state for saving.
   * Saves project accordingly.
   */
  saveFunction = () => {
    if (this.state) {
      let project = this.state.project;
      project.simpleParams = JSON.stringify({
        xAxisData: this.state.xAxisData,
        useSavedCalcs: this.props.spectraViewer.useSavedCalcs,
        areas: this.props.spectraViewer.areas.map((area) => {
          // Remove refs before saving
          area.refs = [];
          return area;
        }),
        datasets: this.state.datasets,
        operationSets: this.props.spectraViewer.operationSets,
        fileParams: this.state.fileParams,
        passingCriteria: this.props.spectraViewer.passingCriteria,
        passingCriteria_idx: this.props.spectraViewer.passingCriteria_idx,
        passingCriteria_min: this.props.spectraViewer.passingCriteria_min,
        passingCriteria_max: this.props.spectraViewer.passingCriteria_max,
      });
      Backend.saveProject(project, (data) => {
        if (data.success) {
          window.showSuccessSnackbar("Project saved succesfully!");
        } else {
          window.showErrorSnackbar("Project could not be saved!");
        }
      });
    }
  };

  onChangeMetaData = (field, e) => {
    this.setState((prevState) => {
      let project = Object.assign({}, prevState.project);
      project.metaData[field] = e;
      return { project };
    });
  };

  handleMainTabChange = (event, value) => {
    this.setState({ activeMainTab: value });
  };

  toggleLeftTableContainer = () => {
    this.setState({ showResultTable: !this.state.showResultTable });
    setTimeout(() => this.updateDimensions(), 10);
  };

  render() {
    const { classes } = this.props;
    const {
      initialized,
      project,
      activeMainTab,
      showResultTable,
      leftTableWidth,
      sideBarWidth,
    } = this.state;

    return (
      <div
        className={classes.outerContainer}
        style={{
          gridTemplateColumns: "1fr 5px 45px " + sideBarWidth + "px",
        }}
      >
        <div className={classes.TableContainer}>
          {project && (
            <React.Fragment>
              {initialized || this.state.project.type === "ESREvaluation" ? (
                <React.Fragment>
                  <Tabs
                    //className={`${classes.tabsContainer} ${classes.flexRowContentHeight}`}
                    value={activeMainTab}
                    onChange={this.handleMainTabChange}
                    variant="fullWidth"
                    indicatorColor="primary"
                    textColor="primary"
                  >
                    <Tab className={classes.tab} label="Spectra Chart" />

                    {this.state.project.type !== "ESREvaluation" && (
                      <Tab className={classes.tab} label="Result Charts" />
                    )}
                  </Tabs>

                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      overflow: "hidden",
                      display: activeMainTab === 0 ? "grid" : "none",
                      gridTemplateColumns: "auto 1fr",
                    }}
                  >
                    <div
                      className={classes.leftTableContainer}
                      style={{ width: showResultTable ? leftTableWidth : 0 }}
                    >
                      <SpectraLeftTableResults
                        activeMainTab={activeMainTab}
                        leftTableWidth={this.state.leftTableWidth}
                        setParentState={(value) => this.setState(value)}
                      />
                      <div className={classes.borderBackground}>
                        <LocalVerticalResizeBorder
                          leftBorder={false}
                          targetWidth={this.state.leftTableWidth}
                          min={400}
                          resizeWidth={(width) => {
                            this.setState({
                              leftTableWidth: width,
                            });
                            this.updateDimensions();
                          }}
                        />
                      </div>
                    </div>
                    <ELineChart
                      project={project}
                      chart={this.state.chart}
                      setChart={this.setChart}
                      onSave={this.saveFunction}
                      requestData={this.requestData}
                      showRawSpectra={this.showRawSpectra}
                      showAnalysisResults={this.showAnalysisResults}
                      mainChartDataShown={this.state.mainChartDataShown}
                    />
                  </div>

                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      overflow: "hidden",
                      display:
                        activeMainTab === 1 &&
                        this.state.project.type !== "ESREvaluation"
                          ? "grid"
                          : "none",
                      gridTemplateRows: "1fr auto",
                    }}
                  >
                    <SpectraResultCharts
                      windowSize={this.state.windowSize}
                      resultChartTypes={this.state.resultChartTypes}
                      datasets={this.state.datasets}
                      onUpdateResultChartTypes={this.onUpdateResultChartTypes}
                      setCurrentlyShownData={(value) =>
                        this.props.spectraViewer.setState({
                          currentlyShownData: value,
                        })
                      }
                    />
                    <div
                      className={classes.bottomTableContainer}
                      style={{
                        height: showResultTable
                          ? this.state.bottomTableHeight
                          : 0,
                      }}
                    >
                      <div className={classes.borderBackground}>
                        <LocalHorizontalResizeBorder
                          leftBorder={false}
                          targetHeight={this.state.bottomTableHeight}
                          min={200}
                          resizeHeight={(height) => {
                            this.setState({
                              bottomTableHeight: height,
                            });
                            this.updateDimensions();
                          }}
                        />
                      </div>
                      <SpectraBottomTableResults
                        tableHeight={this.state.bottomTableHeight}
                        setParentState={(value) => this.setState(value)}
                      />
                    </div>
                  </div>
                </React.Fragment>
              ) : (
                <FileParamForm
                  project={project}
                  fileParams={this.state.fileParams}
                  onUpdateFileParams={this.onUpdateFileParams}
                  setInitialized={(value) =>
                    this.setState({ initialized: value })
                  }
                  save={this.saveFunction}
                />
              )}
            </React.Fragment>
          )}
        </div>
        <div className={classes.borderBackground}>
          <LocalVerticalResizeBorder
            leftBorder={true}
            targetWidth={this.state.sideBarWidth}
            min={400}
            resizeWidth={(width) => {
              this.setState({
                sideBarWidth: width,
              });
              this.updateDimensions();
            }}
          />
        </div>
        <div style={{ position: "relative" }}>
          <SpectraToolBar
            onSave={() => this.saveFunction()}
            showResultTable={this.state.showResultTable}
            toggleLeftTableContainer={() => this.toggleLeftTableContainer()}
          />
        </div>
        <div className={classes.SideBarContainer}>
          {project && (
            <SpectraSideBar
              project={project}
              xAxisData={this.state.xAxisData}
              chart={this.state.chart}
              datasets={this.state.datasets}
              initialized={
                this.state.project.type === "ESREvaluation" ||
                this.state.initialized
              }
              setInitialized={(value) => this.setState({ initialized: value })}
              maxScores={this.state.maxScores}
              toggleLegend={this.toggleLegend}
              toggleAll={this.toggleAll}
              onChangeMetaData={this.onChangeMetaData}
              requestData={this.requestData}
              onSave={() => this.saveFunction()}
              showRawSpectra={this.showRawSpectra}
              showAnalysisResults={this.showAnalysisResults}
              throwError={(error) => window.showErrorSnackbar(error)}
              updateChartData={this.setSeries}
            />
          )}
        </div>
      </div>
    );
  }
}

SpectraViewer.propTypes = {
  classes: PropTypes.object.isRequired,
  spectraViewer: PropTypes.object.isRequired,
  id: PropTypes.string,
  spinloader: PropTypes.object,
};

export default withRouter(
  withSpinloader(withSpectraViewer(withStyles(styles)(SpectraViewer)))
);
