import React, { Component } from "react";
import PropTypes from "prop-types";

import SketchColorPicker from "./SketchColorPicker";

import ListItemOptionsMenu from "./ListItemOptionsMenu";

import {
  TextField,
  ListItem,
  ListItemText,
  Tooltip,
  IconButton,
  Checkbox,
} from "@mui/material";

import withStyles from "@mui/styles/withStyles";

import {
  VisibilityOff,
  Visibility,
  ArrowDropDown,
  ArrowDropUp,
} from "@mui/icons-material";

// define the component's styling
const styles = () => ({
  listItem: {
    "&.Mui-selected": {
      background: "rgba(0, 0, 0, 0.2) !important",
    },
  },
  structureInput: {
    "& >div": {
      width: "100%",
    },
    "& input": {
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
    },
  },
});

class ListItemStructure extends Component {
  state = {
    optionsAnchorEl: null,
  };

  findChilds = (subType) => {
    const { structures } = this.props;
    // return all direct childs
    return structures.filter(
      (element) =>
        element.subtypeLevel === subType.subtypeLevel + 1 &&
        element.parentId === subType.id
    );
  };

  getParentIndex = (structure) => {
    // return index of parent structure
    let parentIndex = this.props.structures.findIndex(
      (element) => element.id === structure.parentId
    );
    return parentIndex;
  };

  hideSubtypes = (subType, lv) => {
    const { structures } = this.props;
    // hide subtypes if not unfolded anymore in sidebar
    if (subType.hasChild) {
      let childs = this.findChilds(subType);
      for (let i = 0; i < childs.length; i++) {
        this.hideSubtypes(childs[i], lv);
      }
    }
    // make visibility like parent
    if (subType.classificationSubtype && subType.subtypeLevel > lv) {
      let idx = this.getParentIndex(subType);
      subType.visible = structures[idx].visible;
    }
    subType.showSubtypes = false;
    subType.isUnfolded = false;
    this.props.projectContext.forceUpdate();
  };

  showSubtypes = (subType) => {
    const { structures } = this.props;
    // unfold direct subtypes of structure
    for (let structure of structures) {
      if (structure.parentId === subType.id) {
        structure.isUnfolded = true;
      }
    }
    this.props.projectContext.setState({ structures });
  };

  visibilitySubtypesOff = (subType) => {
    // set visibility of rois in viewer of subtypes to invisible
    if (subType.hasChild) {
      let childs = this.findChilds(subType);
      for (let i = 0; i < childs.length; i++) {
        this.visibilitySubtypesOff(childs[i]);
      }
    }
    subType.visible = false;
    this.props.projectContext.forceUpdate();
  };

  visibilitySubtypesOn = (subType) => {
    // set visibility of rois in viewer of subtypes to visible
    if (subType.hasChild) {
      let childs = this.findChilds(subType);
      for (let i = 0; i < childs.length; i++) {
        this.visibilitySubtypesOn(childs[i]);
      }
    }
    subType.visible = true;
    this.props.projectContext.forceUpdate();
  };

  selectStructure = (structure, index) => {
    this.props.onSelectLayer(index, () => {
      this.props.tiles.setStructure(structure);
    });
  };

  adaptRois = (str, newName) => {
    const { roiLayers, selectedLayer } = this.props;
    // change subtypename of rois
    roiLayers[selectedLayer].layer.regionRois.forEach((element) => {
      if (element.structureId === str.id) {
        element.subtypeName = newName;
      }
    });
  };

  render() {
    const {
      selectedLayer,
      roiLayers,
      classes,
      projectContext,
      onChangeLayers,
      updateViewer,
      onSelectLayer,
      // item props
      structure,
      index,
      setTextInput,
      renderLocation,
      structures,
      formData,
      allStructuresEnabled,
    } = this.props;

    return (
      <ListItem
        className={classes.listItem}
        disabled={
          renderLocation == "AI-Cockpit" &&
          !allStructuresEnabled &&
          (roiLayers[index].layer.regionRois.length == 0 ||
            structure.label == "Base ROI")
        }
        button
        key={index}
        selected={selectedLayer === index && renderLocation == "SideBarTabRois"}
        style={{
          paddingBottom: 0,
          paddingTop: 0,
        }}
        onClick={() => {
          if (renderLocation == "SideBarTabRois") {
            this.selectStructure(structure, index);
          }
        }}
      >
        {renderLocation == "AI-Cockpit" && (
          <Checkbox
            onChange={(e) => {
              this.props.updateFormDataStructures(
                structures[structure.index],
                e.currentTarget.checked
              );
            }}
            checked={
              formData.selStructures.findIndex(
                (item) => item.id === structures[structure.index].id
              ) > -1
            }
          />
        )}

        {selectedLayer === index ? (
          <Tooltip
            disableInteractive
            title={roiLayers[index].layer.regionRois.length + " objects"}
          >
            <span style={{ width: "100%", minWidth: 0 }}>
              <TextField
                variant="standard"
                style={{
                  width: "100%",
                }}
                className={classes.structureInput}
                value={structure.label === null ? "" : structure.label}
                onChange={(e) => {
                  if (
                    e.target.value.includes("_") ||
                    e.target.value.includes("/")
                  ) {
                    window.showWarningSnackbar(
                      "Do no use / and _ inside structure names."
                    );
                    return; // do not allow these signs in structure label
                  }
                  // update rois and structure
                  this.adaptRois(structure, e.target.value);
                  structure.label = e.target.value;
                  projectContext.forceUpdate();
                }}
                inputRef={setTextInput}
                InputProps={{
                  disableUnderline: true,
                }}
              />
            </span>
          </Tooltip>
        ) : (
          <Tooltip
            disableInteractive
            title={
              roiLayers[index]
                ? roiLayers[index].layer.regionRois.length + " objects"
                : ""
            }
          >
            <span style={{ width: "100%", minWidth: 0 }}>
              <ListItemText
                style={{
                  padding: "0px",
                }}
                primary={
                  <div
                    style={{
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {structure.label || "Brightfield"}
                  </div>
                }
              />
            </span>
          </Tooltip>
        )}

        {structure.hasChild && renderLocation == "SideBarTabRois" && (
          <IconButton
            onClick={() => {
              if (structure.showSubtypes === false) {
                this.showSubtypes(structure);
                structure.showSubtypes = true;
              } else {
                this.hideSubtypes(structure, structure.subtypeLevel);
                structure.isUnfolded = true;
              }
              setTimeout(() => {
                this.props.forceSidebarUpdate();
              }, 10);
            }}
            size="large"
          >
            {structure.showSubtypes ? <ArrowDropUp /> : <ArrowDropDown />}
          </IconButton>
        )}
        {renderLocation == "SideBarTabRois" && (
          <Tooltip
            disableInteractive
            title={(structure.visible ? "Hide" : "Show") + " current structure"}
          >
            <IconButton
              onClick={(e) => {
                structure.visible = !structure.visible;
                if (!structure.visible) {
                  this.visibilitySubtypesOff(structure);
                } else {
                  this.visibilitySubtypesOn(structure);
                }
                this.forceUpdate();
                onChangeLayers(roiLayers);
                e.stopPropagation();
              }}
              size="large"
            >
              {structure.visible ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </Tooltip>
        )}

        {structure.color !== -1 && (
          <SketchColorPicker
            color={structure.color}
            handleChange={(color) => {
              structure.color = color;
              // adjust roi color
              roiLayers[selectedLayer].layer.regionRois.forEach(function (
                element
              ) {
                if (element.subtypeName === "") {
                  element.color = color;
                }
              });
              updateViewer();
            }}
          />
        )}
        {!this.props.viewerConfig.project.projectProperties["HideSubMenus"] &&
          renderLocation == "SideBarTabRois" && (
            <ListItemOptionsMenu
              projectContext={projectContext}
              structure={structure}
              onSelectLayer={onSelectLayer}
            />
          )}
      </ListItem>
    );
  }
}

// define the component's interface
ListItemStructure.propTypes = {
  classes: PropTypes.object.isRequired,
  roiLayers: PropTypes.array,
  structures: PropTypes.array,
  selectedLayer: PropTypes.number,
  projectContext: PropTypes.object,
  viewerConfig: PropTypes.object,
  onChangeLayers: PropTypes.func,
  updateViewer: PropTypes.func,
  isAdmin: PropTypes.bool,
  // item specific
  structure: PropTypes.object,
  tiles: PropTypes.object,
  index: PropTypes.number,
  activeTool: PropTypes.string,
  setTextInput: PropTypes.func,
  onChangeTool: PropTypes.func,
  showGallery: PropTypes.bool,
  updateStructureChange: PropTypes.func,
  onSelectLayer: PropTypes.func,
  forceSidebarUpdate: PropTypes.func,
  renderLocation: PropTypes.string,
  updateFormDataStructures: PropTypes.func,
  formData: PropTypes.object,
  allStructuresEnabled: PropTypes.bool,
};

export default withStyles(styles)(ListItemStructure);
