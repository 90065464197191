import {
  updateDrawLayer,
  updateLayer,
  findSiblingRoiLayers,
  findSameLayer,
} from "../../utils/PolygonUtil";
import OverlapConfigForm from "./ConfigForms/OverlapConfigForm";

import {
  Typography,
  FormControl,
  FormLabel,
  Slider,
  Button,
} from "@mui/material";
import Tool from "./Tool";
import React from "react";

class RegionGrowingTool extends Tool {
  toolName = "Region Growing";
  noConfig = false;
  flag = false;
  threshold = 30;
  buffer = 0;
  removeOverlap = null;
  removeOverlapSame = null;
  tempRemoveOverlap = null;
  tempRemoveOverlapSame = null;

  setLayer(obj) {
    this.ctx = obj.ctx; //context
    this.canvas = obj.ctx.canvas; //canvas
    this.layer = obj.layer;
    this.roiLayers = obj.roiLayers;
    let layerResults = findSameLayer(obj.structures, obj.selectedLayer);
    this.selectedLayer = layerResults[0];
    this.parentLayer = layerResults[1];
    this.originalSelectedLayer = obj.selectedLayer;
    this.structures = obj.structures;
    this.drawLayer = obj.drawLayer;
    this.scale = this.ctx ? this.ctx.getTransform().a : 1;

    const project = obj.viewerConfig_project;
    if (this.removeOverlap === null) {
      this.removeOverlap = project.projectProperties["PreventOverlap"];
    }
    if (this.tempRemoveOverlap === null) {
      this.tempRemoveOverlap = project.projectProperties["PreventOverlap"];
    }
    if (this.removeOverlapSame === null) {
      this.removeOverlapSame = project.projectProperties["PreventOverlapSame"];
    }

    // set removeOverlap
    // save current settings (in tempRemoveOverlap) if structure gets changed
    if (obj.selectedLayer === 0) {
      if (!this.noConfig) {
        this.noConfig = true;
        this.tempRemoveOverlap = this.removeOverlap;
        this.removeOverlap = false;
        window.forceSidebarUpdate();
      }
    } else {
      if (this.noConfig) {
        this.noConfig = false;
        this.removeOverlap = this.tempRemoveOverlap;
        window.forceSidebarUpdate();
      }
    }
  }

  setPreviewRect() {}

  next4Edges(edge) {
    const x = edge[0];
    const y = edge[1];
    return [
      [x + 1, y],
      [x - 1, y],
      [x, y + 1],
      [x, y - 1],
    ];
  }

  growRegion = (image, x, y) => {
    let seed = [parseInt(x, 10), parseInt(y, 10)];
    // seed = [10, 10];
    const delta = parseInt(this.threshold); //delta = threshold slider
    const width = image.width;
    const height = image.height;
    const inputData = image.data;
    const outputData = new Uint8ClampedArray(new ImageData(width, height).data);
    const seedIdx = (seed[1] * width + seed[0]) * 4;
    const seedR = inputData[seedIdx];
    const seedG = inputData[seedIdx + 1];
    const seedB = inputData[seedIdx + 2];
    let edge = [seed];
    while (edge.length) {
      const newedge = [];
      for (let i = 0, ii = edge.length; i < ii; i++) {
        // As noted in the Raster source constructor, this function is provided
        // using the `lib` option. Other functions will NOT be visible unless
        // provided using the `lib` option.
        const next = this.next4Edges(edge[i]);
        for (let j = 0, jj = next.length; j < jj; j++) {
          const s = next[j][0];
          const t = next[j][1];
          if (s >= 0 && s < width && t >= 0 && t < height) {
            const ci = (t * width + s) * 4;
            const cr = inputData[ci];
            const cg = inputData[ci + 1];
            const cb = inputData[ci + 2];
            const ca = inputData[ci + 3];
            // if alpha is zero, carry on
            if (ca === 0) {
              continue;
            }
            if (
              Math.abs(seedR - cr) < delta &&
              Math.abs(seedG - cg) < delta &&
              Math.abs(seedB - cb) < delta
            ) {
              outputData[ci] = 255;
              outputData[ci + 1] = 0;
              outputData[ci + 2] = 0;
              outputData[ci + 3] = 255;
              newedge.push([s, t]);
            }
            // mark as visited
            inputData[ci + 3] = 0;
          }
        }
      }
      edge = newedge;
    }
    return { data: outputData, width: width, height: height };
  };

  getPosition = () => {
    return {
      x: -this.ctx.getTransform().e,
      y: -this.ctx.getTransform().f,
    };
  };

  getPolygonsWithOpenCV = (p) => {
    const cv = window.cv;
    let ctx = this.canvas.getContext("2d");
    let imageData = ctx.getImageData(
      0,
      0,
      this.canvas.width,
      this.canvas.height
    );

    let growResult = this.growRegion(imageData, p.x, p.y);
    let resultImageData = new ImageData(
      growResult.data,
      this.canvas.width,
      this.canvas.height
    );
    let src = cv.matFromImageData(resultImageData);

    // let dst = new cv.Mat();
    let rgbaPlanes = new cv.MatVector();
    cv.split(src, rgbaPlanes);
    let R = rgbaPlanes.get(0);
    cv.threshold(R, R, 0, 200, cv.THRESH_BINARY);
    let contours = new cv.MatVector();
    let hierarchy = new cv.Mat();

    cv.findContours(
      R,
      contours,
      hierarchy,
      cv.RETR_CCOMP,
      cv.CHAIN_APPROX_SIMPLE
    );
    let polygons = [];
    for (let i = 0; i < contours.size(); ++i) {
      let cnt = contours.get(i);
      if (cv.contourArea(cnt) < 100) continue;
      let polygon = [];
      for (let i = 0; i < cnt.rows; i++) {
        let point = new cv.Point(cnt.data32S[i * 2], cnt.data32S[i * 2 + 1]);
        polygon.push([
          (point.x + this.getPosition().x) / this.scale,
          (point.y + this.getPosition().y) / this.scale,
        ]);
      }
      polygons.push(polygon);
    }

    // cleanup
    hierarchy.delete();
    src.delete();

    return polygons;
  };

  updateDrawing = (p) => {
    this.renderer.drawVisibleImage();
    const scale = this.scale;

    // map point to image fit corresponding pixel in visible image
    p = {
      x: parseInt(p.x * scale - this.getPosition().x, 10),
      y: parseInt(p.y * scale - this.getPosition().y, 10),
    };

    let polygons = this.getPolygonsWithOpenCV(p);

    this.drawLayer.regionRois = []; //only one region will be drawn to the draw layer
    let drawRegion = {
      regions: polygons.map((polygon) => {
        return polygon;
      }),
      inverted: false,
    };
    this.drawLayer.clear = this.clear;
    updateDrawLayer(
      this.drawLayer,
      drawRegion,
      this.clear,
      this.color,
      this.subtype,
      this.name,
      this.buffer
    );
    window.forceSidebarUpdate();
  };

  onApply = () => {
    // release drawing flag
    this.flag = false;
    let drawRegion = {
      regions: [],
      inverted: false,
    };
    if (this.drawLayer.regionRois.length > 0) {
      drawRegion.regions = this.drawLayer.regionRois;

      // soll der selected layer sein
      let overlapRoiLayers = [];
      if (this.removeOverlapSame) {
        overlapRoiLayers.push(this.roiLayers[this.selectedLayer]);
      }
      if (this.removeOverlap) {
        let siblingRoiLayers = findSiblingRoiLayers(
          this.structures,
          this.selectedLayer,
          this.roiLayers
        );
        siblingRoiLayers.map((layer) => overlapRoiLayers.push(layer));
      }

      updateLayer(
        this.layer,
        drawRegion,
        this.clear,
        this.color,
        this.subtype,
        this.name,
        this.roiLayers[this.selectedLayer].tree,
        this.positionInRoiLayer,
        this.fullyLoaded,
        false,
        this.roiLayers[this.parentLayer],
        this.structures[this.originalSelectedLayer].id,
        overlapRoiLayers,
        this.clickedOnRoi
      );
      this.drawLayer.regionRois = [];
      window.forceSidebarUpdate();
    }
  };

  // triggered from Renderer, but usable from tool as well
  onKeyDown(event) {
    // enter key
    if (event.code === "Enter") {
      this.onApply();
    }
  }

  mouse(params) {
    try {
      let {
        event,
        p,
        color,
        subtype,
        name,
        positionInRoiLayer,
        fullyLoaded,
        renderer,
      } = params;
      this.color = color;
      this.subtype = subtype;
      this.name = name;
      this.positionInRoiLayer = positionInRoiLayer;
      this.fullyLoaded = fullyLoaded;
      this.renderer = renderer;
      if (
        event.type === "mouseup" ||
        (this.flag && event.type === "mouseleave")
      ) {
        this.clear = event.button === 2;
        this.point = p;
        this.updateDrawing(p);
      }
    } catch (e) {
      console.log("error:", e);
      window.showErrorSnackbar("Error, try again!");
    }
  }

  drawCustomCursor() {
    const ctx = this.ctx;
    if (this.ctx && this.point) {
      ctx.beginPath();
      ctx.globalAlpha = 1.0;
      ctx.strokeStyle = this.isBrightfield ? "#000000" : "#ffffff";
      ctx.lineWidth = 2 / this.scale;
      ctx.arc(this.point.x, this.point.y, 5 / this.scale, 0, 2 * Math.PI);
      ctx.fillStyle = "#0673C1";
      ctx.stroke();
      ctx.fill();
      ctx.closePath();
    }
  }

  exit() {}

  renderConfiguration() {
    return (
      <div style={{ margin: "12px" }}>
        {/* canvas for debugging */}
        <canvas id="outputCanvas"></canvas>
        <Typography variant="h6">{this.toolName}:</Typography>
        <FormControl component="fieldset" fullWidth>
          <FormLabel component="legend">
            {"Threshold: " + this.threshold}
          </FormLabel>
          <Slider
            min={1}
            max={50}
            value={this.threshold}
            onChange={(e, v) => {
              this.threshold = v;
              window.forceSidebarUpdate();
            }}
            onChangeCommitted={() => {
              this.updateDrawing(this.point);
            }}
            onKeyDown={(e) => this.onKeyDown(e)} //onKeyDown also triggered from Renderer
          />
        </FormControl>
        <OverlapConfigForm
          removeOverlap={this.removeOverlap}
          removeOverlapSame={this.removeOverlapSame}
          onChangeRemoveOverlap={(e) => (this.removeOverlap = e)}
          onChangeRemoveOverlapSame={(e) => (this.removeOverlapSame = e)}
        />
        <FormLabel component="legend">
          {"Buffer: " + this.buffer + " px"}
        </FormLabel>
        <Slider
          min={-10}
          max={10}
          value={this.buffer}
          onChange={(e, v) => {
            this.buffer = v;
            window.forceSidebarUpdate();
          }}
          onChangeCommitted={() => {
            this.updateDrawing(this.point);
          }}
          onKeyDown={(e) => this.onKeyDown(e)} //onKeyDown also triggered from Renderer
        />

        <Button
          disabled={this.drawLayer.regionRois.length === 0}
          style={{ marginTop: "5px" }}
          fullWidth
          variant="contained"
          color="primary"
          onClick={this.onApply}
        >
          Apply [Enter]
        </Button>
      </div>
    );
  }
}

export default RegionGrowingTool;
