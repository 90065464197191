import React, { useState } from "react";
import PropTypes from "prop-types";
import RemoveIcon from "@mui/icons-material/Remove";
import { makeStyles } from "@mui/styles";

// Define your styles.
const useStyles = makeStyles({
  colorBox: {
    display: "inline-block",
    width: 26,
    height: 26,
    margin: 7,
    cursor: "pointer",
  },
  removeIcon: {
    transition: "opacity .25s ease-in-out",
  },
});

export default function ColorBox(props) {
  const classes = useStyles();
  const { color, index, parameter, onParameterChange, selected } = props;
  const [hovered, setHovered] = useState(false);

  return (
    <div
      key={index}
      className={classes.colorBox}
      style={{
        background: color,
        boxShadow: selected
          ? "0px 0px 0px 3px #0673C1"
          : "white 0px 0px 0px 2px, black 0px 0px 0px 3px",
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={() => {
        let newColors = [...parameter];
        newColors.splice(index, 1);
        onParameterChange(newColors);
      }}
    >
      <RemoveIcon
        className={classes.removeIcon}
        style={{
          marginTop: "-2px",
          marginLeft: "1px",
          opacity: hovered ? 1 : 0,
        }}
      />
    </div>
  );
}

ColorBox.propTypes = {
  color: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  parameter: PropTypes.array.isRequired,
  onParameterChange: PropTypes.func.isRequired,
  selected: PropTypes.bool,
};
