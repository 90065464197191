import React, { Component } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import {
  MenuItem,
  Select,
  InputLabel,
  Grid,
  FormControlLabel,
  Checkbox,
  Tooltip,
  FormControl,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Backend from "../../common/utils/Backend";
import Draggable from "react-draggable";
import Paper from "@mui/material/Paper";
import AIModelDialogTable from "../components/AIModelDialogTable";
import withStyles from "@mui/styles/withStyles";
import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";

// define the component's styling
const styles = () => ({
  selectContent: {
    "& .MuiSelect-root": {
      minWidth: "200px",
      width: "auto",
    },
  },
  dialogContent: {
    overflowY: "hidden",
    minHeight: "100%",
  },
  gridContent: {
    padding: "30px",
  },
  tableDialog: {
    "& .MuiPaper-root": {
      maxWidth: "none",
    },
  },
  modelSelectionContainer: {},
  addCustomAIModelButton: {
    textAlign: "left",
    cursor: "pointer",
    margin: 15,
  },
  addCustomAIModelText: {
    fontWeight: "bold",
    position: "relative",
    marginLeft: 10,
    top: 2,
  },
  modelInformationContainer: {
    height: "100%",
  },
});

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class AIModelDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      selectedAIModels: [],
      addButtonDisabled: true,
      existingAIModels: [],
      selectedModel: null,
      selectedModelIdx: null,
      selectedModelSourcepath: undefined,
      selectedVersion: null,
      trainExistingModel: false,
      modelsInitialized: false,
    };
    this.data = [];
  }

  handleSelectedAIModels = (value) => {
    this.setState({ selectedAIModels: value });
  };

  handleAddButton = (value) => {
    this.setState({ addButtonDisabled: value });
  };

  handleClickOpen = () => {
    this.setState({ open: true });
    Backend.getModelMetadata("unverified_models", false, (result) => {
      this.data = result;
      this.setState({
        modelsInitialized: true,
      });
      this.forceUpdate();
    });
  };

  handleClickOpenExistingModels = () => {
    if (!this.state.trainExistingModel) {
      this.setState({
        open: true,
        trainExistingModel: !this.state.trainExistingModel,
      });
      this.setState({ existingAIModels: this.props.existingAIModels });
    } else
      this.setState({ trainExistingModel: !this.state.trainExistingModel });
  };

  handleSelectModel = (event) => {
    let selectedVersion = null;
    if (this.state.existingAIModels.aiModels[event.target.value - 1].versions)
      selectedVersion =
      this.state.existingAIModels.aiModels[event.target.value - 1].versions.slice(-1)[0]
          .label;
    this.setState({
      selectedModel: event.target.value,
      selectedVersion: selectedVersion,
    });
  };

  handleSelectVersion = (event) => {
    this.setState({ selectedVersion: event.target.value });
  };

  handleClose = (e) => {
    if (this.state.selectedModel == null) {
      this.setState({ trainExistingModel: false });
    }
    this.setState({ open: false });
    e.preventDefault();
    this.setState({ selectedAIModels: [], addButtonDisabled: true });
  };

  finishAddCustomAIModels = (result) => {
    console.log("finishAddCustomAIModels()", result);
    this.setState({ selectedAIModels: [], addButtonDisabled: true });
    if (result.successful) {
      window.showSuccessSnackbar(result.information);
      this.props.initAIFormData(false);
    } else window.showErrorSnackbar(result.information);
  };

  handleAdd = (e) => {
    this.setState({ open: false });
    e.preventDefault();
    this.customAIModelsAdded = 0;
    for (let i = 0; i < this.state.selectedAIModels.length; i++) {
      var selectedAiModel = this.data.filter((x) => x.verified != true)[
        this.state.selectedAIModels[i]
      ];
      var aiModel = {
        sourcepath: selectedAiModel.versions[0]
          ? selectedAiModel.versions[0].sourcepath
          : "-",
        name: selectedAiModel.name,
      };
      Backend.addCustomAIModels(
        JSON.stringify(aiModel),
        this.finishAddCustomAIModels
      );
    }
  };

  handleRemove = (e) => {
    e.preventDefault();
    window.openResponseDialog("Delete the models permanently?", (response) => {
      if (response) {
        for (let i = 0; i < this.state.selectedAIModels.length; i++) {
          Backend.deleteCustomAIModels(
            this.data[this.state.selectedAIModels[i]].name,
            () => {
              this.setState({
                selectedAIModels: [],
                addButtonDisabled: true,
              });
              this.forceUpdate();
            }
          );
          Backend.getModelMetadata("unverified_models", false, (result) => {
            this.data = result.filter((x) => x.verified != true);
            this.setState({
              modelsInitialized: true,
            });
            this.forceUpdate();
          });
        }
      }
    });
  };

  // send selected exisiting model to AITrainingTool.jsx (parent)
  handleAddExistingModel = (e) => {
    let selectedModelInformation = [
      this.state.existingAIModels.aiModels[this.state.selectedModel - 1].label,
      this.state.selectedVersion,
    ];

    Backend.getSelectedModelSourcepath(selectedModelInformation, (value) => {
      this.props.setSelectedExistingModel(value);
    });
    this.handleClose(e);
  };

  render() {
    const { classes, dialog } = this.props;
    const existingAIModels = this.state.existingAIModels;

    return (
      <div className={classes.modelSelectionContainer}>
        {dialog === "AIDataTable" && (
          <div className={classes.modelInformationContainer}>
            <div
              onClick={this.handleClickOpen}
              className={classes.addCustomAIModelButton}
            >
              <AddCircleOutlineIcon fontSize="default" />
              <span className={classes.addCustomAIModelText}>
                Add Custom AI Model
              </span>
            </div>
            <Dialog
              open={this.state.open}
              TransitionComponent={Transition}
              keepMounted
              onClose={this.handleClose}
              PaperComponent={PaperComponent}
              className={classes.tableDialog}
            >
              <DialogTitle
                style={{ cursor: "move" }}
                id="draggable-dialog-title"
              >
                Custom Trained AI Models
              </DialogTitle>
              <DialogContent className={classes.dialogContent}>
                <DialogContentText>
                  Select the previously trained AI Model
                </DialogContentText>
              </DialogContent>
              {this.state.modelsInitialized ? (
                <AIModelDialogTable
                  data={this.data}
                  rowsSelected={this.state.selectedAIModels}
                  handleSelectedAIModels={this.handleSelectedAIModels}
                  handleAddButton={this.handleAddButton}
                />
              ) : (
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    padding: "20px",
                    textAlign: "center",
                  }}
                >
                  <CircularProgress />
                </div>
              )}
              <DialogActions>
                <Button onClick={this.handleClose} color="primary">
                  Cancel
                </Button>
                <Button
                  onClick={this.handleRemove}
                  disabled={this.state.addButtonDisabled}
                  style={{
                    color: this.state.addButtonDisabled
                      ? "rgba(0, 0, 0, 0.26)"
                      : "red",
                  }}
                >
                  Remove
                </Button>
                <Button
                  onClick={this.handleAdd}
                  color="primary"
                  disabled={this.state.addButtonDisabled}
                >
                  Add
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        )}
        {dialog === "AISelectExistingModel" && (
          <div>
            <FormControl component="fieldset" fullWidth>
              <Tooltip
                disableInteractive
                title="Loads the weights of the existing model and continues training"
                placement="left"
              >
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={this.state.trainExistingModel}
                        onChange={this.handleClickOpenExistingModels}
                      />
                    }
                    label="Train existing model"
                  />
                </div>
              </Tooltip>
            </FormControl>
            <Dialog
              open={this.state.open}
              TransitionComponent={Transition}
              keepMounted
              onClose={this.handleClose}
              PaperComponent={PaperComponent}
              className={classes.tableDialog}
            >
              <DialogTitle
                style={{ cursor: "move" }}
                id="draggable-dialog-title"
              >
                AI Models
              </DialogTitle>
              <DialogContent className={classes.dialogContent}>
                <DialogContentText>
                  Select an existing model and version to initialize them for
                  further training.
                </DialogContentText>
              </DialogContent>

              <Grid container>
                <Grid item xs={6} className={classes.gridContent}>
                  <InputLabel>Select Model</InputLabel>
                  <Select
                    label="Select Model"
                    className={classes.selectContent}
                    value={this.state.selectedModel || "null"}
                    onChange={this.handleSelectModel}
                    variant="outlined"
                  >
                    <MenuItem value="null" disabled>
                      <em>Select Model ...</em>
                    </MenuItem>
                    {existingAIModels.aiModels &&
                      existingAIModels.aiModels.map((model, idx) => {
                        return (
                          <MenuItem key={idx} value={idx + 1}>
                            {model.label}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </Grid>
                <Grid item xs={6} className={classes.gridContent}>
                  <InputLabel>Select Version</InputLabel>
                  <Select
                    label="Select Version"
                    value={this.state.selectedVersion || "null"}
                    className={classes.selectContent}
                    onChange={this.handleSelectVersion}
                    disabled={this.state.selectedModel === null}
                  >
                    <MenuItem value="null" disabled>
                      <em>Select Version ...</em>
                    </MenuItem>
                    {this.state.selectedModel &&
                      existingAIModels.aiModels &&
                      existingAIModels.aiModels.length > 0 &&
                      existingAIModels.aiModels[
                        this.state.selectedModel - 1
                      ].versions.map((version, idx) => (
                        <MenuItem key={idx} value={version.label}>
                          {version.label}
                        </MenuItem>
                      ))}
                  </Select>
                </Grid>
              </Grid>

              <DialogActions>
                <Button onClick={this.handleClose} color="primary">
                  Cancel
                </Button>
                <Button
                  onClick={this.handleAddExistingModel}
                  color="primary"
                  disabled={this.state.selectedVersion === null}
                >
                  Okay
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        )}
      </div>
    );
  }
}

// define the component's interface
AIModelDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  dialog: PropTypes.string,
  handleOptionsClose: PropTypes.func,
  structures: PropTypes.array,
  formDataAICockpit: PropTypes.object,
  setAvailableModels: PropTypes.func,
  setFormDataAICockpit: PropTypes.func,
  initAIFormData: PropTypes.func,
  existingAIModels: PropTypes.array,
  setSelectedExistingModel: PropTypes.func,
};

export default withStyles(styles)(AIModelDialog);
