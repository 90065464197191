import React from "react";

import { RegionROI } from "../../utils/ROI";
import { isInside } from "../../utils/PolygonUtil";
import RBush from "rbush";

import {
  Tooltip,
  IconButton,
  Typography,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

import Tool from "./Tool";

const styles = {
  inputElement: {
    marginBottom: 8,
  },
  h6_small: {
    fontSize: "18px",
    marginTop: "8px",
  },
  gridButton: {
    marginBottom: "20px",
  },
  gridIconText: { fontSize: 18, fontWeight: "bold" },
};

class GridTool extends Tool {
  name = "GridTool";
  tileWidth = 0;
  tileHeight = 0;
  tilesToGenerate = 10;
  showGridPreview = false;

  setLayer(obj) {
    this.ome = obj.ome;
    if (
      this.ome.physicalSizeX &&
      this.ome.physicalSizeX !== 0 &&
      this.ome.physicalSizeX !== 1
    ) {
      this.sizeUnit = obj.ome.physicalSizeXUnit;
      this.pixelSize = obj.ome.physicalSizeX * 1000000;
    } else {
      this.sizeUnit = "px";
      this.pixelSize = 1;
    }

    if (this.tileWidth === 0 || this.tileHeight === 0) {
      this.tileWidth = parseInt(this.ome.sizeX / 20, 10);
      this.tileHeight = parseInt(this.ome.sizeY / 20, 10);
    }

    this.structures = obj.structures;
    this.layer = obj.layer;
    this.roiLayers = obj.roiLayers;
    this.selectedLayer = obj.selectedLayer;
    this.allRoiLayers = obj.allRoiLayers;
    this.project = obj.viewerConfig_project;
    this.projectNoViewConfig = obj.project;
    this.isHistoClassification = this.projectNoViewConfig.type.includes(
      "HistoClassification"
    );
    this.isHistoPointCounting =
      this.projectNoViewConfig.type.includes("HistoPointCounting");
  }

  findChilds = (subType) => {
    return this.structures.filter(
      (element) =>
        element.subtypeLevel === subType.subtypeLevel + 1 &&
        element.parentId === subType.id
    );
  };

  // draw preview grid
  drawCustomCursor(ctx) {
    if (!this.showGridPreview) return;
    const pixelWidth = 1 / ctx.getTransform().a;
    const tileWidth = this.tileWidth;
    const tileHeight = this.tileHeight;

    if (tileWidth < pixelWidth * 3 || tileHeight < pixelWidth * 3) return;
    ctx.beginPath();
    ctx.strokeStyle = "rgba(0,0,0,0.5)";
    ctx.lineWidth = pixelWidth;
    for (let x = 0; x < this.ome.sizeX; x += tileWidth) {
      ctx.moveTo(x, 0);
      ctx.lineTo(x, this.ome.sizeY);
    }
    for (let y = 0; y < this.ome.sizeY; y += tileHeight) {
      ctx.moveTo(0, y);
      ctx.lineTo(this.ome.sizeX, y);
    }
    ctx.stroke();
  }

  isInsideBaseROI = (regionRoi) => {
    let baseRois = this.roiLayers[0].tree
      .search(regionRoi.treeItem)
      .map((item) => item.roi);
    if (baseRois.length === 0) return false;
    for (const baseRoi of baseRois) {
      if (isInside(regionRoi, baseRoi)) return true;
    }
    return false;
  };

  // create this.tilesToGenerate random tiles on the grid with the size of this.tileWidth and this.tileHeight
  createRandomTiles = () => {
    const historyItem = [];
    const histId = this.structures[this.selectedLayer].id;

    const isSubtype =
      this.structures[this.selectedLayer].isSubtype &&
      this.structures[this.selectedLayer].classificationSubtype;
    const structureName = isSubtype
      ? this.structures[this.selectedLayer].label
      : "";
    const color = this.structures[this.selectedLayer].color;

    // Create a list of all possible tile positions
    let positions = [];
    for (let x = 0; x < this.ome.sizeX - this.tileWidth; x += this.tileWidth) {
      for (
        let y = 0;
        y < this.ome.sizeY - this.tileHeight;
        y += this.tileHeight
      ) {
        positions.push([x, y]);
      }
    }

    // Shuffle the positions array
    for (let i = positions.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [positions[i], positions[j]] = [positions[j], positions[i]];
    }

    // Clear old tiles
    for (let roi of this.layer.regionRois) {
      historyItem.push({ add: false, id: histId, roi: roi });
    }
    this.roiLayers[this.selectedLayer].layer.regionRois = [];
    this.roiLayers[this.selectedLayer].tree = new RBush();

    let foundTiles = 0;
    // Create new tiles
    for (
      let i = 0;
      foundTiles < this.tilesToGenerate && i < positions.length;
      i++
    ) {
      let regions = [];
      let c_1 = positions[i];
      regions.push(c_1);
      let c_2 = [c_1[0] + this.tileWidth, c_1[1]];
      regions.push(c_2);
      let c_3 = [c_1[0] + this.tileWidth, c_1[1] + this.tileHeight];
      regions.push(c_3);
      let c_4 = [c_1[0], c_1[1] + this.tileHeight];
      regions.push(c_4);
      let roi = new RegionROI({
        regions: regions,
        color1: color,
        subtype: isSubtype,
        name: structureName,
        structureId: this.structures[this.selectedLayer].id,
      });
      let isValidTile = true;
      if (this.selectedLayer > 0) {
        isValidTile = this.isInsideBaseROI(roi);
      }
      if (isValidTile) {
        foundTiles++;
        this.roiLayers[this.selectedLayer].layer.regionRois.push(roi);
        this.roiLayers[this.selectedLayer].tree.insert(roi.treeItem);
        historyItem.push({ add: true, id: histId, roi: roi });
      }

      if (i === positions.length - 1) {
        window.showWarningSnackbar(
          "All possible " + foundTiles + " tiles set!"
        );
      }
    }
    window.projectHistory.add(historyItem);
  };

  createGrid = (x, noGridForChilds, fromFileChange) => {
    const historyItem = [];
    const histId = this.structures[this.selectedLayer].id;
    if (typeof this.projectNoViewConfig === "undefined") return;
    // if Tobacco Analysis set BaseRoi over hole scene if not set yet
    if (
      this.projectNoViewConfig.type.includes("TobaccoAnalysis") ||
      this.projectNoViewConfig.type.includes("BrainIpsc")
    ) {
      this.selectedLayer = 0;
      if (this.roiLayers[this.selectedLayer].layer.regionRois.length !== 0) {
        return;
      }
    }
    // if fileChange and grid already exists in this file do not draw new grid
    if (
      fromFileChange &&
      this.roiLayers[this.selectedLayer].layer.regionRois.length !== 0
    ) {
      return;
    }
    // make all tiles
    let regions = [];
    let tileWidth = this.ome.sizeX / x;
    let tileHeight = this.ome.sizeY / x;

    // make tiles for each structure
    let lenChilds = this.findChilds(this.structures[this.selectedLayer]).length;
    if (noGridForChilds) {
      lenChilds = 0;
    }

    // check if new gridSize
    let tiles = this.roiLayers[this.selectedLayer].layer.regionRois.length;
    let numberTilesNewGrid = x * x;
    let newGridSize = tiles !== numberTilesNewGrid && tiles !== 0;

    for (
      let str = this.selectedLayer;
      str <= this.selectedLayer + lenChilds;
      str++
    ) {
      // reset structure if new grid with different grid size
      if (newGridSize) {
        this.resetStructure(str);
      }

      // delete all rois
      for (let roi of this.roiLayers[str].layer.regionRois) {
        historyItem.push({ add: false, id: histId, roi: roi });
      }
      this.roiLayers[str].layer.regionRois = [];
      this.roiLayers[str].tree = new RBush();

      // make x*x tiles
      for (let i = 0; i < x * x; i++) {
        regions = [];
        // make four corners of tile
        let c_1 = [(i % x) * tileWidth, Math.floor(i / x) * tileHeight];
        regions.push(c_1);
        let c_2 = [((i % x) + 1) * tileWidth, Math.floor(i / x) * tileHeight];
        regions.push(c_2);
        let c_3 = [
          ((i % x) + 1) * tileWidth,
          (Math.floor(i / x) + 1) * tileHeight,
        ];
        regions.push(c_3);
        let c_4 = [(i % x) * tileWidth, (Math.floor(i / x) + 1) * tileHeight];
        regions.push(c_4);
        let roi = new RegionROI({ regions: [regions] });
        historyItem.push({ add: true, id: histId, roi: roi });
        this.roiLayers[str].layer.regionRois.push(roi);
        this.roiLayers[str].tree.insert(roi.treeItem);
      }
    }
    window.setGridSize(x);
    window.projectHistory.add(historyItem);
    if (this.showGridPreview) {
      this.showGridPreview = false;
      window.forceSidebarUpdate();
    }
  };

  resetStructure = (str) => {
    // reset properties of structure
    this.structures[str].avgClassFrequency = 0;
    for (const key of Object.keys(this.structures[str].classFrequencies)) {
      this.structures[str].classFrequencies[key] = 0;
    }
  };

  create1mmGrid = () => {};

  exit() {}

  renderConfiguration = () => {
    return (
      <div>
        <Typography variant="h6">{this.name}:</Typography>
        {!this.isHistoClassification && !this.isHistoPointCounting &&(
          <>
            <Typography variant="h6" style={styles.h6_small}>
              Add random grid tiles:
            </Typography>
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.showGridPreview}
                  onChange={(e) => {
                    this.showGridPreview = e.currentTarget.checked;
                    window.forceSidebarUpdate();
                  }}
                />
              }
              label="Show grid preview"
            />
            <TextField
              label={"Tile width in " + this.sizeUnit}
              type="number"
              size="small"
              defaultValue={this.tileWidth * this.pixelSize}
              onChange={(e) => {
                this.tileWidth = Math.round(
                  parseFloat(e.target.value, 10) / this.pixelSize
                );
                window.forceSidebarUpdate();
              }}
              inputProps={{
                step: this.pixelSize,
              }}
              fullWidth
              style={styles.inputElement}
            />
            <TextField
              label={"Tile height in " + this.sizeUnit}
              type="number"
              size="small"
              defaultValue={this.tileWidth * this.pixelSize}
              onChange={(e) => {
                this.tileHeight = Math.round(
                  parseFloat(e.target.value, 10) / this.pixelSize
                );
                window.forceSidebarUpdate();
              }}
              inputProps={{
                step: this.pixelSize,
              }}
              fullWidth
              style={styles.inputElement}
            />
            <TextField
              label="Tiles to generate"
              type="number"
              size="small"
              fullWidth
              defaultValue={this.tilesToGenerate}
              onChange={(e) => {
                this.tilesToGenerate = parseInt(e.target.value, 10);
                window.forceSidebarUpdate();
              }}
              style={styles.inputElement}
            />
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => this.createRandomTiles()}
            >
              Set {this.tilesToGenerate} random tiles
            </Button>
          </>
        )}
        <Typography variant="h6" style={styles.h6_small}>
          Create grid:
        </Typography>

        {!this.isHistoClassification && !this.isHistoPointCounting && (
          <div>
            <Tooltip disableInteractive title="2x2 grid">
              <IconButton
                style={styles.gridButton}
                onClick={() => this.createGrid(2, true)}
                size="large"
              >
                <div style={styles.gridIconText}>2x2</div>
              </IconButton>
            </Tooltip>

            <Tooltip disableInteractive title="5x5 grid">
              <IconButton
                style={styles.gridButton}
                onClick={() => this.createGrid(5, true)}
                size="large"
              >
                <div style={styles.gridIconText}>5x5</div>
              </IconButton>
            </Tooltip>

            <Tooltip disableInteractive title="20x20 grid">
              <IconButton
                style={styles.gridButton}
                onClick={() => this.createGrid(20, true)}
                size="large"
              >
                <div style={styles.gridIconText}>20x20</div>
              </IconButton>
            </Tooltip>
          </div>
        )}

        {this.isHistoClassification && (
          <Tooltip disableInteractive title="1x1 grid">
            <IconButton onClick={() => this.createGrid(1)} size="large">
              <div style={styles.gridIconText}>(1x1)</div>
            </IconButton>
          </Tooltip>
        )}

        {this.isHistoClassification && (
          <Tooltip disableInteractive title="2x2 grid">
            <IconButton onClick={() => this.createGrid(2)} size="large">
              <div style={styles.gridIconText}>1:10 (2x2)</div>
            </IconButton>
          </Tooltip>
        )}

        {this.isHistoClassification && (
          <Tooltip disableInteractive title="5x5 grid">
            <IconButton onClick={() => this.createGrid(5)} size="large">
              <div style={styles.gridIconText}>1:4 (5x5)</div>
            </IconButton>
          </Tooltip>
        )}

        {this.isHistoPointCounting && (
          <Tooltip disableInteractive title="20 x 20 grid">
            <IconButton
              style={styles.gridButton}
              onClick={() => this.createGrid(20, true)}
              size="large"
            >
              <div style={styles.gridIconText}>20 x 20</div>
            </IconButton>
          </Tooltip>
        )}
      </div>
    );
  };
}

export default GridTool;
