import React, { Component } from "react";
import PropTypes from "prop-types";

import withStyles from "@mui/styles/withStyles";
import { IconButton, Tooltip, Checkbox, FormControlLabel } from "@mui/material";
import Backend from "../../common/utils/Backend";
import { SketchPicker } from "react-color";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartArea,
  faUndo,
  faArrowsAltH,
  faChartLine,
} from "@fortawesome/free-solid-svg-icons";
import { GetApp, Publish } from "@mui/icons-material";

import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import "./../../css/histogram.css";

const styles = {
  root: {
    overflow: "visible",
  },
  chartContainer: {
    height: 120,
    position: "relative",
    marginBottom: 0,
  },
  rangeSlider: {
    bottom: 12,
  },
  actionButton: {
    display: "inline-block",
    border: "none",
    background: "rgba(0, 0, 0, 0.2)",
    marginRight: "2px",
    height: "35px",
  },
  toggleContainer: {
    boxShadow: "none",
    display: "inline-block",
    verticalAlign: "top",
  },
  title: {
    padding: 5,
    fontSize: 14,
  },
  toolbar: {
    width: "100%",
  },
  toolButton: {
    fontSize: 18,
  },
  toolButtonRight: {
    fontSize: 18,
    float: "right",
  },
  grow: {
    flexGrow: 1,
  },
  gradientBar: {
    height: 5,
    width: "100%",
    background: "linear-gradient(90deg, black, white)",
  },
  dragIndicator: {
    color: "#757575",
    position: "absolute",
    top: 5,
    right: 5,
    cursor: "grab",
  },
  grabbing: {
    cursor: "grabbing",
  },
  color: {
    borderRadius: "2px",
  },
  channelLabel: {
    minWidth: "42px",
    lineHeight: "18px",
  },
  popover: {
    position: "absolute",
    zIndex: "2",
  },
  cover: {
    position: "fixed",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
  },
  toggleBtnContent: {
    position: "relative",
  },
};

// Histogram Scale Enum
const HistogramScale = {
  LINEAR: "linear",
  LOGARITHMIC: "logarithmic",
};

/**
 * convert hex colors to rgb values with alpha
 * @param {String} hex Hax Color Code
 * @param {Number} alpha Alpha value
 */
function hexToRGB(hex, alpha) {
  var r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
  } else {
    return "rgb(" + r + ", " + g + ", " + b + ")";
  }
}

function validateGamma(gamma) {
  return gamma > 2 ? Math.pow(gamma, 1 / 4) : gamma;
}

/**
 * Configuration of a Fluorescence Channel
 */
export class ChannelsConfig {
  constructor(omeChannels) {
    let nChannels = omeChannels.length;
    this.nChannels = nChannels;

    this.channels = [];
    for (let i = 0; i < nChannels; i++) {
      let color = omeChannels[i].color;
      if (color !== -1) {
        if (!isNaN(color)) {
          color = parseInt(color, 10);
          // handle negative color integers
          if (color < 0) color = 16777216 - color;
          // conver decimal to hex
          color = color.toString(16);
          // add leading zeros
          while (color.length < 6 || color.length % 2 !== 0) {
            color = "0" + color;
          }
          // add leading #
          color = "#" + color;
        }
      }
      let channelName =
        omeChannels[i].name === "TL Brightfield" ? "RGB" : omeChannels[i].name;

      channelName =
        channelName === ""
          ? i === 0
            ? "R"
            : i === 1
            ? "G"
            : "B"
          : channelName;
      this.channels[i] = {
        color: color,
        enabled: true,
        min: 250 * omeChannels[i].low,
        max: 250 * Math.min(omeChannels[i].high, 1),
        gamma: validateGamma(omeChannels[i].gamma),
        name: omeChannels[i].name,
        type: omeChannels[i].type,
      };
    }

    // default scaling is linear
    this.scale = HistogramScale.LOGARITHMIC;
  }
}

class Histogram extends Component {
  constructor(props) {
    super(props);

    let stateGamma = this.props.histogramConfig.channels[0].gamma;
    if (stateGamma > 2) {
      stateGamma = Math.pow(stateGamma, 1 / 4);
    }
    this._isMounted = false;
    this.state = {
      originalHistogramConfig: JSON.parse(
        JSON.stringify(props.histogramConfig)
      ),
      selectedSingleChannel: -1,
      selectedChannels: this.getEnabledChannels(),
      histograms: [],
      datasets: [],
      displayColorPicker: false,
      singleChannelMode: false,
      channelSelectMode: this.props.channelSelectMode,
      sliderMode: false,
      leftGammaValue: this.gammaFunction(0.25, stateGamma),
      rightGammaValue: this.gammaFunction(0.75, stateGamma),
      min:
        props.histogramConfig.channels[0].min > 250
          ? 0
          : props.histogramConfig.channels[0].min,
      max:
        props.histogramConfig.channels[0].max > 255
          ? 250
          : props.histogramConfig.channels[0].max,
      gamma: stateGamma,
      isBrightfield: this.isBrightfield(),
    };
    // load small images from backend and calculate histogram
    for (let c = 0; c < props.histogramConfig.nChannels; c++) {
      let img = new Image();
      img.src = Backend.renderRegion({
        id: props.id,
        page: c, // TODO calc page for complex images (timeframes and z stack)
        lv: 0,
        x: 0,
        y: 0,
        debug: false,
      });
      // wait until image is loaded succesfully
      img.onload = () => {
        // calcualte histogram
        let histograms = this.state.histograms;
        if (this.state.isBrightfield) {
          histograms[0] = this.calcHist(img, 0);
          histograms[1] = this.calcHist(img, 1);
          histograms[2] = this.calcHist(img, 2);
        } else {
          histograms[c] = this.calcHist(img, 0);
        }

        this.setMountedState({ histograms: histograms });
        if (this._isMounted) this.forceUpdate();
      };
    }
  }

  isBrightfield() {
    let channels = this.props.histogramConfig.channels;
    if (channels.length > 1) return false;
    return (
      channels[0].type === "brightfield" ||
      channels[0].name === "TL Brightfield" ||
      channels[0].name === "RGB"
    );
  }

  setMountedState = (stateObject, callback) => {
    if (this._isMounted) {
      this.setState(stateObject, callback);
    }
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    const histogramConfig = this.props.histogramConfig;
    this.drawGammaLine();

    // find all selected channels and put in channels array
    let channels = this.props.histogramConfig.channels
      .map((val, index) => (val.enabled ? index : null))
      .filter((val) => val !== null);
    if (this.state.isBrightfield) {
      channels = [-1];
    }
    let stateObject = {
      selectedChannels: channels,
      dimensions: {
        width: this.container.offsetWidth,
        height: 120,
      },
    };

    // if one channel selected, set min max state of this channel
    if (channels.length === 1 && channels[0] !== -1) {
      stateObject.min = histogramConfig.channels[channels[0]].min;
      stateObject.max = histogramConfig.channels[channels[0]].max;
      stateObject.gamma = validateGamma(
        histogramConfig.channels[channels[0]].gamma
      );
    }
    this.setMountedState(stateObject);
  }

  calcHist(img, channel) {
    // create a temporary canvas to convert our image into imagedata array
    let offScrCan = document.createElement("canvas");
    offScrCan.width = img.width;
    offScrCan.height = img.height;
    let ctx1 = offScrCan.getContext("2d");

    // draw image all over the canvas
    ctx1.drawImage(img, 0, 0, img.width, img.height);

    // read out image data array
    let imgData = ctx1.getImageData(0, 0, img.width, img.height).data;

    // step is 4 beacouse our data array is structured like this:
    // [r0,g0,b0,a0, r1,g1,b1,a1, r2,g2,b2,a2, ...]
    //let step = parseInt((img.width * img.height) / imgData.length, 10);
    //let step = 4;
    let step = parseInt(imgData.length / (img.width * img.height), 10);

    // create zeros array
    let histo = Array.from(Array(256), () => 1);
    let histo2 = Array.from(Array(256), () => 1);

    // count occuracys for each brightness value
    for (let i = 0, n = imgData.length; i < n - 1; i += step) {
      histo[imgData[i + channel]] += 1;
    }

    //interpolate histo values
    for (let i = 2; i < histo.length - 2; i++) {
      let sum = 0;
      let count = 0;
      for (let j = i - 2; j < i + 3; j++) {
        if (histo[j] > 1) {
          sum += histo[j];
          count += 1;
        }
      }
      if (count > 0) {
        histo2[i] = sum / count;
      } else {
        histo2[i] = histo[i];
      }
    }

    return histo2;
  }

  handleColorPickerClick = (e, index) => {
    this.setMountedState({
      selectedSingleChannel: index,
      displayColorPicker: !this.state.displayColorPicker,
    });
    let selectedChannels = this.state.selectedChannels;
    if (!selectedChannels.includes(index)) {
      selectedChannels.push(index);
      this.setMountedState({
        selectedChannels: selectedChannels,
      });
    }
  };

  handleColorPickerClose = () => {
    this.setMountedState({ displayColorPicker: false });
  };

  handleColorPickerChange = (color) => {
    if (this.state.selectedSingleChannel >= 0) {
      let newHistogramConfig = this.props.histogramConfig;
      newHistogramConfig.channels[this.state.selectedSingleChannel].color =
        color.hex;
      this.props.onChange(newHistogramConfig);
      this.forceUpdateWithTimeout(10);
    }
  };

  handleLogLin() {
    let newHistogramConfig = this.props.histogramConfig;
    // toggle histogram scale
    Object.assign(newHistogramConfig, {
      scale:
        newHistogramConfig.scale === HistogramScale.LINEAR
          ? HistogramScale.LOGARITHMIC
          : HistogramScale.LINEAR,
    });

    this.props.onChange(newHistogramConfig);
    this.forceUpdateWithTimeout(10);
  }

  gammaFunction(x, gamma) {
    gamma = 2 - gamma;
    let y = gamma > 1 ? gamma ** 4 : gamma;
    return x ** y;
  }

  drawHistogramBackground() {
    const data = this.data;
    if (this.backgroundCanvas) {
      const max = 2 ** this.props.bitDepth - 1;
      let visibleStep = 50;
      switch (this.props.bitDepth) {
        case 10:
          visibleStep = 250;
          break;
        case 12:
          visibleStep = 1000;
          break;
        case 14:
          visibleStep = 2500;
          break;
        case 16:
          visibleStep = 15000;
          break;
        default:
      }
      let ctx = this.backgroundCanvas.getContext("2d");
      let w = this.backgroundCanvas.width;
      let h = this.backgroundCanvas.height;
      ctx.clearRect(0, 0, w, h);
      ctx.beginPath();
      let step = (visibleStep * w) / max;
      ctx.font = "12px Arial";
      ctx.fillStyle = "#ddd";
      for (let s = step, i = 1; s < w; s += step, i++) {
        ctx.moveTo(s, 0);
        ctx.lineTo(s, h);
        ctx.fillText(i * visibleStep, s + 2, 12);
      }
      step = h / 5;
      for (let s = step; s < h; s += step) {
        ctx.moveTo(0, s);
        ctx.lineTo(w, s);
      }
      ctx.strokeStyle = "#ddd";
      ctx.closePath();
      ctx.stroke();

      let maxValues = 0;

      for (let i = 0; i < data.datasets.length; i++) {
        ctx.beginPath();
        for (let j = 0; j < data.datasets[i].data.length; j++) {
          maxValues = Math.max(maxValues, data.datasets[i].data[j]);
        }
      }

      for (let i = 0; i < data.datasets.length; i++) {
        ctx.beginPath();
        ctx.lineWidth = 2;
        ctx.strokeStyle = data.datasets[i].borderColor;
        ctx.fillStyle = data.datasets[i].backgroundColor;
        ctx.moveTo(0, h + 2);
        ctx.globalAlpha = 1.0;
        for (let j = 0; j < data.datasets[i].data.length; j++) {
          let count = data.datasets[i].data[j];
          let transformedCount = (h * count) / maxValues;
          ctx.lineTo(
            (j * w) / data.datasets[i].data.length,
            h - transformedCount
          );
        }
        ctx.lineTo(w, h + 2);
        ctx.fill("nonzero");
        ctx.closePath();
        ctx.stroke();
      }
    }
  }

  drawGammaLine() {
    if (this.gammaCanvas) {
      const width = this.gammaCanvas.width;
      const height = this.gammaCanvas.height;
      let ctx = this.gammaCanvas.getContext("2d");
      ctx.clearRect(0, 0, width, height);
      ctx.beginPath();
      ctx.moveTo(0, height);
      for (let i = 0; i < this.gammaCanvas.width; i++) {
        let x = i / this.gammaCanvas.width;
        let res = this.gammaFunction(x, this.state.gamma);
        ctx.lineTo(i, height - res * height);
        ctx.moveTo(i, height - res * height);
      }
      ctx.lineTo(this.gammaCanvas.width, 0);
      ctx.lineWidth = 3;
      ctx.strokeStyle = "#FFFFFF";
      ctx.closePath();
      ctx.stroke();
    }
  }

  // slider callback
  handleMinMaxRange(values, gamma) {
    let newHistogramConfig = this.props.histogramConfig;
    for (let selectedChannel of this.state.selectedChannels) {
      if (this.state.channelSelectMode === "all") {
        // change for all channels
        for (let channel of newHistogramConfig.channels) {
          channel.min = values[0];
          channel.max = values[1];
          channel.gamma = gamma;
        }
      } else {
        // change selected channel only
        newHistogramConfig.channels[selectedChannel].min = values[0];
        newHistogramConfig.channels[selectedChannel].max = values[1];
        newHistogramConfig.channels[selectedChannel].gamma = gamma;
      }
    }

    this.drawGammaLine();
    this.props.onChange(newHistogramConfig);
    this.forceUpdateWithTimeout(10);
  }

  forceUpdateWithTimeout = (delay) => {
    setTimeout(() => {
      if (this._isMounted) this.forceUpdate();
    }, delay);
  };

  handleGammaChange() {
    let newHistogramConfig = this.props.histogramConfig;
    for (let selectedChannel of this.state.selectedChannels) {
      // change selected channel only
      newHistogramConfig.channels[
        selectedChannel < 0 ? 0 : selectedChannel
      ].gamma = this.state.gamma;
    }
    this.props.onChange(newHistogramConfig);
    this.forceUpdateWithTimeout(10);
  }

  handleReset() {
    let isBrightfield = this.state.isBrightfield;
    let selectedChannels = this.state.selectedChannels;
    if (!isBrightfield) {
      let newHistogramConfig = this.props.histogramConfig;

      for (let i = 0; i < newHistogramConfig.channels.length; i++) {
        newHistogramConfig.channels[i].color =
          this.state.originalHistogramConfig.channels[i].color;
      }
      Object.assign(newHistogramConfig, {
        scale: HistogramScale.LOGARITHMIC,
      });
      this.scale = HistogramScale.LOGARITHMIC;
      selectedChannels = Array.from(
        Array(newHistogramConfig.channels.length),
        (x, index) => index
      );
      selectedChannels =
        selectedChannels.length > 0 && selectedChannels[0] === 0
          ? [-1]
          : selectedChannels;
    } else {
      selectedChannels = [0, 1, 2];
    }

    this.setMountedState({
      singleChannelMode: false,
      channelSelectMode: "all",
      selectedChannels: selectedChannels,
      min: 0,
      max: 2 ** this.props.bitDepth - 1,
      gamma: 1.0,
      leftGammaValue: 0.25,
      rightGammaValue: 0.75,
    });
    //this.props.onChange(newHistogramConfig);
    this.handleMinMaxRange([0, 250], 1.0);
    if (!isBrightfield) {
      this.updateChannels(selectedChannels);
      this.activateAllChannels();
    }
  }

  handleMinMaxFit() {
    const { histograms, selectedSingleChannel } = this.state;
    let min = 0;
    let max = 255;

    let histo = Array.from(Array(256), () => 0); //to do real 16 bit
    if (this.state.channelSelectMode === "all") {
      // merge all channel histograms
      for (let c = 0; c < histograms.length; c++) {
        for (let i = 0; i < histograms[c].length; i++) {
          histo[i] += histograms[c][i];
        }
      }
    } else {
      // use histogram of selected channel
      histo = histograms[selectedSingleChannel];
    }

    if (histo) {
      // accumulate all values
      let sum = histo.reduce((total, val) => total + val);

      // find left statistical border
      let leftSide = 0;
      for (min = 0; min < histo.length; min++) {
        leftSide += histo[min] / sum;
        if (leftSide > 0.0001) {
          break;
        }
      }

      // find right statistical border
      let rightSide = 0;
      for (max = 255; max >= 0; max--) {
        rightSide += histo[max] / sum;
        if (rightSide > 0.0001) {
          break;
        }
      }

      // apply calculated range
      this.handleMinMaxRange([min, max], this.state.gamma);
      this.setMountedState({
        min: min,
        max: max,
      });
    }
  }

  handleBestFit() {
    const { histograms, selectedSingleChannel } = this.state;
    let min = 0;
    let max = 255;

    let histo = Array.from(Array(256), () => 0);
    if (this.state.channelSelectMode === "all") {
      // merge all channel histograms
      for (let c = 0; c < histograms.length; c++) {
        for (let i = 0; i < histograms[c].length; i++) {
          histo[i] += histograms[c][i];
        }
      }
    } else {
      // use histogram of selected channel
      histo = histograms[selectedSingleChannel];
    }

    // accumulate all values
    let sum = histo.reduce((total, val) => total + val);

    // find left statistical border
    let leftSide = 0;
    for (min = 0; min < histo.length; min++) {
      leftSide += histo[min] / sum;
      if (leftSide > 0.0005) {
        break;
      }
    }

    // find right statistical border
    let rightSide = 0;
    for (max = 255; max >= 0; max--) {
      rightSide += histo[max] / sum;
      if (rightSide > 0.0005) {
        break;
      }
    }

    // apply calculated range
    this.handleMinMaxRange([min, max], this.state.gamma);
    this.setMountedState({
      min: min,
      max: max,
    });
  }

  activateAllChannels = () => {
    let selectedChannels = Array.from(
      Array(this.props.histogramConfig.nChannels),
      (x, index) => index
    );
    let validGamma = validateGamma(
      this.props.histogramConfig.channels[selectedChannels[0]].gamma
    );
    this.setMountedState({
      singleChannelMode: false,
      selectedSingleChannel: selectedChannels[0],
      selectedChannels: selectedChannels,
      channelSelectMode: "all",
      min:
        this.props.histogramConfig.channels[selectedChannels[0]].min > 250
          ? 0
          : this.props.histogramConfig.channels[selectedChannels[0]].min,
      max:
        this.props.histogramConfig.channels[selectedChannels[0]].max > 255
          ? 250
          : this.props.histogramConfig.channels[selectedChannels[0]].max,
      gamma: validGamma,
      leftGammaValue: this.gammaFunction(0.25, validGamma),
      rightGammaValue: this.gammaFunction(0.75, validGamma),
    });
    this.forceUpdateWithTimeout(10);
    this.props.changeChannelSelectMode("all");
    this.updateChannels(selectedChannels);
  };

  changeChannelSelectMode = (e, value) => {
    value = value ? "single" : "all";
    let selectedChannels = this.state.selectedChannels;
    if (value !== "all") {
      selectedChannels = [Math.min.apply(Math, this.state.selectedChannels)];
    }
    let validGamma = validateGamma(
      this.props.histogramConfig.channels[selectedChannels[0]].gamma
    );
    this.setMountedState({
      singleChannelMode: value === "single",
      selectedSingleChannel: selectedChannels[0],
      selectedChannels: selectedChannels,
      channelSelectMode: value,
      min:
        this.props.histogramConfig.channels[selectedChannels[0]].min > 250
          ? 0
          : this.props.histogramConfig.channels[selectedChannels[0]].min,
      max:
        this.props.histogramConfig.channels[selectedChannels[0]].max > 255
          ? 250
          : this.props.histogramConfig.channels[selectedChannels[0]].max,
      gamma: validGamma,
      leftGammaValue: this.gammaFunction(0.25, validGamma),
      rightGammaValue: this.gammaFunction(0.75, validGamma),
    });
    this.props.changeChannelSelectMode(value);
    this.updateChannels(selectedChannels);
  };

  changeChannel = (value) => {
    //prevent deselecting all
    if (value.length === 0) return;

    if (this.state.channelSelectMode === "single") {
      value = value.filter((v) => v !== this.state.selectedChannels[0]);
    }
    let validGamma = validateGamma(
      this.props.histogramConfig.channels[value[0]].gamma
    );
    this.setMountedState({
      selectedSingleChannel: value[0],
      selectedChannels: value,
      min:
        this.props.histogramConfig.channels[value[0]].min > 250
          ? 0
          : this.props.histogramConfig.channels[value[0]].min,
      max:
        this.props.histogramConfig.channels[value[0]].max > 255
          ? 250
          : this.props.histogramConfig.channels[value[0]].max,
      gamma: validGamma,
      leftGammaValue: this.gammaFunction(0.25, validGamma),
      rightGammaValue: this.gammaFunction(0.75, validGamma),
    });

    this.updateChannels(value);
  };

  /**
   * Updates Channel Comfiguration in histogram like colors, etc.
   * @param {Number[]} selectedChannels Selected Channel Indices
   */
  updateChannels(selectedChannels) {
    if (selectedChannels.length > 0 && selectedChannels[0] === -1) {
      selectedChannels = [0];
    }
    // hide or show channel layer
    let newHistogramConfig = this.props.histogramConfig;
    for (let i = 0; i < newHistogramConfig.channels.length; i++) {
      newHistogramConfig.channels[i].enabled = selectedChannels.includes(i)
        ? true
        : false;
    }
    this.props.onChange(newHistogramConfig);
    this.forceUpdateWithTimeout(10);
  }

  getEnabledChannels() {
    let selectedChannels = [];
    for (let i = 0; i < this.props.histogramConfig.length; i++) {
      if (this.props.histogramConfig.channels[i].enabled) {
        selectedChannels.push(i);
      }
    }
    return selectedChannels.length > 0 ? selectedChannels : [-1];
  }

  updateRange(e) {
    if (!this.state.lastX) {
      this.setMountedState({ lastX: e.clientX });
      return;
    }
    if (this.state.middleBarActive) {
      let deltaX =
        (255 * (e.clientX - this.state.lastX)) / this.state.dimensions.width;
      let min = this.state.min;
      let max = this.state.max;
      if (deltaX < 0) {
        let new_min = Math.max(0, min + deltaX);
        deltaX = min > 0 ? new_min - min : 0;
      } else {
        let new_max = Math.min(this.state.dimensions.width, max + deltaX);
        deltaX = new_max < 255 ? new_max - max : 255 - max;
      }
      min = Math.max(0, min + deltaX);
      max = Math.min(255, max + deltaX);
      this.setMountedState({
        min: min,
        max: max,
        lastX: e.clientX,
      });
    }
  }

  renderHistogram() {
    const { histogramConfig } = this.props;
    const { histograms, dimensions } = this.state;
    const data = this.data;

    if (data.datasets.length === 0) {
      data.datasets = this.state.datasets;
    }
    if (data.datasets.length === 0) {
      return null;
    }
    if (histogramConfig.scale === HistogramScale.LOGARITHMIC) {
      data.datasets = data.datasets.map((dataset) => {
        dataset.data = dataset.data.map((p) => 10 * Math.log(p));
        return dataset;
      });
    }

    if (histograms.length === 0) {
      return (
        <div
          height={this.state.dimensions.height}
          width={this.state.dimensions.width}
        ></div>
      );
    }
    return (
      <div
        onDragStart={(e) => {
          e.preventDefault();
        }}
        onMouseMove={(e) => {
          if (e.buttons === 1) {
            this.updateRange(e);
          } else {
            if (
              this.state.leftBarActive ||
              this.state.middleBarActive ||
              this.state.rightBarActive
            ) {
              this.setMountedState({
                middleBarActive: false,
              });
            }
          }
        }}
        onMouseUp={() => {
          this.handleMinMaxRange(
            [this.state.min, this.state.max],
            this.state.gamma
          );
        }}
      >
        {dimensions && (
          <React.Fragment>
            <canvas
              width={dimensions.width}
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                background: "#aaa",
              }}
              ref={(c) => {
                this.backgroundCanvas = c;
                this.drawHistogramBackground();
              }}
            />
            <div
              style={{
                position: "absolute",
                top: 0,
                left: (dimensions.width / 255) * this.state.min + "px",
                right: (dimensions.width / 255) * (255 - this.state.max) + "px",
                bottom: 0,
                cursor: "all-scroll",
              }}
              onMouseDown={(e) => {
                this.setMountedState({
                  middleBarActive: true,
                  lastX: e.clientX,
                });
              }}
            >
              {/* Line */}
              <canvas
                style={{ width: "100%", height: "100%" }}
                ref={(c) => {
                  this.gammaCanvas = c;
                  this.drawGammaLine();
                }}
              />
              <div className="slider-vertical-outer" style={{ left: "25%" }}>
                <input
                  ref={(c) => (this.leftGamma = c)}
                  value={this.state.leftGammaValue}
                  onMouseDown={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={() => {
                    let x = 0.25;
                    let res = this.leftGamma.value;
                    let gamma = Math.log(res) / Math.log(x);
                    if (gamma > 1) {
                      gamma = gamma ** 0.25;
                    }
                    gamma = 2 - gamma;
                    this.setMountedState({
                      gamma: gamma,
                      leftGammaValue: res,
                      rightGammaValue: this.gammaFunction(0.75, gamma),
                    });
                  }}
                  onMouseUp={() => {
                    this.handleGammaChange();
                    this.leftGamma.blur();
                  }}
                  type="range"
                  min="0.01"
                  max="0.99"
                  step="0.01"
                  className="slider-vertical"
                />
              </div>
              <div className="slider-vertical-outer" style={{ left: "75%" }}>
                <input
                  ref={(c) => (this.rightGamma = c)}
                  value={this.state.rightGammaValue}
                  onMouseDown={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={() => {
                    let x = 0.75;
                    let res = this.rightGamma.value;
                    let gamma = Math.log(res) / Math.log(x);
                    if (gamma > 1) {
                      gamma = gamma ** 0.25;
                    }
                    gamma = 2 - gamma;
                    if (gamma > 0) {
                      this.setMountedState({
                        gamma: gamma,
                        leftGammaValue: this.gammaFunction(0.25, gamma),
                        rightGammaValue: res,
                      });
                    }
                  }}
                  onMouseUp={() => {
                    this.handleGammaChange();
                    this.rightGamma.blur();
                  }}
                  type="range"
                  min="0.01"
                  max="0.99"
                  step="0.01"
                  className="slider-vertical"
                />
              </div>

              <div
                className="hover-label"
                style={{
                  position: "absolute",
                  left: "0",
                  top: "0",
                  padding: "0 5px",
                  fontSize: "10px",
                  background: "#555",
                  color: "white",
                }}
              >
                Min:{" "}
                {Math.round(
                  (this.state.min * (2 ** this.props.bitDepth - 1)) / 255
                )}
              </div>

              <div
                className="hover-label"
                style={{
                  position: "absolute",
                  right: "0",
                  top: "0",
                  padding: "0 5px",
                  fontSize: "10px",
                  background: "#555",
                  color: "white",
                }}
              >
                Max:{" "}
                {Math.round(
                  (this.state.max * (2 ** this.props.bitDepth - 1)) / 255
                )}
              </div>
            </div>

            <div
              style={{
                position: "absolute",
                left: "0",
                top: "0",
                borderRight: "2px solid black",
                height: "100%",
                width:
                  (this.state.dimensions.width / 255) * this.state.min + "px",
                background: "rgba(0,0,0,0.5)",
              }}
            ></div>

            <div
              style={{
                position: "absolute",
                right: "0",
                top: "0",
                borderLeft: "2px solid black",
                height: "100%",
                width:
                  (this.state.dimensions.width / 255) * (255 - this.state.max) +
                  "px",
                background: "rgba(0,0,0,0.5)",
              }}
            ></div>

            <div
              className="hover-label"
              style={{
                position: "absolute",
                right: "0",
                bottom: "0",
                padding: "0 5px",
                fontSize: "10px",
                background: "#555",
                color: "white",
              }}
            >
              Gamma:{" "}
              {(this.state.gamma < 1
                ? (2 - this.state.gamma) ** 4
                : 2 - this.state.gamma
              ).toFixed(2, 10)}
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }

  /**
   * Renders Color Picker Popup Button to choose FL channel color
   */
  renderColorPicker() {
    return (
      <div>
        {this.state.displayColorPicker ? (
          <div style={styles.popover}>
            <div style={styles.cover} onClick={this.handleColorPickerClose} />
            <SketchPicker
              width={this.state.dimensions.width - 20}
              color={
                this.props.histogramConfig.channels[
                  this.state.selectedSingleChannel
                ].color
              }
              onChange={this.handleColorPickerChange}
            />
          </div>
        ) : null}
      </div>
    );
  }

  onExportHistogramParameters = () => {
    const strData = this.props.projectContext.getProjectStringInfos();
    const downloadName =
      strData.name +
      "_" +
      strData.date +
      "_" +
      this.props.projectContext.user +
      ".hishsa";

    let dataStr =
      "data:text/json;charset=utf-8," +
      encodeURIComponent(JSON.stringify(this.props.histogramConfig));
    let dlAnchorElem = document.createElement("a");
    dlAnchorElem.setAttribute("href", dataStr);
    dlAnchorElem.setAttribute("download", downloadName);
    dlAnchorElem.click();
    dlAnchorElem.remove();
  };

  onImportHistogramParameters = (e) => {
    let files = e.target.files;
    if (files.length <= 0) return false;

    let fr = new FileReader();
    fr.onload = (e) => {
      try {
        let selectedCs = [];
        let loadedConfig = JSON.parse(e.target.result);
        if (
          this.props.histogramConfig.channels.length !==
          loadedConfig.channels.length
        ) {
          window.showErrorSnackbar("Not compatible with this histogram!");
          return;
        }
        let newHistogramConfig = this.props.histogramConfig;
        let stateToSet = true;
        let newStateObject = {};
        for (let i = 0; i < loadedConfig.channels.length; i++) {
          let c = loadedConfig.channels[i];
          if (c.enabled) {
            selectedCs.push(i);
            if (stateToSet) {
              let validGamma = validateGamma(c.gamma);
              newStateObject = {
                min: c.min,
                max: c.max,
                gamma: validGamma,
                leftGammaValue: this.gammaFunction(0.25, validGamma),
                rightGammaValue: this.gammaFunction(0.75, validGamma),
              };
              stateToSet = false;
            }
          }
        }
        if (this.state.isBrightfield) selectedCs = [-1];

        newStateObject.selectedChannels = selectedCs;
        Object.assign(newHistogramConfig, {
          scale: loadedConfig.scale,
        });

        this.setMountedState(newStateObject);
        newHistogramConfig.channels = loadedConfig.channels;

        this.props.onChange(newHistogramConfig);
        this.forceUpdateWithTimeout(10);
      } catch (e) {
        console.log("import errror:", e);
        window.showErrorSnackbar("File not supported!");
      }
    };
    fr.readAsText(files.item(0));
  };

  initData() {
    const { histogramConfig } = this.props;
    const { histograms, selectedChannels } = this.state;

    let datasets = [];
    let channelsArray = histogramConfig.channels;
    let isBrightfield =
      (histograms.length === 0 || histograms.length === 3) &&
      histogramConfig.channels.length === 1;
    if (isBrightfield) {
      channelsArray = [
        {
          enabled: true,
          name: "R",
          color: "#ff0000",
        },
        {
          enabled: true,
          name: "G",
          color: "#00ff00",
        },
        {
          enabled: true,
          name: "B",
          color: "#0000ff",
        },
      ];
    }
    if (selectedChannels.includes(-1)) {
      // display all channels
      for (let c = 0; c < channelsArray.length; c++) {
        // skip disabled channels
        if (!channelsArray[c].enabled || !histograms[c]) continue;

        // push channel to dataset
        datasets.push({
          label: channelsArray[c].name,
          backgroundColor:
            channelsArray[c].color !== -1
              ? hexToRGB(channelsArray[c].color, 0.2)
              : hexToRGB("#000000", 0.2),
          borderColor:
            channelsArray[c].color !== -1 ? channelsArray[c].color : "#000000",
          borderWidth: 1,
          data: histograms[c],
        });
      }
    } else {
      for (let selectedChannel of selectedChannels) {
        if (typeof histograms[selectedChannel] === "undefined") continue;
        // dispaly single selected channel
        datasets.push({
          label: channelsArray[selectedChannel].name,
          backgroundColor:
            channelsArray[selectedChannel].color !== -1
              ? hexToRGB(channelsArray[selectedChannel].color, 0.2)
              : hexToRGB("#000000", 0.2),
          borderColor:
            channelsArray[selectedChannel].color !== -1
              ? channelsArray[selectedChannel].color
              : "#000000",
          borderWidth: 1,
          data: histograms[selectedChannel],
        });
      }
    }
    this.data = {
      labels: Array.from(Array(256), (x, index) => index),
      datasets: datasets,
    };
  }

  render() {
    const { histogramConfig, classes, projectType } = this.props;
    const { dimensions, isBrightfield } = this.state;

    this.initData();

    return (
      <div className={classes.root} ref={(el) => (this.container = el)}>
        <div className={classes.toolbar}>
          <Tooltip disableInteractive title="Reset">
            <IconButton
              style={{
                float: "right",
                marginTop:
                  projectType.includes("HistoPointCounting") ||
                  projectType.includes("HistoClassification")
                    ? "-34px"
                    : "0px",
              }}
              className={classes.toolButtonRight}
              onClick={() => this.handleReset()}
              size="large"
            >
              <FontAwesomeIcon icon={faUndo} />
            </IconButton>
          </Tooltip>
          {!(
            projectType.includes("HistoPointCounting") ||
            projectType.includes("HistoClassification")
          ) && (
            <div>
              <Tooltip disableInteractive title="Linear / logarithmic">
                <IconButton
                  className={classes.toolButton}
                  style={{
                    color:
                      histogramConfig.scale === HistogramScale.LOGARITHMIC &&
                      "#3F51B5",
                  }}
                  onClick={() => this.handleLogLin()}
                  size="large"
                >
                  <FontAwesomeIcon icon={faChartArea} />
                </IconButton>
              </Tooltip>

              {!isBrightfield && (
                <React.Fragment>
                  <Tooltip disableInteractive title="Min-max-fit">
                    <IconButton
                      className={classes.toolButton}
                      onClick={() => this.handleMinMaxFit()}
                      size="large"
                    >
                      <FontAwesomeIcon icon={faArrowsAltH} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip disableInteractive title="Best-fit">
                    <IconButton
                      className={classes.toolButton}
                      onClick={() => this.handleBestFit()}
                      size="large"
                    >
                      <FontAwesomeIcon icon={faChartLine} />
                    </IconButton>
                  </Tooltip>
                </React.Fragment>
              )}
              <Tooltip disableInteractive title="Export histogram settings">
                <IconButton
                  className={classes.toolButton}
                  onClick={this.onExportHistogramParameters}
                  size="large"
                >
                  <GetApp />
                </IconButton>
              </Tooltip>
              <Tooltip disableInteractive title="Import histogram settings">
                <IconButton
                  className={classes.toolButton}
                  onClick={() =>
                    document.getElementById("selectHistogramFile").click()
                  }
                  size="large"
                >
                  <Publish />
                </IconButton>
              </Tooltip>
              <input
                type="file"
                id="selectHistogramFile"
                accept=".json, .hishsa"
                style={{ position: "absolute", top: "-150px" }}
                onChange={this.onImportHistogramParameters}
              />
            </div>
          )}
        </div>
        <div className="histogram-outer">
          <div className={classes.chartContainer} height={null} width={null}>
            {dimensions && this.renderHistogram()}
          </div>
          <div className={classes.gradientBar} />
          <div className="slidecontainer">
            <input
              ref={(c) => (this.slider1 = c)}
              onChange={() => {
                this.setMountedState({
                  min: Math.min(
                    parseFloat(this.slider1.value),
                    this.state.max - 5
                  ),
                });
              }}
              onMouseUp={() => {
                let min = Math.min(
                  parseFloat(this.slider1.value),
                  this.state.max - 5
                );
                let max = this.state.max;
                this.handleMinMaxRange([min, max], this.state.gamma);
                this.slider1.blur();
              }}
              value={this.state.min}
              type="range"
              min="0"
              max="255"
              className="slider-bar"
            />
            {dimensions && (
              <input
                ref={(c) => (this.sliderGamma = c)}
                style={{
                  marginLeft: (dimensions.width / 255) * this.state.min + "px",
                  width:
                    (dimensions.width * (this.state.max - this.state.min)) /
                      255 +
                    "px",
                }}
                onChange={() => {
                  let gamma = parseFloat(this.sliderGamma.value);
                  this.setMountedState({
                    gamma: gamma,
                    leftGammaValue: this.gammaFunction(0.25, gamma),
                    rightGammaValue: this.gammaFunction(0.75, gamma),
                  });
                  this.drawGammaLine();
                }}
                onMouseUp={() => {
                  this.handleGammaChange();
                  this.sliderGamma.blur();
                }}
                value={this.state.gamma}
                type="range"
                min="0.00"
                max="1.99"
                step="0.01"
                className="slider"
              />
            )}

            <input
              ref={(c) => (this.slider2 = c)}
              onChange={() => {
                this.setMountedState({
                  max: Math.max(
                    parseFloat(this.slider2.value),
                    this.state.min + 5
                  ),
                });
              }}
              onMouseUp={() => {
                let min = this.state.min;
                let max = Math.max(this.slider2.value, min + 5);
                this.handleMinMaxRange([min, max], this.state.gamma);
                this.slider2.blur();
              }}
              value={this.state.max}
              type="range"
              min="0"
              max="255"
              className="slider-bar"
            />
          </div>
        </div>
        {!isBrightfield &&
          !(
            projectType.includes("HistoPointCounting") ||
            projectType.includes("HistoClassification")
          ) && (
            <React.Fragment>
              <button
                className={classes.actionButton}
                onClick={() => this.activateAllChannels()}
              >
                ALL
              </button>
              <ToggleButtonGroup
                className={classes.toggleContainer}
                value={this.state.selectedChannels}
                onChange={(e, value) => this.changeChannel(value)}
              >
                {histogramConfig.channels.map((channel, index) => (
                  <Tooltip
                    key={index}
                    disableInteractive
                    title={"Toggle with [ALT] + [" + (index + 1) + "]"}
                  >
                    <ToggleButton
                      id={"channel_" + index}
                      value={index}
                      onDoubleClick={(e) =>
                        this.handleColorPickerClick(e, index)
                      }
                    >
                      <span className={classes.toggleBtnContent}>
                        <div className={classes.channelLabel}>
                          {channel.name}{" "}
                          {this.state.selectedChannels.includes(index)}
                        </div>
                        <div
                          className={classes.color}
                          style={{
                            paddingBottom: this.state.selectedChannels.includes(
                              index
                            )
                              ? "12px"
                              : "5px",
                            marginBottom: this.state.selectedChannels.includes(
                              index
                            )
                              ? "5px"
                              : "12px",
                            backgroundColor: channel.color,
                          }}
                        />
                      </span>
                    </ToggleButton>
                  </Tooltip>
                ))}
              </ToggleButtonGroup>

              {dimensions && this.renderColorPicker()}
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e, value) =>
                      this.changeChannelSelectMode(e, value)
                    }
                    color="primary"
                    checked={this.state.channelSelectMode === "single"}
                    value="singleChannelMode"
                  />
                }
                label="Single Channel"
              />
            </React.Fragment>
          )}
      </div>
    );
  }
}

// define the component's interface
Histogram.propTypes = {
  classes: PropTypes.object.isRequired,
  histogramConfig: PropTypes.object,
  bitDepth: PropTypes.number,
  channelSelectMode: PropTypes.string.isRequired,
  changeChannelSelectMode: PropTypes.func,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  projectType: PropTypes.string,
  projectContext: PropTypes.object,
};

export default withStyles(styles)(Histogram);
