//import React from 'react';
import React, { Component } from "react";
import PropTypes from "prop-types";

// make a new context
const TilesContext = React.createContext();

export const withTiles = (Component) => {
  const WrappedComponent = (props) => (
    <TilesContext.Consumer>
      {(context) => <Component {...props} tiles={context} />}
    </TilesContext.Consumer>
  );

  WrappedComponent.displayName = `withTiles(${
    Component.displayName || Component.name || "Component"
  })`;

  return WrappedComponent;
};

// create provider component
export class TilesProvider extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.visibleImage = [];
    this.visibleImageUpdatedAt = [];
    this.coloredImages = [];
    this.coloredImagesUpdatedAt = [];
    this.imgWidth = 0;
    this.imgHeight = 0;
    this.pageIndex = 0;
    this.histogramConfig = null;
    this.selectedLayerColor = "#fff";
    this.selcetedLayerIndex = 0;
    this.annotationCount = 0;
    this.firstIteration = true;
    this.accPoints = [];
    this.pointCount = 0;
    this.galleryVisible = false;
    this.isMousedown = false;
    this.isOnImage = false;
    this.IsInOtherImage = false;
    this.onPageInput = false;
    this.strAnnoCount = [];
    this.strSubtypesPages = [];
    this.structure = null;
    this.structureBefore = null;
    this.graphAcc = [];
    this.roiProps = {
      color: "",
      isSubtype: false,
      subtypeName: "",
      pos: -1,
    };
    this.histoClassificationStarted = false;
    this.fileId = null;
    this.zLevel = 0;
    this.chainLeaderId = null;
    this.transformationMatrix = {};
    this.transformationFactor = {};
    this.transformationOffset = {};
  }

  getIndex = (a) => {
    if (this.strAnnoCount) {
      for (let i = 0; i < this.strAnnoCount.length; i++) {
        if (this.strAnnoCount[i][0] === a) {
          return i;
        }
      }
    }
    return -1;
  };

  getIndexObj = (a) => {
    if (this.strSubtypesPages) {
      for (let i = 0; i < this.strSubtypesPages.length; i++) {
        if (this.strSubtypesPages[i].id === a) {
          return i;
        }
      }
    }
    return -1;
  };

  resetStPg = () => {
    if (this.strSubtypesPages) {
      for (let i = 0; i < this.strSubtypesPages.length; i++) {
        this.strSubtypesPages[i].page = 0;
      }
    }
  };

  render() {
    return (
      <TilesContext.Provider
        value={{
          state: this.state,

          // Visible Images
          setVisibleImage: (a) => {
            this.visibleImage = a;
          },
          clearTiles: () => {
            for (let key of Object.keys(this.visibleImage)) {
              this.visibleImage[key] = null;
            }
            for (let key of Object.keys(this.visibleImage)) {
              delete this.visibleImage[key];
            }
            for (let key of Object.keys(this.coloredImages)) {
              this.coloredImages[key] = null;
            }
            for (let key of Object.keys(this.coloredImages)) {
              delete this.coloredImages[key];
            }
          },
          pushVisibleImage: (a, tileId) => {
            this.visibleImage[tileId] = a;
            this.visibleImageUpdatedAt[tileId] = new Date().getTime();

            // 275 imgs ~ 1GB RAM
            if (Object.keys(this.visibleImage).length > 150) {
              let sorted = [];
              for (let tileId in this.visibleImageUpdatedAt) {
                sorted.push([tileId, this.visibleImageUpdatedAt[tileId]]);
              }
              sorted.sort(function (a, b) {
                return a[1] - b[1];
              });

              let i = 0;
              while (
                Object.keys(this.visibleImage).length > 125 &&
                i < sorted.length
              ) {
                if (
                  sorted &&
                  sorted[i] &&
                  this.visibleImage[sorted[i][0]] &&
                  this.visibleImageUpdatedAt[sorted[i][0]] &&
                  sorted[i][0] !== "0,0,0,0"
                ) {
                  this.visibleImage[sorted[i][0]].onload = null;
                  this.visibleImage[sorted[i][0]].src = null;
                  this.visibleImage[sorted[i][0]] = null;
                  delete this.visibleImage[sorted[i][0]];
                  delete this.visibleImageUpdatedAt[sorted[i][0]];
                } else {
                  if (this.visibleImageUpdatedAt[sorted[i][0]] === undefined) {
                    delete this.visibleImageUpdatedAt[sorted[i][0]];
                  }
                }
                i++;
              }
            }
          },
          resetVisibleImages: () => {
            for (let f in this.visibleImage) {
              if (f !== "0,0,0,0") {
                this.visibleImage[f].onload = null;
                this.visibleImage[f].src = null;
                this.visibleImage[f] = null;
                delete this.visibleImage[f];
                delete this.visibleImageUpdatedAt[f];
              }
            }
            // this.visibleImage = [];
          },
          getVisibleImages: () => {
            return this.visibleImage;
          },
          getVisibleImage: (tileId) => {
            let img = this.visibleImage[tileId];
            if (img !== undefined) {
              this.visibleImageUpdatedAt[tileId] = new Date().getTime();
            }
            return img;
          },

          // Colored Images
          setColoredImages: (a) => {
            this.coloredImages = a;
          },
          pushColoredImages: (a, tileId) => {
            this.coloredImages[tileId] = a;
            this.coloredImagesUpdatedAt[tileId] = new Date().getTime();

            // 275 imgs ~ 1GB RAM
            if (Object.keys(this.coloredImages).length > 150) {
              let sorted = [];
              for (let tileId in this.coloredImagesUpdatedAt) {
                sorted.push([tileId, this.coloredImagesUpdatedAt[tileId]]);
              }

              sorted.sort(function (a, b) {
                return a[1] - b[1];
              });

              let i = 0;
              while (
                Object.keys(this.coloredImages).length > 125 &&
                i < sorted.length
              ) {
                if (
                  sorted &&
                  sorted[i] &&
                  this.coloredImages[sorted[i][0]] &&
                  this.coloredImagesUpdatedAt[sorted[i][0]] &&
                  sorted[i][0] !== "0,0,0,0"
                ) {
                  this.coloredImages[sorted[i][0]].onload = null;
                  this.coloredImages[sorted[i][0]].src = null;
                  this.coloredImages[sorted[i][0]] = null;
                  delete this.coloredImages[sorted[i][0]];
                  delete this.coloredImagesUpdatedAt[sorted[i][0]];
                } else {
                  if (this.coloredImagesUpdatedAt[sorted[i][0]] === undefined) {
                    delete this.coloredImagesUpdatedAt[sorted[i][0]];
                  }
                }
                i++;
              }
            }
          },
          resetColoredImages: () => {
            for (let f in this.coloredImages) {
              if (this.coloredImages[f] && f !== "0,0,0,0") {
                this.coloredImages[f].onload = null;
                this.coloredImages[f].src = null;
                this.coloredImages[f] = null;
                delete this.coloredImages[f];
                delete this.coloredImagesUpdatedAt[f];
              }
            }
          },
          getColoredImages: () => {
            return this.coloredImages;
          },
          getColoredImage: (tileId) => {
            let img = this.coloredImages[tileId];
            if (img !== undefined) {
              this.coloredImagesUpdatedAt[tileId] = new Date().getTime();
            }
            return img;
          },

          setImgWidth: (a) => {
            this.imgWidth = a;
          },
          getImgWidth: () => {
            return this.imgWidth;
          },

          setImgHeight: (a) => {
            this.imgHeight = a;
          },
          getImgHeight: () => {
            return this.imgHeight;
          },

          setPageIndex: (a) => {
            this.pageIndex = a;
          },
          getPageIndex: () => {
            return this.pageIndex;
          },

          setHistogramConfig: (a) => {
            this.histogramConfig = a;
          },
          getHistogramConfig: () => {
            return this.histogramConfig;
          },

          setSelectedLayerColor: (a) => {
            this.selectedLayerColor = a;
          },
          getSelectedLayerColor: () => {
            return this.selectedLayerColor;
          },

          setSelectedLayerIndex: (a) => {
            this.selectedLayerIndex = a;
          },
          getSelectedLayerIndex: () => {
            return this.selectedLayerIndex;
          },

          addAnnotationCount: (x) => {
            this.annotationCount = this.annotationCount + x;
          },
          setAnnotationCount: (x) => {
            return (this.annotationCount = x);
          },
          getAnnotationCount: () => {
            return this.annotationCount;
          },

          setFirstIteration: (x) => {
            this.firstIteration = x;
          },
          getFirstIteration: () => {
            return this.firstIteration;
          },

          pushAccPoint: (x) => {
            let point = {
              x: 30 * this.pointCount,
              y: x,
            };
            this.accPoints.push(point);
            this.pointCount = this.pointCount + 1;
          },
          getAccPoints: () => {
            return this.accPoints;
          },

          setGalleryVisible: (a) => {
            this.galleryVisible = a;
          },
          getGalleryVisible: () => {
            return this.galleryVisible;
          },

          setIsMousedown: (a) => {
            this.isMousedown = a;
          },
          getIsMousedown: () => {
            return this.isMousedown;
          },

          setIsOnImage: (a) => {
            this.isOnImage = a;
          },
          getIsOnImage: () => {
            return this.isOnImage;
          },

          setIsInOtherImage: (a) => {
            this.isInOtherImage = a;
          },
          getIsInOtherImage: () => {
            return this.isInOtherImage;
          },

          setRoiProps: (a) => {
            this.roiProps.color = a.color;
            this.roiProps.isSubtype = a.isSubtype;
            this.roiProps.subtypeName = a.subtypeName;
          },
          getRoiProps: () => {
            return this.roiProps;
          },

          setPositionRoi: (a) => {
            this.roiProps.pos = a;
          },

          setOnPageInput: (a) => {
            this.onPageInput = a;
          },
          getOnPageInput: () => {
            return this.onPageInput;
          },

          pushStrAnnoCount: (a) => {
            this.strAnnoCount.push([a, 0]);
          },
          pushDynamicStructureAC: (a, b) => {
            this.strAnnoCount.splice(b, 0, [a, 0]);
          },
          setStrAnnoCount: (a, b) => {
            let idx = this.getIndex(a);
            if (this.strAnnoCount[idx]) {
              this.strAnnoCount[idx][1] = this.strAnnoCount[idx][1] + b;
            }
          },
          setParentAnnoCount: (a, b) => {
            let idx = this.getIndex(a);
            if (this.strAnnoCount[idx]) {
              this.strAnnoCount[idx][1] = this.strAnnoCount[idx][1] + b;
            }
          },
          getStrAnnoCountElement: (a) => {
            let idx = this.getIndex(a);
            return this.strAnnoCount[idx][1];
          },
          getStrAnnoCount: () => {
            return this.strAnnoCount;
          },
          setAnnoCount: (a) => {
            this.strAnnoCount = a;
          },
          changeAnnoCount: (a, b) => {
            let idx_a = this.getIndex(a);
            let idx_b = this.getIndex(b);
            if (this.strAnnoCount[idx_a] && this.strAnnoCount[idx_b]) {
              this.strAnnoCount[idx_a][1] = this.strAnnoCount[idx_a][1] + 1;
              this.strAnnoCount[idx_b][1] = this.strAnnoCount[idx_b][1] - 1;
            }
          },

          pushSubtypesPages: (a, b) => {
            let obj = {
              name: a,
              page: 0,
              tilePage: 0,
              subtypes: [],
              id: b,
            };
            this.strSubtypesPages.push(obj);
          },
          pushDynamicStructureSP: (a, b) => {
            let obj = {
              name: a.label,
              page: 0,
              tilePage: 0,
              subtypes: [],
              id: a.id,
            };
            this.strSubtypesPages.splice(b, 0, obj);
          },
          getSubtypesPages: () => {
            return this.strSubtypesPages;
          },
          setSubtypesPages: (a) => {
            this.strSubtypesPages = a;
          },
          setPage: (a, b) => {
            let idx = this.getIndexObj(a);
            this.strSubtypesPages[idx].page = b;
          },
          getPage: (a) => {
            let idx = this.getIndexObj(a);
            if (this.strSubtypesPages[idx]) {
              return this.strSubtypesPages[idx].page;
            } else {
              return 0;
            }
          },
          setTilePage: (a, b) => {
            let idx = this.getIndexObj(a);
            this.strSubtypesPages[idx].tilePage = b;
          },
          getTilePage: (a) => {
            let idx = this.getIndexObj(a);
            return this.strSubtypesPages[idx].tilePage;
          },
          setSubtypes: (a, b) => {
            let idx = this.getIndexObj(a);
            this.strSubtypesPages[idx].subtypes = b;
          },
          setSingleSubtype: (a, b, c) => {
            let idx = this.getIndexObj(a);
            this.strSubtypesPages[idx].subtypes[c] = b;
          },
          getSubtypes: (a) => {
            let idx = this.getIndexObj(a);
            return this.strSubtypesPages[idx].subtypes;
          },
          resetSubtypesPages: () => {
            this.resetStPg();
            //this.strSubtypesPages = [];
          },

          setStructure: (a) => {
            this.structureBefore = this.structure;
            this.structure = a;
          },
          getStructure: () => {
            return this.structure;
          },
          getStructureBefore: () => {
            return this.structureBefore;
          },

          setGraphAcc: (a) => {
            this.graphAcc = a;
          },
          pushGraphAcc: (a) => {
            this.graphAcc.push(a);
          },
          getGraphAcc: () => {
            return this.graphAcc;
          },

          setHistoClassificationStarted: (a) => {
            this.histoClassificationStarted = a;
          },
          getHistoClassificationStarted: () => {
            return this.histoClassificationStarted;
          },

          setFileId: (a) => {
            this.fileId = a;
          },
          getFileId: () => {
            return this.fileId;
          },

          setZLevel: (a) => {
            this.zLevel = a;
          },
          getZLevel: () => {
            return this.zLevel;
          },
          setTransformationMatnFactnOff: (a, fileId, fact, Off) => {
            this.transformationMatrix[fileId] = a;
            this.transformationFactor[fileId] = fact;
            this.transformationOffset[fileId] = Off;
          },
          getTransformationMatrix: (fileId) => {
            return this.transformationMatrix[fileId];
          },
          getTransformationFactor: (fileId) => {
            return this.transformationFactor[fileId];
          },
          getTransformationOffset: (fileId) => {
            return this.transformationOffset[fileId];
          },
          removeTransformationMatrix: (fileId) => {
            delete this.transformationMatrix[fileId];
            delete this.transformationFactor[fileId];
            delete this.transformationOffset[fileId];
          },
          resetAllTransformationMatnFact: () => {
            this.transformationMatrix = {};
            this.transformationFactor = {};
            this.transformationOffset = {};
          },
          getAllTransformationMatrices: () => {
            return this.transformationMatrix;
          },
          getAllTransformationFactors: () => {
            return this.transformationFactor;
          },
          getAllTransformationOffsets: () => {
            return this.transformationOffset;
          },
        }}
      >
        {this.props.children}
      </TilesContext.Provider>
    );
  }
}

TilesProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default TilesContext;
