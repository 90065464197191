// Framework imports
import React, { useState, useRef, useEffect } from "react";
import { PropTypes } from "prop-types";

/**
 * Only load the visible children of a (long) list.
 * @param {Array} children Children to be rendered only whne in rendering area.
 * @param {number} maxContainerHeight The maximum allowed height of the container in pixels.
 * @param {number} elementHeight The height of a single element. all elements assumed to be the same height.
 * @param {number} offsetElements Optional. The number of elements to be preloaded outside of visible area. Defaults to 2.
 * @returns
 */
export function LazyRender(props) {
  const {
    children,
    maxContainerHeight,
    elementHeight,
    offsetElements = 2,
  } = props;
  const containerRef = useRef();

  const [scrollPositionTop, setScrollPositionTop] = useState(0);
  const [containerHeight, setContainerHeight] = useState(100);

  useEffect(() => {
    setContainerHeight(containerRef.current.clientHeight);
    containerRef.current.addEventListener("scroll", (e) => handleScroll(e));
    return containerRef.current.removeEventListener("scroll", (e) =>
      handleScroll(e)
    );
  }, []);

  /**
   * Update position and container properties on scroll.
   * @param {event} e Scrollevent.
   */
  const handleScroll = (e) => {
    setScrollPositionTop(e.target.scrollTop);
    setContainerHeight(containerRef.current.clientHeight);
  };

  /**
   * The placeholder to put in place of a non-rendered element
   */
  const placeholder = (key) => (
    <div key={key} style={{ height: elementHeight }}></div>
  );

  /**
   * Determine if an element should be rendered depending on its position.
   * @param {int} idx The index of the element in an arrray.
   * @returns {Boolean} Whether an element sould be rendered.
   */
  function isInView(idx) {
    const pos = idx * elementHeight;

    const minRendered = scrollPositionTop - offsetElements * elementHeight;
    const maxRendered =
      scrollPositionTop + containerHeight + offsetElements * elementHeight;

    const shouldBeRendered = pos >= minRendered && pos <= maxRendered;
    return shouldBeRendered;
  }

  return (
    <div
      style={{
        overflow: "auto",
        maxHeight: maxContainerHeight,
      }}
      ref={containerRef}
    >
      {children.map((c, i) => (isInView(i) ? c : placeholder(i)))}
    </div>
  );
}

LazyRender.propTypes = {
  children: PropTypes.array.isRequired,
  maxContainerHeight: PropTypes.number.isRequired,
  elementHeight: PropTypes.number.isRequired,
  offsetElements: PropTypes.number,
};
