import React, { Component } from "react";
import PropTypes from "prop-types";

const PersistentStorageContext = React.createContext();

export const withPersistentStorage = (Component) => {
  const WrappedComponent = (props) => (
    <PersistentStorageContext.Consumer>
      {(context) => <Component {...props} persistentStorage={context} />}
    </PersistentStorageContext.Consumer>
  );

  WrappedComponent.displayName = `withPersistentStorage(${
    Component.displayName || Component.name || "Component"
  })`;

  return WrappedComponent;
};

// this is the main component, it has to be added at the root of the app.
// all components that use withPersistentStorage(...) will have access to it via this.props.persistentStorage...
class PersistentStorageProvider extends Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    const loadedProjectState = JSON.parse(
      localStorage.getItem(this.props.projectId)
    );
    this.projectState = loadedProjectState ? loadedProjectState : {};
    this.projectTypeState = null;
  }

  save(key, value) {
    let v = JSON.stringify(value);
    let stateObject = { ...this.projectState }; //copy whole state for saving
    stateObject[key] = v;
    localStorage.setItem(this.props.projectId, JSON.stringify(stateObject));
    this.projectState[key] = v;
  }

  load(key) {
    if (typeof this.projectState[key] === "string") {
      try {
        return JSON.parse(this.projectState[key]);
      } catch (e) {
        return this.projectState[key];
      }
    } else {
      return this.projectState[key];
    }
  }

  saveProjectTypeValue(projectType, key, value) {
    let v = JSON.stringify(value);
    let stateObject = { ...this.projectTypeState }; //copy whole state for saving
    stateObject[key] = v;
    localStorage.setItem("local_" + projectType, JSON.stringify(stateObject));
    this.projectState[key] = v;
  }

  loadProjectTypeValue(projectType, key) {
    if (this.projectTypeState == null) {
      this.projectTypeState = JSON.parse(
        localStorage.getItem("local_" + projectType)
      );
    }
    if (this.projectTypeState == null) return null;
    if (typeof this.projectTypeState[key] === "string") {
      try {
        return JSON.parse(this.projectTypeState[key]);
      } catch (e) {
        return this.projectTypeState[key];
      }
    } else {
      return this.projectTypeState[key];
    }
  }

  loadAll() {
    let stateObject = {};
    for (const [key, value] of Object.entries(this.projectState)) {
      try {
        stateObject[key] = JSON.parse(value);
      } catch (e) {
        stateObject[key] = value;
      }
    }
    return stateObject;
  }

  render() {
    return (
      <PersistentStorageContext.Provider
        value={{
          save: (key, value) => this.save(key, value),
          saveProjectTypeValue: (projectType, key, value) =>
            this.saveProjectTypeValue(projectType, key, value),
          load: (key) => this.load(key),
          loadProjectTypeValue: (projectType, key) =>
            this.loadProjectTypeValue(projectType, key),
          loadAll: () => this.loadAll(),
        }}
      >
        {this.props.children}
      </PersistentStorageContext.Provider>
    );
  }
}

PersistentStorageProvider.propTypes = {
  projectId: PropTypes.string,
  children: PropTypes.element.isRequired,
};

export default PersistentStorageProvider;
