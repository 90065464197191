/**
 * Adjusts a UTC date to local timezone.
 * @param {Date} date A date object to convert.
 * @returns {Date} A date adjusted to the local timezone.
 */
export function convertUTCDateToLocalDate(date) {
  var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

  var offset = date.getTimezoneOffset() / 60;
  var hours = date.getHours();

  newDate.setHours(hours - offset);

  return newDate;
}

/**
 * Turn a json date into a standard Date object.
 * @param {object} jsonDate An date formatted as a json object.
 * @returns {Date} A Date object.
 */
export function convertDate(jsonDate) {
  return new Date(jsonDate);
}

/**
 * Get the viewer defining url path from a project type in the format "/path/".
 * @param {string} projectType Name of the project type.
 * @returns {string} Viewer defining path for URL.
 */
export function viewerType(projectType) {
  // Spectra Viewer
  if (
    projectType.includes("ESRTraining") ||
    projectType.includes("ESREvaluation")
  ) {
    return "/esr_view/";
  }
  // Proteome Viewer
  else if (projectType.includes("ProteomeAnalysis")) {
    return "/proteome_view/";
  }
  // Image Viewer
  else {
    return "/view/";
  }
}

/**
 * if there were no new calls of this function for the delay time, the newest func will be triggered
 * @param {function} func callback function
 * @param {int} delay in ms
 * @returns
 */
export function debounce(fn, delay) {
  let timeoutId;
  return function (...args) {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      fn(...args);
    }, delay);
  };
}
