import {
  updateLayer,
  updateDrawLayer,
  findSiblingRoiLayers,
  findSameLayer,
  findClickedRoi,
  checkIfStructureHidden,
  createRegionRoi,
  findRoi,
} from "../../utils/PolygonUtil";
import Tool from "./Tool";
import React from "react";
import OverlapConfigForm from "./ConfigForms/OverlapConfigForm";
import {
  Tabs,
  Tab,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Typography,
} from "@mui/material";

class RectangleTool extends Tool {
  name = "Rectangle";
  noConfig = false;
  includeBaseROI = false;
  flag = false;
  downScale = 2;
  points = [];
  removeOverlap = null;
  removeOverlapSame = null;
  tempRemoveOverlap = null;
  tempRemoveOverlapSame = null;
  activeTab = 0;
  drawMode = "draw";
  selectedRoi = null;
  selectedIndex = null;
  resizeMode = "";
  resizingStarted = "";

  setLayer(obj) {
    this.ome = obj.ome;
    this.updateObjectMode = obj.updateObjectMode;
    this.isBrightfield = obj.ome.channels[0].type === "brightfield";
    this.layer = obj.layer;
    this.roiLayers = obj.roiLayers;
    let layerResults = findSameLayer(obj.structures, obj.selectedLayer);
    this.selectedLayer = layerResults[0];
    this.parentLayer = layerResults[1];
    this.originalSelectedLayer = obj.selectedLayer;
    this.structures = obj.structures;
    this.drawLayer = obj.drawLayer;

    const project = obj.viewerConfig_project;
    if (this.removeOverlap === null) {
      this.removeOverlap = project.projectProperties["PreventOverlap"];
    }
    if (this.tempRemoveOverlap === null) {
      this.tempRemoveOverlap = project.projectProperties["PreventOverlap"];
    }
    if (this.removeOverlapSame === null) {
      this.removeOverlapSame = project.projectProperties["PreventOverlapSame"];
    }

    // set removeOverlap
    // save current settings (in tempRemoveOverlap) if structure gets changed
    if (obj.selectedLayer === 0) {
      this.activeTab = 0;
      if (!this.noConfig) {
        this.noConfig = true;
        this.tempRemoveOverlap = this.removeOverlap;
        this.tempRemoveOverlapSame = this.removeOverlapSame;
        this.removeOverlap = false;
        this.removeOverlapSame = false;
        window.forceSidebarUpdate();
      }
    } else {
      if (this.noConfig) {
        this.noConfig = false;
        this.removeOverlap = this.tempRemoveOverlap;
        this.removeOverlapSame = this.tempRemoveOverlapSame;
        window.forceSidebarUpdate();
      }
    }
    if (this.activeTab === 1) {
      if (typeof this.updateObjectMode === "function") {
        this.updateObjectMode(true);
      }
    }
  }

  setPreviewRect() {}

  getValidatedRegions(regions) {
    let validatedRegions = regions.map((p) => {
      return [
        Math.max(0, Math.min(this.ome.sizeX, p[0])),
        Math.max(0, Math.min(this.ome.sizeY, p[1])),
      ];
    });
    return validatedRegions;
  }

  /** Edits the position and size of a ROI using its new coordinates.
   *
   * @param {Array} regions Array of coordninate arrays: [[x,y], ..., [x,y]]
   */
  editRoi(regions) {
    if (this.selectedRoi === null) return;
    let newRoi = null;

    let historyItem = [];
    let histId = this.structures[this.selectedIndex].id;
    if (regions !== null) {
      regions = regions.map((p) => {
        return [
          Math.max(0, Math.min(this.ome.sizeX, p[0])),
          Math.max(0, Math.min(this.ome.sizeY, p[1])),
        ];
      });

      newRoi = createRegionRoi(
        this.getValidatedRegions(regions),
        this.selectedRoi.color,
        this.selectedRoi.isSubtype,
        this.selectedRoi.subtypeName,
        this.selectedRoi.fullyLoaded,
        this.structures[this.selectedIndex].id,
        true // isObject = true
      );
    }
    if (newRoi !== null) {
      this.lastRoi = newRoi;
      this.roiLayers[this.selectedIndex].tree.insert(newRoi.treeItem);
      historyItem.push({ add: true, id: histId, roi: newRoi });
    }
    if (typeof this.roiLayers[this.selectedIndex] === "undefined") return;

    let layerIndex = this.roiLayers[
      this.selectedIndex
    ].layer.regionRois.findIndex((roi) => roi.uuid === this.selectedRoi.uuid);
    this.roiLayers[this.selectedIndex].tree.remove(this.selectedRoi.treeItem);
    historyItem.push({ add: false, id: histId, roi: this.selectedRoi });

    if (layerIndex >= 0) {
      if (newRoi === null) {
        this.roiLayers[this.selectedIndex].layer.regionRois.splice(
          layerIndex,
          1
        );
      } else {
        this.roiLayers[this.selectedIndex].layer.regionRois[layerIndex] =
          newRoi;
      }
    }

    this.lastRoi = newRoi;
    this.selectedRoi = newRoi;
    this.hoveredRoi = newRoi;

    if (newRoi === null) {
      this.selectedIndex = null;
    }
    window.projectHistory.add(historyItem);
  }

  selectNexRoi = () => {
    const regionRois = this.roiLayers[this.selectedIndex].layer.regionRois;
    let roiIdx = regionRois.findIndex(
      (roi) => roi.uuid === this.selectedRoi.uuid
    );
    roiIdx = roiIdx + 1 >= regionRois.length ? 0 : roiIdx + 1;
    this.selectedRoi = regionRois[roiIdx];
    window.zoomToRect(this.selectedRoi.bounds);
  };

  rendererKeyDown(e) {
    if (this.drawMode === "edit" && this.selectedRoi) {
      let bounds = Object.assign({}, this.selectedRoi.bounds);
      let editRoi = false;
      let deleteRoi = false;
      switch (e.key) {
        case "Tab":
          this.selectNexRoi();
          e.preventDefault();
          break;
        case "ArrowRight":
          if (!e.shiftKey) bounds.left++;
          bounds.right++;
          editRoi = true;
          break;
        case "ArrowLeft":
          if (!e.shiftKey) bounds.left--;
          bounds.right--;
          editRoi = true;
          break;
        case "ArrowUp":
          if (!e.shiftKey) bounds.top--;
          bounds.bottom--;
          editRoi = true;
          break;
        case "ArrowDown":
          if (!e.shiftKey) bounds.top++;
          bounds.bottom++;
          editRoi = true;
          break;
        case "Delete":
          editRoi = true;
          deleteRoi = true;
          break;
        default:
          break;
      }
      if (editRoi) {
        let regions = null;
        if (
          bounds.left > 0 &&
          bounds.top > 0 &&
          bounds.right < this.ome.sizeX &&
          bounds.bottom < this.ome.sizeY
        ) {
          if (!deleteRoi) {
            regions = [
              [bounds.left, bounds.top],
              [bounds.right, bounds.top],
              [bounds.right, bounds.bottom],
              [bounds.left, bounds.bottom],
              [bounds.left, bounds.top],
            ];
          }

          this.editRoi(regions);
        }
      }
    }
  }

  setClickedRoiSelected(p, event) {
    let foundRoiResults = findRoi(
      p,
      this.roiLayers,
      this.structures,
      this.includeBaseROI
    );
    this.selectedIndex = foundRoiResults[0];
    let roi = foundRoiResults[1];
    if (roi && !roi.isObject) {
      window.showWarningSnackbar("Can not be edited! (no object annotation)");
      this.selectedRoi = null;
    } else {
      this.selectedRoi = roi;
      this.lastRoi = roi;
      if (event.button === 2) {
        this.editRoi(null); //delete roi
      } else {
        this.mouseDownPosition = p;
        if (roi) {
          if (this.resizeMode !== "") {
            this.resizingStarted = this.resizeMode;
          }
          this.mouseDownRoi = roi;
        }
      }
    }
  }

  /** Checks whether the cursor is hovering over an object.
   *
   * @param {Object} p x and y of cursor {x:, y: }
   */
  setHoveredRoi(p) {
    let foundRoiResults = findRoi(
      p,
      this.roiLayers,
      this.structures,
      this.includeBaseROI
    );
    this.hoveredIndex = foundRoiResults[0];
    let roi = foundRoiResults[1];
    this.hoveredRoi = roi;
  }

  /** Handeling of mouse events.
   *
   * @param {MouseEvent} event
   * @param {Object} p x and y of cursor {x:, y: }
   * @param {String} color #012DEF
   * @param {Bool} subtype Is the mouse over a subtype or not?
   * @param {String} name Name of the structure the cursor is above.
   * @param {Bool} positionInRoiLayer Is the cursor currently in a ROI Layer?
   * @param {Bool} fullyLoaded
   * @param {Int} parentLayer The id of the parentlayer of the structure the curson is above.
   */
  mouse(params) {
    let { event, p, color, subtype, name, positionInRoiLayer, fullyLoaded } =
      params;
    // Rectangle tool in object mode, selected option duplicate.
    if (this.activeTab === 1 && this.drawMode === "duplicate") {
      if (event.type === "mousedown" && event.button === 0 && this.lastRoi) {
        let rw = (this.lastRoi.bounds.right - this.lastRoi.bounds.left) / 2;
        let rh = (this.lastRoi.bounds.bottom - this.lastRoi.bounds.top) / 2;
        let regions = [
          [p.x - rw, p.y - rh],
          [p.x + rw, p.y - rh],
          [p.x + rw, p.y + rh],
          [p.x + rw, p.y + rh],
          [p.x - rw, p.y - rh],
        ];

        let drawRegion = {
          regions: [
            createRegionRoi(
              regions,
              color,
              subtype,
              name,
              fullyLoaded,
              this.structures[this.originalSelectedLayer].id,
              true
            ),
          ],
          inverted: false,
        };

        let overlapRoiLayers = [];
        if (this.removeOverlapSame) {
          overlapRoiLayers.push(this.roiLayers[this.selectedLayer]);
        }
        if (this.removeOverlap) {
          let siblingRoiLayers = findSiblingRoiLayers(
            this.structures,
            this.selectedLayer,
            this.roiLayers
          );
          siblingRoiLayers.map((layer) => overlapRoiLayers.push(layer));
        }
        updateLayer(
          this.layer,
          drawRegion,
          this.clear,
          color,
          subtype,
          name,
          this.roiLayers[this.selectedLayer].tree,
          positionInRoiLayer,
          fullyLoaded,
          false,
          this.roiLayers[this.parentLayer],
          this.structures[this.originalSelectedLayer].id,
          overlapRoiLayers,
          this.clickedOnRoi,
          true
        );
      }
    }
    // Rectangle tool in object mode, selected option edit or draw-while-hovering-over-an-object.
    else if (
      this.activeTab === 1 &&
      (this.drawMode === "edit" ||
        (this.drawMode === "draw" &&
          this.hoveredRoi !== null &&
          this.hoveredRoi.structureId ===
            this.structures[this.selectedLayer].id))
    ) {
      // Click on hovered-over object
      if (
        event.type === "mousedown" &&
        (event.button === 0 || event.button === 2)
      ) {
        this.setClickedRoiSelected(p, event);
      }
      // Move hovered-over object
      else if (event.type === "mousemove") {
        this.setHoveredRoi(p);

        // move roi
        if (
          this.mouseDownPosition &&
          this.mouseDownRoi &&
          (this.mouseDownRoi.structureId ==
            this.structures[this.selectedLayer].id ||
            this.drawMode === "edit")
        ) {
          let deltaP = {
            x: this.mouseDownPosition.x - p.x,
            y: this.mouseDownPosition.y - p.y,
          };
          let regions = [];
          // Resize Object
          if (this.resizingStarted !== "") {
            let bounds = {
              left: this.resizingStarted.includes("left")
                ? this.mouseDownRoi.bounds.left - deltaP.x
                : this.mouseDownRoi.bounds.left,
              top: this.resizingStarted.includes("top")
                ? this.mouseDownRoi.bounds.top - deltaP.y
                : this.mouseDownRoi.bounds.top,
              right: this.resizingStarted.includes("right")
                ? this.mouseDownRoi.bounds.right - deltaP.x
                : this.mouseDownRoi.bounds.right,
              bottom: this.resizingStarted.includes("bottom")
                ? this.mouseDownRoi.bounds.bottom - deltaP.y
                : this.mouseDownRoi.bounds.bottom,
            };
            bounds.left = Math.max(0, bounds.left);
            bounds.top = Math.max(0, bounds.top);
            bounds.right = Math.min(this.ome.sizeX, bounds.right);
            bounds.bottom = Math.min(this.ome.sizeY, bounds.bottom);
            regions = [
              [bounds.left, bounds.top],
              [bounds.right, bounds.top],
              [bounds.right, bounds.bottom],
              [bounds.left, bounds.bottom],
              [bounds.left, bounds.top],
            ];
          }
          // Move object
          else {
            regions = this.mouseDownRoi.regions[0].map((point) => {
              return [point[0] - deltaP.x, point[1] - deltaP.y];
            });
          }
          // Save changes to object
          this.editRoi(regions);
        }
      }
      // Release hovered-over object
      if (event.type === "mouseup") {
        this.mouseDownPosition = null;
        this.resizingStarted = "";
      }
    }
    // Rectangle tool in area or object mode, set starting point.
    else if (
      event.type === "mousedown" &&
      (event.button === 0 || event.button === 2)
    ) {
      checkIfStructureHidden(
        this.structures,
        this.selectedLayer,
        subtype,
        name,
        color
      );
      this.drawLayer.regionRois = [];
      this.drawLayer.inverted = false;
      // right mouse button -> clear
      this.clear = event.button === 2;
      this.drawLayer.clear = this.clear;

      // update position history
      this.startPoint = p;
      this.points = [];
      this.points.push([p.x, p.y]);

      // set drawing flag
      this.flag = true;

      // check if mouse down is inside region? => exapand region
      if (this.removeOverlapSame) {
        this.clickedOnRoi = findClickedRoi(
          p,
          this.selectedLayer,
          this.structures,
          this.roiLayers,
          this.includeBaseROI
        );
      }
    }
    // Rectangle tool in area or object mode, set end point.
    else if (
      event.type === "mouseup" ||
      (this.flag && event.type === "mouseleave")
    ) {
      // release drawing flag
      this.flag = false;
      let drawRegion = {
        regions: [],
        inverted: false,
      };
      if (this.drawLayer.regionRois.length > 0) {
        drawRegion.regions = [this.drawLayer.regionRois[0]];

        let overlapRoiLayers = [];
        if (this.removeOverlapSame) {
          overlapRoiLayers.push(this.roiLayers[this.selectedLayer]);
        }
        if (this.removeOverlap) {
          let siblingRoiLayers = findSiblingRoiLayers(
            this.structures,
            this.selectedLayer,
            this.roiLayers
          );
          siblingRoiLayers.map((layer) => overlapRoiLayers.push(layer));
        }

        // Save last ROI for duplication
        if (this.activeTab === 1) {
          this.lastRoi =
            this.drawLayer.regionRois[this.drawLayer.regionRois.length - 1];
        }
        updateLayer(
          this.layer,
          drawRegion,
          this.clear,
          color,
          subtype,
          name,
          this.roiLayers[this.selectedLayer].tree,
          positionInRoiLayer,
          fullyLoaded,
          false,
          this.roiLayers[this.parentLayer],
          this.structures[this.originalSelectedLayer].id,
          overlapRoiLayers,
          this.clickedOnRoi,
          this.activeTab === 1 // defines if it should be treated as an object
        );

        this.drawLayer.regionRois = [];
      }
    }
    // Rectangle tool in area or object mode, create rectangle by dragging.
    else if (event.type === "mousemove") {
      if (this.flag) {
        let points = [];
        points.push([this.startPoint.x, this.startPoint.y]);
        points.push([p.x, this.startPoint.y]);
        points.push([p.x, p.y]);
        points.push([this.startPoint.x, p.y]);
        points.push([this.startPoint.x, this.startPoint.y]);

        let drawRegion = {
          regions: [points],
          inverted: false,
        };

        this.drawLayer.regionRois = []; //only one region will be drawn to the draw layer
        updateDrawLayer(
          this.drawLayer,
          drawRegion,
          false,
          color,
          subtype,
          name
        );
      }
      // Check if currently hovering over an object.
      else {
        this.setHoveredRoi(p);
      }
    }
  }

  highlightRoi(ctx, roi) {
    // do not highlight if not in selected structure in draw mode
    // do not highlight if not object annotation
    // highlight all object annotations if in edit mode
    if (
      roi &&
      roi.isObject &&
      (this.drawMode === "edit" ||
        roi.structureId == this.structures[this.selectedLayer].id)
    ) {
      ctx.globalAlpha = 0.2;
      ctx.fillStyle = roi.isObject ? "#000000" : "#ff0000";
      ctx.beginPath();
      for (let points of roi.getRectRegions()) {
        if (points[points.length - 2]) {
          ctx.moveTo(
            points[points.length - 2][0],
            points[points.length - 2][1]
          );
          for (let i = 0; i < points.length; i++) {
            ctx.lineTo(points[i][0], points[i][1]);
          }
        }
      }
      ctx.closePath();
      ctx.fill();
      ctx.globalAlpha = 1.0;
    }
  }

  highlightResizeCorner(ctx, roi, p) {
    // do not highlight resize corner if not in selected structure in draw mode
    // do not highlight resize corner if not object annotation
    // highlight resize corner for all object annotations if in edit mode
    if (
      roi &&
      roi.isObject &&
      (this.drawMode === "edit" ||
        roi.structureId == this.structures[this.selectedLayer].id)
    ) {
      ctx.globalAlpha = 1.0;
      ctx.fillStyle = "#0000ff";
      let rb = roi.bounds;
      let scaleFactor = 1 / ctx.getTransform().a;
      let cornerSize = 20 * scaleFactor;
      let roiWidth = roi.bounds.right - roi.bounds.left;
      let roiHeight = roi.bounds.bottom - roi.bounds.top;
      let x = roi.bounds.left;
      let y = roi.bounds.top;

      //no resizing if corners would hinder translation
      if (cornerSize * 2.5 > roiWidth || cornerSize * 2.5 > roiHeight) {
        this.resizeMode = "";
        return;
      }

      //if mouse in bottom right corner
      if (
        !(
          p.x < rb.left ||
          p.x > rb.left + cornerSize ||
          p.y < rb.top ||
          p.y > rb.top + cornerSize
        )
      ) {
        this.resizeMode = "top_left";
      } else if (
        !(
          p.x < rb.right - cornerSize ||
          p.x > rb.right ||
          p.y < rb.top ||
          p.y > rb.top + cornerSize
        )
      ) {
        this.resizeMode = "top_right";
        x = rb.right - cornerSize;
      } else if (
        !(
          p.x < rb.right - cornerSize ||
          p.x > rb.right ||
          p.y < rb.bottom - cornerSize ||
          p.y > rb.bottom
        )
      ) {
        this.resizeMode = "bottom_right";
        x = rb.right - cornerSize;
        y = rb.bottom - cornerSize;
      } else if (
        !(
          p.x < rb.left ||
          p.x > rb.left + cornerSize ||
          p.y < rb.bottom - cornerSize ||
          p.y > rb.bottom
        )
      ) {
        this.resizeMode = "bottom_left";
        y = rb.bottom - cornerSize;
      } else {
        //mouse in right bottom corner
        this.resizeMode = "";
        return;
      }
      let points = [
        [x, y],
        [x + cornerSize, y],
        [x + cornerSize, y + cornerSize],
        [x, y + cornerSize],
        [x, y],
      ];
      ctx.beginPath();
      ctx.moveTo(points[0], points[1]);
      for (let i = 0; i < points.length; i++) {
        ctx.lineTo(points[i][0], points[i][1]);
      }
      ctx.closePath();
      ctx.fill();
      ctx.globalAlpha = 1.0;
    }
  }

  drawCustomCursor(ctx, mousePosition) {
    if (
      (this.drawMode === "draw" && this.hoveredRoi === null) ||
      (this.drawMode === "draw" &&
        this.hoveredRoi &&
        this.hoveredRoi.structureId !==
          this.structures[this.selectedLayer].id) ||
      this.activeTab === 0
    ) {
      ctx.beginPath();
      ctx.globalAlpha = 1.0;
      ctx.strokeStyle = this.isBrightfield ? "#000000" : "#ffffff";
      ctx.beginPath();
      ctx.moveTo(mousePosition.x, 0);
      ctx.lineTo(mousePosition.x, 1000000);
      ctx.moveTo(0, mousePosition.y);
      ctx.lineTo(1000000, mousePosition.y);
      ctx.stroke();
      ctx.closePath();
    } else if (this.lastRoi && this.drawMode === "duplicate") {
      let p = mousePosition;
      let rw = (this.lastRoi.bounds.right - this.lastRoi.bounds.left) / 2;
      let rh = (this.lastRoi.bounds.bottom - this.lastRoi.bounds.top) / 2;
      ctx.beginPath();
      ctx.globalAlpha = 1.0;
      ctx.strokeStyle = this.isBrightfield ? "#000000" : "#ffffff";
      ctx.moveTo(
        Math.max(0, Math.min(this.ome.sizeX, p.x - rw)),
        Math.max(0, Math.min(this.ome.sizeY, p.y - rh))
      );
      ctx.lineTo(
        Math.max(0, Math.min(this.ome.sizeX, p.x + rw)),
        Math.max(0, Math.min(this.ome.sizeY, p.y - rh))
      );
      ctx.lineTo(
        Math.max(0, Math.min(this.ome.sizeX, p.x + rw)),
        Math.max(0, Math.min(this.ome.sizeY, p.y + rh))
      );
      ctx.lineTo(
        Math.max(0, Math.min(this.ome.sizeX, p.x - rw)),
        Math.max(0, Math.min(this.ome.sizeY, p.y + rh))
      );
      //ctx.lineTo(p.x + rw, p.y - rh);
      ctx.closePath();
      ctx.stroke();
    }

    if (this.activeTab === 1) {
      this.highlightRoi(ctx, this.selectedRoi);
      this.highlightRoi(ctx, this.hoveredRoi);
      this.highlightResizeCorner(ctx, this.hoveredRoi, mousePosition);
    }
  }

  handleChange = () => {
    this.activeTab = this.activeTab === 0 ? 1 : 0;
    if (typeof this.updateObjectMode === "function") {
      this.updateObjectMode(this.activeTab === 1);
    }
    window.forceSidebarUpdate();
  };

  exit() {}

  renderConfiguration() {
    return (
      <div>
        <Typography variant="h6">{this.name}:</Typography>
        <React.Fragment>
          <Tabs
            variant="fullWidth"
            indicatorColor="primary"
            textColor="primary"
            value={this.activeTab}
            onChange={this.handleChange}
          >
            <Tab label="Area" />
            <Tab label="Object" />
          </Tabs>
          {this.activeTab === 0 && (
            <OverlapConfigForm
              removeOverlap={this.removeOverlap}
              removeOverlapSame={this.removeOverlapSame}
              onChangeRemoveOverlap={(e) => (this.removeOverlap = e)}
              onChangeRemoveOverlapSame={(e) => (this.removeOverlapSame = e)}
            />
          )}
          {this.activeTab === 1 && (
            <React.Fragment>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="drawMode"
                  name="drawMode"
                  value={this.drawMode}
                  onChange={(e) => {
                    this.drawMode = e.target.value;
                    window.forceSidebarUpdate();
                  }}
                >
                  <FormControlLabel
                    value="draw"
                    control={<Radio />}
                    label="Draw Object"
                  />
                  <FormControlLabel
                    value="edit"
                    control={<Radio />}
                    label="Edit Object (arrows, arrows+Shift, Tab)"
                  />
                  <FormControlLabel
                    value="duplicate"
                    control={<Radio />}
                    label="Duplicate last object size"
                    disabled={typeof this.lastRoi === "undefined"}
                  />
                </RadioGroup>
              </FormControl>
            </React.Fragment>
          )}
        </React.Fragment>
      </div>
    );
  }
}

export default RectangleTool;
