import React, { Component } from "react";
import PropTypes from "prop-types";

import { withAllViewerContexts } from "../contexts/AllViewerContexts";
import { withTiles } from "../contexts/TilesContext";
import { withResultTab } from "../contexts/ResultTabContext";

import {
  Grid,
  Typography,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
} from "@mui/material";

import { Add } from "@mui/icons-material";

import withStyles from "@mui/styles/withStyles";

const styles = {};

class ScoringMatrixHistoPointCounting extends Component {
  constructor(props) {
    super(props);

    window.addFrequencyClass = this.addFrequencyClass;
    window.classifyTileWithkey = this.classifyTileWithkey;

    this.store = this.props.persistentStorage;
    this.canCallClassifyTileWithKey = true;
  }

  componentDidMount = () => {};

  componentDidUpdate() {}

  UNSAFE_componentWillMount = () => {
    // get all parent structures
    let selSample = this.store.load("selLayerSample");
    if (selSample) {
      this.props.onSelectLayer(selSample);
      let selId = this.props.structures[selSample].id;

      // get all parent structures
      let parentStructures = this.props.structures.filter(
        (element) => element.subtypeLevel === 0
      );

      // get Index of sample in parent structures
      let parentIdxSelStructure = parentStructures.findIndex(
        (element) => element.id === selId
      );
      this.props.resultTab.setSelectedStructure(parentIdxSelStructure);
    }
  };

  findChilds = (subType) => {
    const { structures } = this.props;
    // return direct childs of structure
    return structures.filter(
      (element) =>
        element.subtypeLevel === subType.subtypeLevel + 1 &&
        element.parentId === subType.id
    );
  };

  getAbbreviationOfStructure = (structure) => {
    // get abbreviation of structure --> label [abbreviation]
    let strWithBracket = structure.label.split("[")[1];
    if (!strWithBracket) {
      strWithBracket = structure.label;
    }
    let abbreviation = strWithBracket.substring(0, strWithBracket.length - 1);
    return abbreviation;
  };

  handleChangeSelectedStructure = (e) => {
    const { resultTab } = this.props;
    // get all parent structures
    const parentStructures = this.props.structures.filter(
      (element) => element.subtypeLevel === 0
    );

    const idxNewSelectedStructure = this.props.structures.findIndex(
      (element) => element.id === parentStructures[e.target.value].id
    );

    if (idxNewSelectedStructure >= 0) {
      this.props.onSelectLayer(idxNewSelectedStructure);
    }

    resultTab.setSelectedChildIndex(1);
    resultTab.setSelectedStructure(e.target.value);

    // reset structures
    this.props.resetStructures(true);

    // save selectedLayer in local storage
    this.store.save("selLayerSample", idxNewSelectedStructure);
  };

  getCount = (structure) => {
    const { roiLayers, selectedLayer } = this.props;
    // calculate number of rois in parent layer
    const numberRois = roiLayers[selectedLayer].layer.regionRois.filter(
      (roi) => roi.structureId === structure.id
    ).length;

    return numberRois;
  };

  blockClassifyTile = (structure) => {
    const { roiLayers, selectedLayer, resultTab, tiles, onChangeTool } =
      this.props;
    const selectedRoi = resultTab.getSelectedRoi();

    // if file is changing no classification is possible for a few seconds
    if (resultTab.getFileChange()) return true;

    // if not started no classification is possible
    if (!tiles.getHistoClassificationStarted()) {
      window.showWarningSnackbar("Please click 'RUN' to classify.");
      return true;
    }

    // if no grid no classification is possible
    if (roiLayers[selectedLayer].layer.regionRois.length === 0) {
      window.showWarningSnackbar("Please create a grid before annotating");
      onChangeTool("gridtool");
      return true;
    }

    // do not classify empty structure if 100 already reached
    if (
      resultTab.getHundredTiles() &&
      structure.label !== "leer [leer]" &&
      roiLayers[selectedLayer].layer.regionRois[selectedRoi].subtypeName ===
        "leer [leer]"
    ) {
      window.showWarningSnackbar("Finished!");
      return true;
    }

    return false;
  };

  classifyTile = (structure) => {
    if (this.blockClassifyTile(structure)) return;

    const { roiLayers, selectedLayer, resultTab } = this.props;
    const selectedRoi = resultTab.getSelectedRoi();

    // classify tile --> set roi properties
    roiLayers[selectedLayer].layer.regionRois[selectedRoi].structureId =
      structure.id;
    roiLayers[selectedLayer].layer.regionRois[selectedRoi].subtypeName =
      structure.label;
    roiLayers[selectedLayer].layer.regionRois[selectedRoi].color =
      structure.color;
    roiLayers[selectedLayer].layer.regionRois[selectedRoi].isSubtype = true;
    roiLayers[selectedLayer].layer.regionRois[selectedRoi].isAnnotated = true;
    roiLayers[selectedLayer].layer.regionRois[selectedRoi].isLabeled = true;

    //this.criticalValueReached();
    // set hundret tiles to true only if from classification button
    this.getTotalCount();
    this.props.onNext();
    this.forceUpdate();
  };

  classifyTileWithkey = (keyNumber) => {
    if (keyNumber > 0 && this.canCallClassifyTileWithKey) {
      const { structures, selectedLayer } = this.props;
      // classification with key shortcut
      let childs = this.findChilds(structures[selectedLayer]);

      if (childs[keyNumber - 1]) {
        let structureForClassification = childs[keyNumber - 1];
        this.classifyTile(structureForClassification);
        this.canCallClassifyTileWithKey = false;
        setTimeout(() => {
          this.canCallClassifyTileWithKey = true;
        }, 100); // re-enable the call after 0.1 seconds
      }
    }
  };

  isTileClassified = (structure) => {
    const { roiLayers, selectedLayer } = this.props;
    // color structure in dark grey if selected tile was classified with structure

    // if no grid return
    if (roiLayers[selectedLayer].layer.regionRois.length === 0) {
      return;
    }

    // check if selected tile has class of structure
    let selectedRoi = this.props.resultTab.getSelectedRoi();
    let tileHasStructureClass =
      roiLayers[selectedLayer].layer.regionRois[selectedRoi].structureId ===
      structure.id;

    // if tile has no classification yet
    if (structure.label === "leer [leer]" && !tileHasStructureClass) {
      tileHasStructureClass =
        roiLayers[selectedLayer].layer.regionRois[selectedRoi].structureId ===
        0;
    }

    if (tileHasStructureClass && !this.props.showGallery) {
      return "#d8d8d8";
    } else {
      return "#FFFFFF";
    }
  };

  renderSubtypeRow = (structure, indexSubtype) => {
    // check if selected tile is classified with this structure
    let backgroundColor = this.isTileClassified(structure);

    return (
      <Grid
        key={indexSubtype}
        container
        spacing={0}
        style={{
          background: backgroundColor,
        }}
      >
        <Grid item xs>
          <Tooltip disableInteractive title={structure.label}>
            <Typography>
              {this.getAbbreviationOfStructure(structure)}
            </Typography>
          </Tooltip>
        </Grid>
        <Grid item xs>
          <Typography
            style={{
              marginLeft: "20px",
              color: structure.label === "leer [leer]" && backgroundColor,
            }}
          >
            {this.getCount(structure)}
          </Typography>
        </Grid>
        <Grid item xs>
          <Tooltip
            disableInteractive
            title={
              indexSubtype < 9
                ? "Shortcut key: [" + (indexSubtype + 1) + "]"
                : ""
            }
          >
            <IconButton
              style={{ marginLeft: "32px" }}
              onClick={() => this.classifyTile(structure)}
              size="large"
            >
              <Add />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    );
  };

  getTotalCount = (fromRendering) => {
    const { structures, roiLayers, selectedLayer, resultTab } = this.props;

    // get id of "leer" child
    let emptyId = 0;
    this.findChilds(structures[selectedLayer]).forEach((child) => {
      if (child.label === "leer [leer]") {
        emptyId = child.id;
      }
    });

    // get number of rois that are not of class "leer"
    const numberRois = roiLayers[selectedLayer].layer.regionRois.filter(
      (roi) => roi.isAnnotated && roi.structureId !== emptyId
    ).length;

    if (numberRois >= 100 && !fromRendering) {
      resultTab.setHundredTiles(true);
    }

    return numberRois;
  };

  renderMatrixBottom = () => {
    return (
      <Grid container spacing={0}>
        <Grid item xs>
          <Typography>Total</Typography>
        </Grid>
        <Grid item xs>
          <Typography>{this.getTotalCount(true)} / 100</Typography>
        </Grid>
        <Grid item xs></Grid>
      </Grid>
    );
  };

  renderMatrixHeader = () => {
    return (
      <Grid container spacing={0} style={{ marginBottom: "15px" }}>
        <Grid item xs>
          <Typography
            style={{
              textAlign: "left",
              fontWeight: "bold",
            }}
          >
            Gewebeart
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography
            style={{
              textAlign: "left",
              fontWeight: "bold",
            }}
          >
            Count
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography
            style={{
              textAlign: "left",
              fontWeight: "bold",
            }}
          >
            Increase Count
          </Typography>
        </Grid>
      </Grid>
    );
  };

  isSelSampleSet = () => {
    // disable dropdown if sample is set and classification started
    const dropdownDisabled = this.props.tiles.getHistoClassificationStarted();
    return dropdownDisabled;
  };

  render() {
    const { structures, selectedLayer } = this.props;
    return (
      <div>
        <FormControl>
          <InputLabel>Sample</InputLabel>
          <Select
            label="Sample"
            variant="standard"
            style={{ marginBottom: "30px" }}
            value={this.props.resultTab.getSelectedStructure()}
            onChange={this.handleChangeSelectedStructure}
            disabled={this.isSelSampleSet()}
          >
            {this.props.structures
              .filter((element) => element.subtypeLevel === 0)
              .map((structure, idx) => (
                <MenuItem key={idx} value={idx}>
                  {structure.label}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        {this.renderMatrixHeader()}
        {this.findChilds(structures[selectedLayer]).map((structure, idx) =>
          this.renderSubtypeRow(structure, idx)
        )}
        {this.renderMatrixBottom()}
      </div>
    );
  }
}

// define the component's interface
ScoringMatrixHistoPointCounting.propTypes = {
  persistentStorage: PropTypes.object,
  resultTab: PropTypes.object,
  tiles: PropTypes.object,
  selectedLayer: PropTypes.number,
  showGallery: PropTypes.bool,
  structures: PropTypes.array,
  roiLayers: PropTypes.array,
  onSelectLayer: PropTypes.func,
  resetStructures: PropTypes.func,
  onChangeTool: PropTypes.func,
  onNext: PropTypes.func,
};

export default withAllViewerContexts(
  withTiles(withResultTab(withStyles(styles)(ScoringMatrixHistoPointCounting)))
);
