import React, { Component } from "react";
import PropTypes from "prop-types";

import Tool from "./Tool";

import {
  lineArrayBuffer,
  updateDrawLayer,
  updateLayer,
  findSiblingRoiLayers,
  findSameLayer,
  checkIfStructureHidden,
  findClickedRoi,
  createRegionRoi,
  distance,
} from "../../utils/PolygonUtil";
import OverlapConfigForm from "./ConfigForms/OverlapConfigForm";
import { FormLabel, FormControl, Slider, Typography } from "@mui/material";

class PenTool extends Tool {
  name = "Pen";
  flag = false;
  downScale = 2;
  penRadius = 10;
  minPenRadius = 1;
  maxPenRadius = 200;
  removeOverlap = null;
  removeOverlapSame = null;
  lastP = null;
  tempRemoveOverlap = null;
  tempRemoveOverlapSame = null;
  useNodeDrawingMode = null;
  tempUseNodeDrawingMode = null;

  setLayer(obj) {
    this.ome = obj.ome;
    this.isBrightfield = obj.ome.channels[0].type === "brightfield";
    this.layer = obj.layer;
    this.roiLayers = obj.roiLayers;
    let layerResults = findSameLayer(obj.structures, obj.selectedLayer);
    this.selectedLayer = layerResults[0];
    this.parentLayer = layerResults[1];
    this.originalSelectedLayer = obj.selectedLayer;
    this.structures = obj.structures;
    this.drawLayer = obj.drawLayer;

    const project = obj.viewerConfig_project;
    if (this.removeOverlap === null) {
      this.removeOverlap = project.projectProperties["PreventOverlap"];
    }
    if (this.tempRemoveOverlap === null) {
      this.tempRemoveOverlap = project.projectProperties["PreventOverlap"];
    }
    if (this.removeOverlapSame === null) {
      this.removeOverlapSame = project.projectProperties["PreventOverlapSame"];
    }
    if (this.useNodeDrawingMode === null) {
      this.useNodeDrawingMode = project.projectProperties["NodeDrawingMode"]
        ? true
        : false;
    }

    // set removeOverlap
    // save current settings (in tempRemoveOverlap) if structure gets changed
    if (obj.selectedLayer === 0) {
      if (!this.noConfig) {
        this.noConfig = true;
        this.tempRemoveOverlap = this.removeOverlap;
        this.tempRemoveOverlapSame = this.removeOverlapSame;
        this.removeOverlap = false;
        this.removeOverlapSame = false;
        this.tempUseNodeDrawingMode = this.useNodeDrawingMode;
        this.useNodeDrawingMode = false;
        window.forceSidebarUpdate();
      }
    } else {
      if (this.noConfig) {
        this.noConfig = false;
        this.removeOverlap = this.tempRemoveOverlap;
        this.removeOverlapSame = this.tempRemoveOverlapSame;
        this.useNodeDrawingMode = this.tempUseNodeDrawingMode;
        window.forceSidebarUpdate();
      }
    }
  }

  onKeyDown(e) {
    let delta = Math.max(1, parseInt(this.penRadius * 0.05, 10));
    if (e.code === "KeyS") {
      this.penRadius -= delta;
    } else if (e.code === "KeyW") {
      this.penRadius += delta;
    }
    if (this.penRadius < this.minPenRadius) {
      this.penRadius = this.minPenRadius;
    }
    if (this.penRadius > this.maxPenRadius) {
      this.penRadius = this.maxPenRadius;
    }
    window.forceSidebarUpdate();
  }

  setPreviewRect() {}

  addPoint = (event, p) => {
    let eventPoint = { x: event.clientX, y: event.clientY };
    if (this.line.length < 3 || this.useNodeDrawingMode) {
      this.line.push([p.x, p.y]);
      this.lastEventPoint = eventPoint;
    } else {
      const dist = distance(this.lastEventPoint, eventPoint);
      if (dist < 10) {
        this.line[this.line.length - 1] = [p.x, p.y];
      } else {
        this.line.push([p.x, p.y]);
        this.lastEventPoint = eventPoint;
      }
    }
  };

  mouse(params) {
    let { event, p, color, subtype, name, positionInRoiLayer, fullyLoaded } =
      params;
    if (typeof p === "undefined") {
      this.line = [];
      return;
    }
    // mouse middle button
    if (event.button === 1) {
      return;
    }
    if (this.useNodeDrawingMode) {
      if (
        event.type === "mousedown" &&
        (event.button === 0 || event.button === 2)
      ) {
        if (this.flag) {
          if (
            (this.clear && event.button === 2) ||
            (!this.clear && event.button === 0)
          ) {
            this.addPointToDrawLayer(event, p, color, subtype);
          } else {
            this.applyDrawLayer(
              color,
              subtype,
              name,
              positionInRoiLayer,
              fullyLoaded,
              event,
              p
            );
          }
        } else {
          this.initDrawing(subtype, name, color, event, p);
        }
      }
      return;
    }
    if (
      event.type === "mousedown" &&
      (event.button === 0 || event.button === 2)
    ) {
      this.initDrawing(subtype, name, color, event, p);
    } else if (event.type === "mouseup") {
      this.applyDrawLayer(
        color,
        subtype,
        name,
        positionInRoiLayer,
        fullyLoaded
      );
      this.drawLayer.regionRois = [];
    } else if (event.type === "mousemove") {
      if (this.flag) {
        this.addPointToDrawLayer(event, p, color, subtype);
      }
    }
    this.lastP = p;
  }

  initDrawing = (subtype, name, color, event, p) => {
    checkIfStructureHidden(
      this.structures,
      this.selectedLayer,
      subtype,
      name,
      color
    );
    this.clear = event.button === 2;
    this.drawLayer.clear = this.clear;
    this.line = [[p.x, p.y]];
    this.flag = true;
    if (this.removeOverlapSame) {
      this.clickedOnRoi = findClickedRoi(
        p,
        this.selectedLayer,
        this.structures,
        this.roiLayers,
        this.includeBaseROI
      );
    }
  };

  replaceLastPointInDrawLayer = (event, p, color, subtype) => {
    if (this.line.length === 1) {
      this.addPointToDrawLayer(event, p, color, subtype);
      return;
    }
    if (this.line.length > 1) {
      this.line.pop();
      this.addPointToDrawLayer(event, p, color, subtype);
    }
  };

  applyDrawLayer = (color, subtype, name, positionInRoiLayer, fullyLoaded) => {
    let linePoly = lineArrayBuffer(
      this.line,
      this.penRadius,
      this.ome.sizeX,
      this.ome.sizeY
    );
    if (linePoly === undefined) return;
    this.flag = false;
    this.drawLayer.regionRois = []; //only one region will be drawn to the draw layer
    let lineRegions = {
      regions:
        linePoly !== null
          ? [
              createRegionRoi(
                [linePoly.geometry.coordinates],
                color,
                subtype,
                name,
                fullyLoaded,
                this.structures[this.originalSelectedLayer].id,
                false
              ),
            ]
          : [],
      inverted: false,
    };
    let overlapRoiLayers = [];
    if (this.removeOverlapSame) {
      overlapRoiLayers.push(this.roiLayers[this.selectedLayer]);
    }
    if (this.removeOverlap) {
      let siblingRoiLayers = findSiblingRoiLayers(
        this.structures,
        this.selectedLayer,
        this.roiLayers
      );
      siblingRoiLayers.map((layer) => overlapRoiLayers.push(layer));
    }
    updateLayer(
      this.layer,
      lineRegions,
      this.clear,
      color,
      subtype,
      name,
      this.roiLayers[this.selectedLayer].tree,
      positionInRoiLayer,
      fullyLoaded,
      false,
      this.roiLayers[this.parentLayer],
      this.structures[this.originalSelectedLayer].id,
      overlapRoiLayers,
      this.clickedOnRoi
    );
    this.drawLayer.regionRois = [];
    this.line = [];
  };

  addPointToDrawLayer = (event, p, color, subtype) => {
    // update position history
    this.addPoint(event, p);
    this.drawLayer.regionRois = []; //only one region will be drawn to the draw layer
    let linePoly = lineArrayBuffer(
      this.line,
      this.penRadius,
      this.ome.sizeX,
      this.ome.sizeY
    );

    let lineRegions = {
      regions: linePoly !== null ? [linePoly.geometry.coordinates] : [],
      inverted: false,
    };
    updateDrawLayer(this.drawLayer, lineRegions, false, color, subtype, name);
  };

  drawCustomCursor(ctx, mousePosition, fkt, fromGallery) {
    let p = mousePosition;
    fromGallery = fromGallery && fromGallery === true ? true : false;
    ctx.beginPath();
    ctx.globalAlpha = 1.0;
    ctx.strokeStyle = this.isBrightfield ? "#000000" : "#ffffff";
    if (this.useNodeDrawingMode && this.line?.length > 0) {
      const lineEnd = this.line[this.line.length - 1];
      let previewLine = [lineEnd, [p.x, p.y]];
      let linePoly = lineArrayBuffer(
        previewLine,
        this.penRadius,
        this.ome.sizeX,
        this.ome.sizeY
      );
      let points = linePoly.geometry.coordinates[0];
      ctx.lineWidth = 2 / fkt;
      ctx.strokeStyle = "#000000";
      ctx.moveTo(points[0][0], points[0][1]);
      for (let p of points) {
        ctx.lineTo(p[0], p[1]);
      }
    } else {
      let penRad =
        fkt && fromGallery && fromGallery === true
          ? this.penRadius * fkt
          : this.penRadius;
      ctx.arc(p.x, p.y, penRad, 0, 2 * Math.PI);
    }
    ctx.stroke();
    ctx.closePath();
  }

  exit() {}

  renderConfiguration() {
    return (
      <div>
        <Typography variant="h6">{this.name}:</Typography>
        <ConfigForm
          penRadius={this.penRadius}
          minPenRadius={this.minPenRadius}
          maxPenRadius={this.maxPenRadius}
          removeOverlap={this.removeOverlap}
          onChangePenRadius={(e) => {
            this.penRadius = e;
            window.forceSidebarUpdate();
          }}
          onChangeRemoveOverlap={(e) => (this.removeOverlap = e)}
        />
        <OverlapConfigForm
          removeOverlap={this.removeOverlap}
          removeOverlapSame={this.removeOverlapSame}
          useNodeDrawingMode={this.useNodeDrawingMode}
          onChangeUseNodeDrawingMode={(e) => (this.useNodeDrawingMode = e)}
          onChangeRemoveOverlap={(e) => (this.removeOverlap = e)}
          onChangeRemoveOverlapSame={(e) => (this.removeOverlapSame = e)}
        />
      </div>
    );
  }
}

class ConfigForm extends Component {
  state = {
    removeOverlap: this.props.removeOverlap,
  };

  render() {
    let { onChangePenRadius, penRadius, minPenRadius, maxPenRadius } =
      this.props;

    return (
      <div style={{ marginRight: "15px" }}>
        <FormControl component="fieldset" fullWidth>
          <FormLabel component="legend">
            {"Line thickness = " + penRadius + " (smaller: S, wider: W)"}
          </FormLabel>
          <Slider
            min={minPenRadius}
            max={maxPenRadius}
            value={penRadius}
            onChange={(e, v) => {
              onChangePenRadius(v);
              this.setState({ penRadius: v });
            }}
          />
        </FormControl>
      </div>
    );
  }
}

ConfigForm.propTypes = {
  removeOverlap: PropTypes.bool,
  onChangePenRadius: PropTypes.func,
  penRadius: PropTypes.number,
  minPenRadius: PropTypes.number,
  maxPenRadius: PropTypes.number,
};

export default PenTool;
