import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItemButton,
  ListItemText,
  IconButton,
  Button,
} from "@mui/material";
import { Folder, Description, ArrowUpward } from "@mui/icons-material";
import Backend from "../utils/Backend";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  title: {
    minHeight: 64,
  },
  content: {
    width: "500px",
    height: "500px",
    overflow: "auto",
  },
  contentIcon: {
    color: theme.palette.icon.main,
    marginRight: "5px",
  },
}));

const SelectPathDialog = ({ isFolder, acceptPath }) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [path, setPath] = useState("");
  const [directories, setDirectories] = useState([]);

  useEffect(() => {
    // Get the list of directories when the dialog opens
    if (isOpen) {
      Backend.getDirectories(path).then((directories) => {
        if (directories) {
          setDirectories(directories);
        }
      });
    }
  }, [isOpen, path]);

  const handleListItemClick = useCallback((directory) => {
    setPath(directory);
  }, []);

  const handleGoUp = useCallback(() => {
    if (path.length < 4) {
      setPath("");
    } else {
      const newPath = path.split("/").slice(0, -1).join("/");
      setPath(newPath === "" ? "/" : newPath);
    }
  }, [path]);

  const onCancel = useCallback(() => {
    setPath("");
    setIsOpen(false);
  }, []);

  const onAccept = useCallback(() => {
    acceptPath(path);
    setIsOpen(false);
  }, [path]);

  return (
    <>
      <IconButton onClick={() => setIsOpen(true)}>
        {isFolder ? (
          <Folder className={classes.contentIcon} />
        ) : (
          <Description className={classes.contentIcon} />
        )}
      </IconButton>

      <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <DialogTitle className={classes.title}>{path}</DialogTitle>
        <DialogContent className={classes.content}>
          <List>
            {/* Add a button to go up a folder if not already at root */}
            {path !== "" && (
              <ListItemButton onClick={handleGoUp}>
                <ArrowUpward className={classes.contentIcon} />
                <ListItemText primary=".." />
              </ListItemButton>
            )}

            {/* Display each directory in the list */}
            {directories.map((directory) => {
              let dirName = directory.substring(directory.lastIndexOf("/") + 1);
              if (dirName === "") {
                dirName = directory;
              }
              return (
                <ListItemButton
                  key={directory}
                  onClick={() => handleListItemClick(directory)}
                >
                  <Folder className={classes.contentIcon} />
                  <ListItemText primary={dirName} />
                </ListItemButton>
              );
            })}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel}>Cancel</Button>
          <Button onClick={onAccept}>Select</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

SelectPathDialog.propTypes = {
  isFolder: PropTypes.bool.isRequired,
  acceptPath: PropTypes.func.isRequired,
};

export default SelectPathDialog;
